import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTabTitle from 'src/components/PageTabTitle';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';
import { user } from 'src/store/actions';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 48,
  },
  infoBox: {
    marginBottom: 15
  },
  label: {
    marginBottom: 5,
    color: '#333333'
  },
  buttonBox: {
    paddingTop: 5
  },
  formBox: {
    maxWidth: '350px'
  },
  formButtonBox: {
    paddingTop: 5,
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const EditProfileView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.auth.user);
  const UserDispatch = (value) => dispatch(user(value));

  const [activeTab, setactiveTab] = useState('primary');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      nome: me.usr_nome,
      email: me.usr_email,
      senhaAtual: '',
      senha: '',
      confirmaSenha: ''
    }
  });

  const updateUser = () => {
    let obj = {
      "usr_id": me.usr_id,
      "usr_nome": formik.values.nome,
      "usr_email": formik.values.email,
      "usr_tipoUsuario": me.usr_tipoUsuario
    }

    if (me.usr_cli_id !== null) {
      obj.usr_cli_id = me.usr_cli_id;
    }

    api.put('/user', obj)
      .then((resp) => {
        openSuccessAlert();
        UserDispatch(resp.data.result);
        setIsEditing(false);
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Perfil atualizado com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
    if(alertType !== 'error') navigate('/app/dashboard');
  };

  const changeActiveTab = (tab) => {
    setactiveTab(tab);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  }

  const updatePassword = () => {
    let obj = {
      "usr_id": me.usr_id,
      "usr_senha": formik.values.senha,
    }

    if (formik.values.senha === formik.values.confirmaSenha) {
      api.put('/user/senha', obj)
        .then((resp) => {
          openSuccessAlert();
          UserDispatch(resp.data.result);
          formik.setFieldValue('senha', '');
          formik.setFieldValue('confirmaSenha', '');
          setIsEditing(false);
        })
        .catch((err) => {
          console.log(err);
          openErrorAlert(err.response.data.message);
        })
    } else {
      alert('Senhas diferentes.')
    }
  }

  return (
    <Page className={classes.root} title="Perfil">
      <PageTabTitle primary="PERFIL" secondary="SENHA" active={activeTab} changeTab={changeActiveTab} />
      <Container className={classes.container}>
      { 
        activeTab==="primary" ? (
          <>
          {
            !isEditing ? (
              <Box>
                <Box className={classes.infoBox}>
                  <Typography variant="body2" className={classes.label}>Nome</Typography>
                  <Typography variant="body1">{me.usr_nome}</Typography>
                </Box>
                <Box className={classes.infoBox}>
                  <Typography variant="body2" className={classes.label}>E-mail</Typography>
                  <Typography variant="body1">{me.usr_email}</Typography>
                </Box>

                <Box className={classes.buttonBox}>
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={() => setIsEditing(true)}
                  >
                    Editar Cadastro
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box className={classes.formBox}>
                <TextField
                  fullWidth
                  label="Nome"
                  margin="normal"
                  name="nome"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.nome}
                  variant="outlined"
                  required
                />

                <TextField
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.email}
                  variant="outlined"
                  required
                />

                <Box className={classes.formButtonBox}>
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={updateUser}
                  >
                    Atualizar Cadastro
                  </Button>

                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            )
          }
          </>
        ) : (
          <>
          {
            !isEditing ? (
              <Box>
                <Box className={classes.infoBox}>
                  <Typography variant="body2" className={classes.label}>Senha Atual</Typography>
                  <Typography variant="body1">**************</Typography>
                </Box>

                <Box className={classes.buttonBox}>
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={() => setIsEditing(true)}
                  >
                    Editar Senha
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box className={classes.formBox}>
                {/* <TextField
                  fullWidth
                  label="Senha Atual"
                  margin="normal"
                  name="senhaAtual"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showCurrentPassword ? 'text' : 'password'}
                  value={formik.values.senhaAtual}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showCurrentPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownCurrentPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                /> */}

                <TextField
                  fullWidth
                  label="Nova senha"
                  margin="normal"
                  name="senha"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.senha}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  fullWidth
                  label="Confirme a nova senha"
                  margin="normal"
                  name="confirmaSenha"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formik.values.confirmaSenha}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showConfirmPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Box className={classes.formButtonBox}>
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={updatePassword}
                  >
                    Atualizar Cadastro
                  </Button>

                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            )
          }
          </>
        )
      }
      </Container>

      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default EditProfileView;
