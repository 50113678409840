import React from 'react';
import {
  AppBar,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  appBar: {
    boxShadow: 'none'
  },
  menuBar: {
    height: '120px',
    backgroundColor: '#F5F5F5',
    paddingLeft: '64px',
    paddingRight: '64px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuButton: {
    color: '#000'
  },
  menuIcon: {
    width: '40px',
    height: '40px'
  },
  imgLogo: {
    width: '197px',
    height: '80px'
  },
}));

const HeaderBar = ({toggle}) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.menuBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => toggle(true)}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>

        <img
          alt="Logo"
          src="/static/images/logo_TVM.png"
          className={classes.imgLogo}
        />
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;