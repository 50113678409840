import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Fab,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import MediaCard from "src/components/MediaCard";
import Modal from "src/components/Modal";
import Loader from "src/components/Loader";
import api from "src/services/api";
import { Autocomplete } from "@mui/material";
import { Skeleton } from "@mui/material";
import Popup from 'reactjs-popup';
import AlertDialog from "src/components/AlertDialog";

import ConfirmActionModal from "src/components/ConfirmActionModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  searchBox: {
    marginRight: "10px",
    width: "326px",
  },
  searchByCategoryBox: {
    marginRight: 10,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "1440px",
  },
  green: {
    color: "#009C6D",
  },
  red: {
    color: "#E6202A",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  mediaGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    marginTop: theme.spacing(10),
    paddingBottom: "40px",
    gap: theme.spacing(1),
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  paginationItem: {},
  filterContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "200px",
    "& .MuiAutocomplete-root": {
      height: 56,
      "& .MuiAutocomplete-inputRoot": {
        height: 56,
      },
    },
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    marginTop: theme.spacing(10),
    paddingBottom: "40px",
    gap: theme.spacing(1),
    maxWidth: "1440px",
  },
  searchBtn: {},
  optionContainer: {

  },
  buttonOptions: {
    background: "#f07c00",
    color: "white",
    marginBottom: 6,
    borderRadius: 4,
    padding: 8,
    textAlign: "center",
    width: 150,
    cursor: "pointer"
  }
}));

const MediaLibraryView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  const [modalType, setModalType] = useState("");
  const [modalEditType, setModalEditType] = useState("");
  const [contentSrc, setContentSrc] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [mediaActive, setMediaActive] = useState(null);

  const [multimediaIdToEdit, setMultimediaIdToEdit] = useState("");
  const [displayDialogMessage, setDisplayDialogMessage] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");

  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState(10000000);

  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  const [paginationCount, setPaginationCount] = useState(0);
  const [page, setPage] = useState(1);

  const requestSearch = async () => {
    if ((!filterCategory || filterCategory === 10000000) && !searched) {
      api
        .get(`/multimidia/all/ativos?pagina=${1}&quantidade=${12}`)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setRows(resp.data.result.items);
            setPaginationCount(resp.data.result.last_page);
            setPage(resp.data.result.next_page - 1);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (
      filterCategory &&
      filterCategory !== "" &&
      searched !== "" &&
      filterCategory !== 10000000
    ) {
      await api
        .get(
          `/multimidia/all/ativos?pagina=1&quantidade=12&mul_nome=${searched}&cat_id=${filterCategory}`
        )
        .then((res) => {
          res.data.result.items.length > 0
            ? setRows(res.data.result.items)
            : setRows([]);
          setPaginationCount(res.data.result.last_page);
          setPage(res.data.result.next_page - 1);
        })
        .catch((err) => {
          console.log("Error: ", err);
          setRows([]);
        });
    } else {
      if (
        filterCategory &&
        filterCategory !== "" &&
        filterCategory !== 10000000
      ) {
        await api
          .get(
            `/multimidia/all/ativos?pagina=1&quantidade=12&cat_id=${filterCategory}`
          )
          .then((res) => {
            console.log(res);
            res.data.result.items.length > 0
              ? setRows(res.data.result.items)
              : setRows([]);
            setPaginationCount(res.data.result.last_page);
            setPage(res.data.result.next_page - 1);
          })
          .catch((err) => {
            console.log("Error: ", err);
            setRows([]);
          });
      } else {
        await api
          .get(
            `/multimidia/all/ativos?pagina=1&quantidade=12&mul_nome=${searched}`
          )
          .then((res) => {
            res.data.result.items.length > 0
              ? setRows(res.data.result.items)
              : setRows([]);
            setPaginationCount(res.data.result.last_page);
            setPage(res.data.result.next_page - 1);
          })
          .catch((err) => {
            console.log("Erro: ", err);
            setRows([]);
          });
      }
    }
  };

  const handleClick = (type) => {
    navigate(`/app/nova-biblioteca?type=${type}`);
  };

  const onDelete = (id) => {
    api
      .delete(`/multimidia/status/${id}`)
      .then((resp) => {
        if (resp.status && resp.status === 200) {
          setConfirmModal(!confirmModal);
          setDialogMessage("Mídia deletada com sucesso.");
          setAlertType("success");
          setAlertButtonText("Fechar");
          setDisplayDialogMessage(true);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setConfirmModal(!confirmModal);
        setDialogMessage("Você não tem permissão para deletar essa mídia.");
        setAlertType("error");
        setAlertButtonText("Fechar");
        setDisplayDialogMessage(true);
        // console.log(err.message);
      });
  };

  const handleAlertClose = () => {
    setDisplayDialogMessage(false);
  }

  const onOpen = (path, tipo) => {
    setStartLoader(true);
    console.log("TIPO: ", tipo);
    api
      .get(`/multimidia/file/${path}`, { responseType: "blob" })
      .then((resp) => {
        const objectUrl = URL.createObjectURL(resp.data);

        setStartLoader(false);
        setModalType(tipo);
        setOpenModal(true);
        setContentSrc(objectUrl);
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
      });
  };

  const onEdit = (path, tipo, id) => {
    setStartLoader(true);
    setIsEdit(true);
    setMultimediaIdToEdit(id);

    api
      .get(`/multimidia/file/${path}`, { responseType: "blob" })
      .then((resp) => {
        const objectUrl = URL.createObjectURL(resp.data);
        setStartLoader(false);
        setContentSrc(objectUrl);
      })
      .catch((err) => {
        setStartLoader(false);
        setContentSrc("");
        console.log(err);
      });

    setModalType('');
    setModalEditType(tipo === "imagem" ? "image" : tipo);
    setOpenModal(true);
  };

  const onClose = () => {
    console.log("ONCLOSE");
    setOpenModal(false);
    setIsEdit(false);
    URL.revokeObjectURL(contentSrc);
    setContentSrc("");
  };

  const handleChangePage = async (page) => {
    if (filterCategory && filterCategory != 10000000 && searched) {
      await api
        .get(
          `/multimidia/all/ativos?pagina=${page}&quantidade=${12}&mul_nome=${searched}&cat_id=${filterCategory}`
        )
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setRows(resp.data.result.items);
            setPaginationCount(resp.data.result.last_page);
            setPage(resp.data.result.next_page - 1);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (
        filterCategory &&
        filterCategory !== "" &&
        filterCategory !== 10000000
      ) {
        await api
          .get(
            `/multimidia/all/ativos?pagina=${page}&quantidade=12&cat_id=${filterCategory}`
          )
          .then((res) => {
            console.log(res);
            res.data.result.items.length > 0
              ? setRows(res.data.result.items)
              : setRows([]);
            setPaginationCount(res.data.result.last_page);
            setPage(res.data.result.next_page - 1);
          })
          .catch((err) => {
            console.log("Error: ", err);
            setRows([]);
          });
      } else {
        await api
          .get(
            `/multimidia/all/ativos?pagina=${page}&quantidade=12&mul_nome=${searched}`
          )
          .then((res) => {
            res.data.result.items.length > 0
              ? setRows(res.data.result.items)
              : setRows([]);
            setPaginationCount(res.data.result.last_page);
            setPage(res.data.result.next_page - 1);
          })
          .catch((err) => {
            console.log("Error: ", err);
            setRows([]);
          });
      }
    }

    if ((!filterCategory || filterCategory === 10000000) && !searched) {
      await api
        .get(`/multimidia/all/ativos?pagina=${page}&quantidade=${12}`)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setRows(resp.data.result.items);
            setPaginationCount(resp.data.result.last_page);
            setPage(resp.data.result.next_page - 1);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      setStartLoader(true);
      api
        .get(`/multimidia/all/ativos?pagina=${1}&quantidade=${12}`)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setRows(resp.data.result.items);
            setPaginationCount(resp.data.result.last_page);
            setPage(resp.data.result.next_page - 1);
            setIsLoaded(true);
            setStartLoader(false);
          }
        })
        .catch((err) => {
          setStartLoader(false);
          console.log(err);
        });

      api
        .get("/categoria/")
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setCategories(resp.data.result);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      requestSearch();
    }
  }, [searched, filterCategory]);

  const handleOpenDelete = (media) => {
    setMediaActive(media);
    setConfirmModal(!confirmModal);
  }

  const handleCloseDelete = () => {
    setMediaActive(null);
    setConfirmModal(!confirmModal);
  }

  return (
    <Page className={classes.root} title="Biblioteca de Mídia">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle title="BIBLIOTECA DE MÍDIAS" />
        <div className={classes.filterContainer}>
          <Autocomplete
            disablePortal
            id="autocomplete-filter"
            className={classes.searchByCategoryBox}
            sx={{ width: 300 }}
            options={categories.map((category) => {
              return { label: category.cat_nome, value: category.cat_id };
            })}
            onChange={(e, val) => setFilterCategory(val?.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Categorias"
                type="text"
              />
            )}
          />

          <TextField
            className={classes.searchBox}
            label="O que deseja localizar?"
            name="busca"
            onChange={(e) => setSearched(e.target.value)}
            type="text"
            value={searched}
            variant="outlined"
            style={{ width: 300 }}
          />

          <Fab
            color="primary"
            size="medium"
            className={classes.searchBtn}
            onClick={requestSearch}
          >
            <SearchIcon fontSize="large" />
          </Fab>
        </div>
      </Box>

      {isLoaded ? (
        <Container className={classes.container}>
          <Box className={classes.mediaGrid}>
            {rows.map((media, index) => (
              <MediaCard
                key={index}
                className={classes.root}
                name={media.mul_nome}
                image={media.mul_thumb}
                type={media.mul_tipo}
                content={media.mul_path}
                mul_processamento_concluido={media.mul_processamento_concluido}
                duration={media.mul_video_duration}
                category={media.category}
                onOpen={() => onOpen(media.mul_path, media.mul_tipo)}
                onDelete={() => handleOpenDelete(media.mul_id)}
                onEdit={() =>
                  onEdit(media.mul_path, media.mul_tipo, media.mul_id)
                }
                mediaLibraryView={true}
                index={index}
              />
            ))}
            {rows.length === 0 ? (
              <Typography variant="h3" style={{ color: "gray" }}>
                Sem midia para exibir.
              </Typography>
            ) : null}
          </Box>

          <div
            className="tooltipBoundary"
          >
            <Popup
              trigger={
                <Fab color="secondary" className={classes.fab}>
                  <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
                </Fab>
              }
              position="top center"
              closeOnDocumentClick
              arrow={false}
            >
              <div className={classes.optionContainer}>
                <div
                  className={classes.buttonOptions}
                  onClick={() => handleClick("midia")}
                >
                  <Typography>
                    Nova mídia
                  </Typography>
                </div>
                <div
                  className={classes.buttonOptions}
                  onClick={() => handleClick("template")}
                >
                  <Typography>
                    Editar template
                  </Typography>
                </div>
              </div>

            </Popup>
          </div>
          <Container className={classes.pagination}>
            <Pagination
              count={paginationCount}
              page={page}
              onChange={(event, nextPage) =>
                nextPage !== 0 && handleChangePage(nextPage)
              }
              className={classes.paginationItem}
            />
          </Container>
        </Container>
      ) : (
        <Container className={classes.skeletonContainer}>
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
        </Container>
      )}

      <AlertDialog
        open={displayDialogMessage}
        handleClose={() => handleAlertClose(alertType)}
        type={alertType}
        text={dialogMessage}
        buttonText={alertButtonText}
      />

      <Modal
        type={modalType}
        open={openModal}
        content={contentSrc}
        close={onClose}
        isEdit={isEdit}
        typeEdit={modalEditType}
        multimediaIdToEdit={multimediaIdToEdit}
        isLoaded={setIsLoaded}
      />
      <Loader display={startLoader} />
      <ConfirmActionModal
        message={'Deseja deletar essa mídia?'}
        open={confirmModal}
        confirm={() => onDelete(mediaActive)}
        close={() => handleCloseDelete()}
      />
    </Page>
  );
};

export default MediaLibraryView;
