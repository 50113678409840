import React from 'react';
import {
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footerBar: {
    backgroundColor: '#F5F5F5',
    height: '64px',
    minHeight: '64px',
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none'
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Container className={classes.footerBar}>
      <Typography variant="body2">Desenvolvido por Techlise © 2021</Typography>
    </Container>
  )
}

export default Footer;