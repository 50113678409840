import {
  Button,
  Fade,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask/lib/react-input-mask.development";
import deafaultDaysToDisplay from "src/utils/defaultDaysToDisplay";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    position: "relative",
    width: "50vw",
    height: "90vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 10),
    boxSizing: "border-box",
  },
  inputContainer: {
    width: "calc(100% - 30px)",
    height: 60
  },
  timeBox: {
    width: "calc(100% - 30px)",
    margin: "0px 15px",
  },
  saveButton: {
    textAlign: "center",
    margin: "15px 0",
    "& button": {
      backgroundColor: "#F07C00",
      color: "white",
    },
  },
  dayContainer: {
    display: "flex",
    width: "100%",
    "& 	.MuiToggleButton-root": {
      marginBottom: 30,
      height: 40,
    },
  },
  flex: {
    display: "flex",
  },
  timeInput: {
    display: "flex",
    width: "100%",
    marginBottom: "20px",
    height: 50,
  },
  errorText: {
    fontSize: 12,
    color: "red",
    textAlign: "center",
    width: "100%"
  }
}));
export default function MidiaDaysModal(props) {
  const classes = useStyles();
  const [segunda, setSegunda] = useState(deafaultDaysToDisplay[0]);
  const [terca, setTerca] = useState(deafaultDaysToDisplay[1]);
  const [quarta, setQuarta] = useState(deafaultDaysToDisplay[2]);
  const [quinta, setQuinta] = useState(deafaultDaysToDisplay[3]);
  const [sexta, setSexta] = useState(deafaultDaysToDisplay[4]);
  const [sabado, setSabado] = useState(deafaultDaysToDisplay[5]);
  const [domingo, setDomingo] = useState(deafaultDaysToDisplay[6]);
  const [disableDuration, setDisableDuration] = useState(false);

  const [errorField, setErrorField] = useState({});

  useEffect(
    () => setErrorField({}), 
    [segunda, terca, quarta, quinta, sexta, sabado, domingo]
  );

  useEffect(() => {
    if (props.videoDuration) {
      setSegunda({ ...segunda, exc_duracao: props.videoDuration });
      setTerca({ ...terca, exc_duracao: props.videoDuration });
      setQuarta({ ...quarta, exc_duracao: props.videoDuration });
      setQuinta({ ...quinta, exc_duracao: props.videoDuration });
      setSexta({ ...sexta, exc_duracao: props.videoDuration });
      setSabado({ ...sabado, exc_duracao: props.videoDuration });
      setDomingo({ ...domingo, exc_duracao: props.videoDuration });
      setDisableDuration(true);
    } else {
      setSegunda(deafaultDaysToDisplay[0]);
      setTerca(deafaultDaysToDisplay[1]);
      setQuarta(deafaultDaysToDisplay[2]);
      setQuinta(deafaultDaysToDisplay[3]);
      setSexta(deafaultDaysToDisplay[4]);
      setSabado(deafaultDaysToDisplay[5]);
      setDomingo(deafaultDaysToDisplay[6]);
      setDisableDuration(false);
    }
  }, [props.videoDuration, props.open]);

  const validDays = (daysToSave) => {
    let validation = { isValid: true }

    for(let day of daysToSave) {

      if(day.exc_horaInicio && !day.exc_horaFim) {

        validation = { 
          isValid: false, 
          field: 'exc_horaFim',
          day: day.exc_dia_semana
        }

        setErrorField(validation);
        
        break
      }

      if(
        day.exc_horaFim && !day.exc_horaInicio
      ) {

        validation = { 
          isValid: false, 
          field: 'exc_horaInicio',
          day: day.exc_dia_semana
        }

        setErrorField(validation);
        
        break
      }
    }

    return validation
  }

  const handleClose = () => {
    const daysToSave = [];

    if (
      segunda.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "1")
    )
      daysToSave.push(segunda);
    if (
      terca.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "2")
    )
      daysToSave.push(terca);
    if (
      quarta.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "3")
    )
      daysToSave.push(quarta);
    if (
      quinta.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "4")
    )
      daysToSave.push(quinta);
    if (
      sexta.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "5")
    )
      daysToSave.push(sexta);
    if (
      sabado.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "6")
    )
      daysToSave.push(sabado);
    if (
      domingo.exc_horaFim !== "" &&
      props.daysToDisplay.find((day) => day === "0")
    )
      daysToSave.push(domingo);

    const validation = validDays(daysToSave);

    if(!validation.isValid) return;
    
    setErrorField({});

    props.save(daysToSave);

    setSegunda(deafaultDaysToDisplay[0]);
    setTerca(deafaultDaysToDisplay[1]);
    setQuarta(deafaultDaysToDisplay[2]);
    setQuinta(deafaultDaysToDisplay[3]);
    setSexta(deafaultDaysToDisplay[4]);
    setSabado(deafaultDaysToDisplay[5]);
    setDomingo(deafaultDaysToDisplay[6]);

    if (props.videoDuration) {
      setSegunda({ ...segunda, exc_duracao: props.videoDuration });
      setTerca({ ...terca, exc_duracao: props.videoDuration });
      setQuarta({ ...quarta, exc_duracao: props.videoDuration });
      setQuinta({ ...quinta, exc_duracao: props.videoDuration });
      setSexta({ ...sexta, exc_duracao: props.videoDuration });
      setSabado({ ...sabado, exc_duracao: props.videoDuration });
      setDomingo({ ...domingo, exc_duracao: props.videoDuration });
    }
    
    setDisableDuration(false);
    props.close();
  };
  return (
    <div>
      <Modal
        aria-labelledby="MidiaDaysModal-modal-title"
        aria-describedby="MidiaDaysModal-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => handleClose()}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Typography
              variant="h2"
              style={{ marginBottom: 30, textAlign: "center" }}
            >
              Exibição semanal da Mídia
            </Typography>
            <div className={classes.dayContainer}>
              <ToggleButtonGroup
                color="primary"
                value={props.daysToDisplay}
                onChange={props.handleDaysToDisplay}
                orientation="vertical"
              >
                <ToggleButton value={"1"}>Segunda</ToggleButton>
                <ToggleButton value={"2"}>Terça</ToggleButton>
                <ToggleButton value={"3"}>Quarta</ToggleButton>
                <ToggleButton value={"4"}>Quinta</ToggleButton>
                <ToggleButton value={"5"}>Sexta</ToggleButton>
                <ToggleButton value={"6"}>Sábado</ToggleButton>
                <ToggleButton value={"0"}>Domingo</ToggleButton>
              </ToggleButtonGroup>
              <div style={{ width: "100%" }}>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setSegunda({ ...segunda, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={segunda.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "1")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'segunda' ? (
                          <div className={classes.errorText}>
                            Campo obrigatório.
                          </div>
                        ) : ''}
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setSegunda({ ...segunda, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={segunda.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "1")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'segunda' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                            ) : ''}
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={segunda.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setSegunda({ ...segunda, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "1") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "1") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setTerca({ ...terca, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={terca.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "2")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'terca' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''}
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setTerca({ ...terca, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={terca.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "2")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'terca' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''}
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={terca.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setTerca({ ...terca, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "2") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "2") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setQuarta({ ...quarta, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={quarta.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "3")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'quarta' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''}
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setQuarta({ ...quarta, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={quarta.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "3")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'quarta' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''}
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={quarta.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setQuarta({ ...quarta, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "3") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "3") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setQuinta({ ...quinta, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={quinta.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "4")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'quinta' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setQuinta({ ...quinta, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={quinta.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "4")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'quinta' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={quinta.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setQuinta({ ...quinta, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "4") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "4") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setSexta({ ...sexta, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={sexta.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "5")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'sexta' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setSexta({ ...sexta, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={sexta.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "5")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'sexta' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={sexta.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setSexta({ ...sexta, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "5") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "5") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setSabado({ ...sabado, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={sabado.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "6")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'sabado' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setSabado({ ...sabado, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={sabado.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "6")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'sabado' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={sabado.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setSabado({ ...sabado, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "6") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "6") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
                <div className={classes.timeInput}>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario início"
                      margin="normal"
                      onChange={(e) =>
                        setDomingo({ ...domingo, exc_horaInicio: e.target.value })
                      }
                      type="time"
                      size="small"
                      value={domingo.exc_horaInicio}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "0")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaInicio' && 
                      errorField.day === 'domingo' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.timeBox}
                      label="Horario final"
                      margin="normal"
                      size="small"
                      onChange={(e) =>
                        setDomingo({ ...domingo, exc_horaFim: e.target.value })
                      }
                      type="time"
                      value={domingo.exc_horaFim}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        props.daysToDisplay.find((day) => day === "0")
                          ? false
                          : true
                      }
                    />
                    {
                      errorField && 
                      errorField.field === 'exc_horaFim' && 
                      errorField.day === 'domingo' ? (
                            <div className={classes.errorText}>
                              Campo obrigatório.
                            </div>
                          ) : ''
                    }
                  </div>
                  <InputMask
                    mask={"99:99:99"}
                    value={domingo.exc_duracao}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setDomingo({ ...domingo, exc_duracao: e.target.value })
                    }
                    disabled={
                      props.daysToDisplay.find((day) => day === "0") && !disableDuration
                        ? false
                        : true
                    }
                  >
                    <TextField
                      className={classes.timeBox}
                      label="Duração"
                      margin="normal"
                      name="duracao"
                      size="small"
                      type="text"
                      variant="outlined"
                      disabled={
                        props.daysToDisplay.find((day) => day === "0") && !disableDuration
                          ? false
                          : true
                      }
                    />
                  </InputMask>
                </div>
              </div>
            </div>
            <div className={classes.saveButton}>
              <Button variant="contained" onClick={handleClose}>
                Salvar horários
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
