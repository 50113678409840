import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTabTitle from 'src/components/PageTabTitle';
import AlertDialog from 'src/components/AlertDialog';
import Watermark from 'src/components/Watermark';
import api, { apiUrl } from 'src/services/api';
import getCep from 'src/services/cep';
import unmask from 'src/services/unmask-values';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { validCNPJ, validEmail } from 'src/services/validDocument';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '760px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '60px',
    marginBottom: '60px'
  }
}));

const EditLotteryView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const previewUrl = `${apiUrl}/public`;

  const [currentLottery, setCurrentLottery] = useState({
    lot_id: '',
    lot_razaoSocial: '',
    lot_cnpj: '',
    lot_email: '',
    lot_cli_id: '',
    responsavel: null,
    end_cep: '',
    end_endereco: '',
    end_numero: '',
    end_complemento: '',
    end_bairro: '',
    end_cidade: '',
    end_estado: '',
    lot_marcaDaguaNome: '',
    lot_marcaDaguaPath: ''
  });

  const [startLoader, setStartLoader] = useState(false);
  const [activeTab, setactiveTab] = useState('primary');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  const [currentCep, setCurrentCep] = useState('');
  const [responsibles, setResponsibles] = useState([]);
  const [responsible, setResponsible] = useState({});
  const [client, setClient] = useState({});
  ///////////////////////////////////////
  // 11-11-2024
  //[RND] - Alterar Proprietário  
  const [clients, setClients] = useState([]);  
  //
  /////////////////////////////////////////  
  const [selectedWatermark, setSelectedWatermark] = useState(null);
  const [previewWatermark, setPreviewWatermark] = useState(null);

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nome: currentLottery.lot_razaoSocial,
      email: currentLottery.lot_email,
      cnpj: currentLottery.lot_cnpj,
      responsavel: null,
      cliente: null,
      cep: currentLottery.end_cep,
      endereco: currentLottery.end_endereco,
      numero: currentLottery.end_numero,
      complemento: currentLottery.end_complemento,
      bairro: currentLottery.end_bairro,
      cidade: currentLottery.end_cidade,
      uf: currentLottery.end_estado,
      freeText: currentLottery.lot_marcaDaguaNome ? currentLottery.lot_marcaDaguaNome : ''
    }
  });

  const changeActiveTab = (tab) => {
    setactiveTab(tab);
  }

  const handleSave = () => {
    let values = formik.values;
    const cnpjIsValid = validCNPJ(unmask(values.cnpj));

    if(!validEmail(values.email)) return openErrorAlert('Insira um email válido.');
    if(!values.nome) return openErrorAlert('Insira a Razão Social do cliente.');
    if(!values.email) return openErrorAlert('Insira o e-mail do cliente.');
    if(!values.cnpj) return openErrorAlert('Insira o CNPJ do cliente.');
    if(!cnpjIsValid) return openErrorAlert('Insira um CNPJ válido.')
    if(!responsible?.usr_id) return openErrorAlert('Selecione o responsável.');
    if(!client.cli_id) return openErrorAlert('Selecione o cliente.');
    if(!values.cep) return openErrorAlert('Insira o CEP.');
    if(!values.endereco) return openErrorAlert('Insira o endereço');
    if(!values.numero) return openErrorAlert('Insira o número do endereço.');
    if(!values.bairro) return openErrorAlert('Insira o bairro.');
    if(!values.cidade) return openErrorAlert('Insira a cidade.');
    if(!values.uf) return openErrorAlert('Insira o estado.');

    setStartLoader(true);

    let obj = {
      "lot_id": id,
      "lot_razaoSocial": values.nome,
      "lot_email": values.email,
      "lot_cnpj": unmask(values.cnpj),
      "lot_usr_id_responsavel": responsible.usr_id,
      "lot_cli_id": client.cli_id,
      "end_cep": unmask(values.cep),
      "end_endereco": values.endereco,
      "end_numero": values.numero.toString(),
      "end_complemento": values.complemento,
      "end_bairro": values.bairro,
      "end_cidade": values.cidade,
      "end_estado": values.uf
    }

    api.put('/loterica', obj)
      .then((resp) => {
        setStartLoader(false);
        setCurrentLottery(resp.data.result);
        openSuccessAlert();
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Lotérica atualizada com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const checkCep = (event) => {
    setCurrentCep(event.target.value);
    getCep(unmask(event.target.value))
      .then((resp) => {
        if (resp !== undefined && !resp.erro) {
          formik.setFieldValue('cep', event.target.value);
          formik.setFieldValue('endereco', resp.logradouro);
          formik.setFieldValue('bairro', resp.bairro);
          formik.setFieldValue('cidade', resp.localidade);
          formik.setFieldValue('uf', resp.uf);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  ///////////////////////////////////////
  // 11-11-2024
  //[RND] - Alterar Proprietário
  useEffect(() => {
    api.get('/cliente')
      .then((resp) => {
        setClients(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }, [setClients]);    
  //
  /////////////////////////////////////////  
    
  const fetchLottery = () => {
    api.get(`/loterica/${id}`)
      .then((resp) => {
        const { result } = resp.data;
        
        fetchResponsibles(result)
        setCurrentLottery(result)
        setClient({cli_id: result.lot_cli_id, cli_razaoSocial: result.cli_razaoSocial });
        setResponsible({id: result.lot_usr_id_responsavel,usr_id: result.lot_usr_id_responsavel, usr_nome: result.usr_nome});
        setPreviewWatermark(result.lot_marcaDaguaPath ? previewUrl + result.lot_marcaDaguaPath : null);
        setCurrentCep(result.end_cep);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }

  const fetchResponsibles = (lottery) => {
    api.get(`/user/cliente/${lottery.lot_cli_id}/ativos`)
    .then((resp) => {
      const { result } = resp.data;
      setResponsibles(result);
    })
    .catch((err) => {
      console.log(err);
      openFetchErrorAlert(err.response.data.message);
    })
  }

  const setWatermark = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedWatermark(selectedFile);

    const reader = new FileReader();
    
    reader.onloadend = () => {
      setPreviewWatermark(reader.result);
    }
    
    reader.readAsDataURL(selectedFile);
  }

  const removeWatermark = () => {
    setSelectedWatermark(null);
    setPreviewWatermark(null);
  }

  const applyWatermark = () => {
    if (selectedWatermark) {
      let data = new FormData();

      data.append("file", selectedWatermark, selectedWatermark.name);
      data.append("lot_marcaDaguaNome", formik.values.freeText );

      api.put(`/loterica/${id}/marcadagua`, data)
      .then((resp) => {
        setCurrentLottery(resp.data.result)
        openSuccessAlert();
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
    }

    if (!selectedWatermark && !previewWatermark) {
      api.delete(`/loterica/${id}/marcadagua`)
      .then((resp) => {
        setCurrentLottery(resp.data.result)
        openSuccessAlert();
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
    }
  }

  const backPage = () => {
    const url = `/${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}`;
    
    navigate(url);
  }

  useEffect(() => {
    fetchLottery();
  }, [id]);

  return (
    <Page className={classes.root} title="Atualizar Lotérica">
      <PageTabTitle primary="ATUALIZAR LOTÉRICA" secondary="MARCA D'ÁGUA" active={activeTab} changeTab={changeActiveTab} />
      <Container className={classes.container}>
      { 
        activeTab === "primary" ? (
          <>
            <Box className={classes.fullBox}>
              <TextField
                className={classes.field}
                fullWidth
                label="Razão Social"
                margin="normal"
                name="nome"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.nome}
                variant="outlined"
                required
              />

              <TextField
                className={classes.field}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.email}
                variant="outlined"
                required
              />

              <Box style={{ display: 'flex' }}>
                <InputMask 
                  mask={'99999999/9999-99'}
                  value={formik.values.cnpj}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={false}
                >
                  <TextField
                    style={{ display: 'flex', flex: 1 }}
                    className={classes.field}
                    label="CNPJ"
                    margin="normal"
                    name="cnpj"
                    type="text"
                    variant="outlined"
                    required
                  />
                </InputMask>

                <Autocomplete
                  value={responsible}
                  options={responsibles}
                  getOptionLabel={(option) => option.usr_nome || ''}
                  onChange={(event, value) => {
                    console.log(value)
                    setResponsible(value);
                  }}
                  style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px' }}
                  renderInput={(params) => <TextField {...params} label="Nome do responsável" variant="outlined" />}
                />
              </Box>
              
              <Box style={{ display: 'flex' }}>
              {
                  ///////////////////////////////////////
                  // 11-11-2024
                  //[RND] - Alterar Proprietário    
                }                
                <Autocomplete
                  value={client}
                  options={clients}
                  getOptionLabel={(option) => option.cli_razaoSocial || ''}
                  onChange={(event, value) => {
                    setClient(value);
                  }}
                  style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px', marginRight: '25px' }}
                  renderInput={(params) => <TextField {...params} label="Cliente (proprietário)" variant="outlined" />}
                />
                {
                  //
                  /////////////////////////////////////////
                }
                <InputMask 
                  mask={'99999-999'}
                  value={currentCep}
                  onChange={(event) => checkCep(event)}
                  disabled={false}
                >
                  <TextField
                    className={classes.field}
                    label="CEP"
                    margin="normal"
                    name="cep"
                    type="text"
                    variant="outlined"
                    required
                  />
                </InputMask>
              </Box>

              <Box style={{ display: 'flex' }}>
                <TextField
                  style={{ display: 'flex', flex: 1 }}
                  className={classes.field}
                  label="Endereço"
                  margin="normal"
                  name="endereco"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.endereco}
                  variant="outlined"
                  required
                />

                <TextField
                  label="Número"
                  margin="normal"
                  name="numero"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.numero}
                  variant="outlined"
                  required
                />
              </Box>

              <Box style={{ display: 'flex' }}>
                <TextField
                  className={classes.field}
                  label="Complemento"
                  margin="normal"
                  name="complemento"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.complemento}
                  variant="outlined"
                />

                <TextField
                  style={{ display: 'flex', flex: 1 }}
                  label="Bairro"
                  margin="normal"
                  name="bairro"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.bairro}
                  variant="outlined"
                  required
                />
              </Box>

              <Box style={{ display: 'flex' }}>
                <TextField
                  style={{ display: 'flex', flex: 1 }}
                  className={classes.field}
                  label="Cidade"
                  margin="normal"
                  name="cidade"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.cidade}
                  variant="outlined"
                  required
                />

                <TextField
                  label="UF"
                  margin="normal"
                  name="uf"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.uf}
                  variant="outlined"
                  required
                />
              </Box>
            </Box>
      
            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={handleSave}
              >
                Salvar
              </Button>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{marginLeft: 5, backgroundColor: '#F07C00', color: 'white'}}
              >
                Cancelar
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <TextField
                className={classes.field}
                fullWidth
                label="Texto livre (opcional)"
                margin="normal"
                name="freeText"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.freeText}
                variant="outlined"
              />

              <Watermark value={'image'} setWatermark={setWatermark} removeWatermark={removeWatermark} preview={previewWatermark} buttons />
            </Box>

            <Typography style={{ marginTop: 20 }}>Recomendamos que a imagem seja em formato PNG, com transparência.</Typography>

            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={applyWatermark}
                style={{width: 285, marginRight: 30}}
              >
                Atualizar
              </Button>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{marginLeft: 5, backgroundColor: '#F07C00', color: 'white'}}
              >
                Cancelar
              </Button>
            </Box>
          </>
        )
      }
      </Container>

      <Loader
        display={startLoader}
      />

      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default EditLotteryView;
