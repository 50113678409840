import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTabTitle from 'src/components/PageTabTitle';
import AlertDialog from 'src/components/AlertDialog';
import Watermark from 'src/components/Watermark';
import api, { apiUrl } from 'src/services/api';
import getCep from 'src/services/cep';
import unmask from 'src/services/unmask-values';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '760px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '30px',
    marginBottom: '40px'
  }
}));

const EditSingleClientView = () => {
  const classes = useStyles();
  const me = useSelector((state) => state.auth.user);
  const previewUrl = `${apiUrl}/public`;

  const [currentClient, setCurrentClient] = useState({
    cli_id: '',
    cli_razaoSocial: '',
    cli_email: '',
    cli_cnpj: '',
    responsavel: null,
    end_cep: '',
    end_endereco: '',
    end_numero: '',
    end_complemento: '',
    end_bairro: '',
    end_cidade: '',
    end_estado: '',
    cli_marcaDaguaNome: '',
    cli_marcaDaguaPath: ''
  });

  const [id, setId] = useState(0);
  const [activeTab, setactiveTab] = useState('primary');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  const [currentCep, setCurrentCep] = useState('');
  const [responsibles, setResponsibles] = useState([]);
  const [responsible, setResponsible] = useState({});
  const [selectedWatermark, setSelectedWatermark] = useState(null);
  const [previewWatermark, setPreviewWatermark] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: currentClient.cli_id,
      nome: currentClient.cli_razaoSocial,
      email: currentClient.cli_email,
      cnpj: currentClient.cli_cnpj,
      responsavel: null,
      cep: currentClient.end_cep,
      endereco: currentClient.end_endereco,
      numero: currentClient.end_numero,
      complemento: currentClient.end_complemento,
      bairro: currentClient.end_bairro,
      cidade: currentClient.end_cidade,
      uf: currentClient.end_estado,
      freeText: currentClient.cli_marcaDaguaNome ? currentClient.cli_marcaDaguaNome : ''
    }
  });

  const changeActiveTab = (tab) => {
    setactiveTab(tab);
  }

  const handleSave = () => {
    let values = formik.values;

    let obj = {
      "cli_id": id,
      "cli_razaoSocial": values.nome,
      "cli_email": values.email,
      "cli_cnpj": unmask(values.cnpj),
      "cli_usr_id_responsavel": responsible.usr_id,
      "end_cep": unmask(values.cep),
      "end_endereco": values.endereco,
      "end_numero": values.numero,
      "end_complemento": values.complemento,
      "end_bairro": values.bairro,
      "end_cidade": values.cidade,
      "end_estado": values.uf
    }

    api.put('/cliente', obj)
      .then((resp) => {
        setCurrentClient(resp.data.result)
        openSuccessAlert();
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Cliente atualizado com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const checkCep = (event) => {
    setCurrentCep(event.target.value);
    getCep(unmask(event.target.value))
      .then((resp) => {
        if (resp !== undefined && !resp.erro) {
          formik.setFieldValue('cep', event.target.value);
          formik.setFieldValue('endereco', resp.logradouro);
          formik.setFieldValue('bairro', resp.bairro);
          formik.setFieldValue('cidade', resp.localidade);
          formik.setFieldValue('uf', resp.uf);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const fetchClient = () => {
    api.get(`/cliente/${id}`)
      .then((resp) => {
        const { result } = resp.data;

        fetchResponsibles(result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }

  const fetchResponsibles = (client) => {
    api.get(`/user`)
      .then((resp) => {
        const { result } = resp.data;

        setResponsibles(result);

        setCurrentClient(client);
        setPreviewWatermark(client.cli_marcaDaguaPath ? previewUrl + client.cli_marcaDaguaPath : null);
        setCurrentCep(client.end_cep);

        let clientRespArr = result.filter((ele) => ele.usr_id === client.cli_usr_id_responsavel);
        if (clientRespArr.length > 0) {
          setResponsible(clientRespArr[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }

  const setWatermark = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedWatermark(selectedFile);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewWatermark(reader.result);
    }

    reader.readAsDataURL(selectedFile);
  }

  const removeWatermark = () => {
    setSelectedWatermark(null);
    setPreviewWatermark(null);
  }

  const applyWatermark = () => {
    if (selectedWatermark) {
      let data = new FormData();

      data.append("file", selectedWatermark, selectedWatermark.name);
      data.append("cli_marcaDaguaNome", formik.values.freeText);

      api.put(`/cliente/${id}/marcadagua`, data)
        .then((resp) => {
          setCurrentClient(resp.data.result)
          openSuccessAlert();
        })
        .catch((err) => {
          console.log(err);
          openErrorAlert(err.response.data.message);
        })
    }

    if (!selectedWatermark && !previewWatermark) {
      api.delete(`/cliente/${id}/marcadagua`)
        .then((resp) => {
          setCurrentClient(resp.data.result)
          openSuccessAlert();
        })
        .catch((err) => {
          console.log(err);
          openErrorAlert();
        })
    }
  }

  const applyWatermarkToAll = () => {
    if (selectedWatermark) {
      let data = new FormData();

      data.append("file", selectedWatermark, selectedWatermark.name);
      data.append("cli_marcaDaguaNome", formik.values.freeText);

      api.put(`/cliente/${id}/marcadagua/alllotericas`, data)
        .then((resp) => {
          setCurrentClient(resp.data.result)
          openSuccessAlert();
        })
        .catch((err) => {
          console.log(err);
          openErrorAlert(err.response.data.message);
        })
    }
  }

  useEffect(() => {
    if (id === 0) {
      setId(me.usr_cli_id);
    } else {
      fetchClient();
    }
  }, [id]);

  return (
    <Page className={classes.root} title="Atualizar Cliente">
      <PageTabTitle primary="ATUALIZAR CLIENTE" secondary="MARCA D'ÁGUA" active={activeTab} changeTab={changeActiveTab} />
      <Container className={classes.container}>
        {
          activeTab === "primary" ? (
            <>
              <Box className={classes.fullBox}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Razão Social"
                  margin="normal"
                  name="nome"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.nome}
                  variant="outlined"
                  required
                  disabled
                />

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.email}
                  variant="outlined"
                  required
                />

                <Box style={{ display: 'flex' }}>
                  <InputMask
                    mask={'99999999/9999-99'}
                    value={formik.values.cnpj}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={true}
                  >
                    <TextField
                      style={{ display: 'flex', flex: 1 }}
                      className={classes.field}
                      label="CNPJ"
                      margin="normal"
                      name="cnpj"
                      type="text"
                      variant="outlined"
                      required
                    />
                  </InputMask>

                  <Autocomplete
                    value={responsible}
                    options={responsibles}
                    getOptionLabel={(option) => option.usr_nome || ''}
                    onChange={(event, value) => {
                      setResponsible(value);
                    }}
                    style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px' }}
                    renderInput={(params) => <TextField {...params} label="Nome do responsável" variant="outlined" />}
                  />
                </Box>

                <InputMask
                  mask={'99999-999'}
                  value={currentCep}
                  onChange={(event) => checkCep(event)}
                  disabled={false}
                >
                  <TextField
                    className={classes.field}
                    label="CEP"
                    margin="normal"
                    name="cep"
                    type="text"
                    variant="outlined"
                    required
                  />
                </InputMask>

                <Box style={{ display: 'flex' }}>
                  <TextField
                    style={{ display: 'flex', flex: 1 }}
                    className={classes.field}
                    label="Endereço"
                    margin="normal"
                    name="endereco"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.endereco}
                    variant="outlined"
                    required
                  />

                  <TextField
                    label="Número"
                    margin="normal"
                    name="numero"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.numero}
                    variant="outlined"
                    required
                  />
                </Box>

                <Box style={{ display: 'flex' }}>
                  <TextField
                    className={classes.field}
                    label="Complemento"
                    margin="normal"
                    name="complemento"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.complemento}
                    variant="outlined"
                  />

                  <TextField
                    style={{ display: 'flex', flex: 1 }}
                    label="Bairro"
                    margin="normal"
                    name="bairro"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.bairro}
                    variant="outlined"
                    required
                  />
                </Box>

                <Box style={{ display: 'flex' }}>
                  <TextField
                    style={{ display: 'flex', flex: 1 }}
                    className={classes.field}
                    label="Cidade"
                    margin="normal"
                    name="cidade"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.cidade}
                    variant="outlined"
                    required
                  />

                  <TextField
                    label="UF"
                    margin="normal"
                    name="uf"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.uf}
                    variant="outlined"
                    required
                  />
                </Box>
              </Box>

              <Box className={classes.buttonBox}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleSave}
                >
                  Salvar
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Texto livre (opcional)"
                  margin="normal"
                  name="freeText"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.freeText}
                  variant="outlined"
                />

                <Watermark value={'image'} setWatermark={setWatermark} removeWatermark={removeWatermark} preview={previewWatermark} buttons />
              </Box>

              <Typography style={{ marginTop: 20 }}>Recomendamos que a imagem seja em formato PNG, com transparência.</Typography>

              <Box className={classes.buttonBox}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={applyWatermark}
                  style={{ width: 285, marginRight: 30 }}
                >
                  Atualizar
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={applyWatermarkToAll}
                >
                  Aplicar a todas as lotéricas
                </Button>
              </Box>
            </>
          )
        }
      </Container>

      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default EditSingleClientView;
