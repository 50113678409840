import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactCardFlip from 'react-card-flip';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ImageIcon from '@material-ui/icons/Image';
import Modal from '../components/Modal'
import api, { apiUrl } from '../services/api';

import PlaceholderImage from '../assets/placeholder-image.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: 287,
    height: 161,
    '& h2': {
      fontSize: '10px',
      color: '#5E5E5E',
      paddingBottom: 2,
      '& p': {
        fontSize: '14px',
        marginTop: 8,
        transform: 'translatey(-5px)',
        '& svg': {
          width: 18,
        },
      },
    },
  },
  media: {
    borderRadius: 0,
  },
  cardAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: "25%",
    backgroundColor: "#000",
    '& button': {
      borderRadius: 0,
      border: 'none',
      width: 50,
      height: 50,
      margin: theme.spacing(1),
      backgroundColor: "#fff",
      '& svg': {
        fontSize: '2.5rem'
      },
    },
  },
  time: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
  type: {
    '& svg': {
      transform: 'translatey(6px)',
      marginRight: 7,
    },
  },
  cardMedia: {
    backgroundSize: "cover"
  }
}));


const VideoFrame = ({ image, imgUrl, handleError, classes, mulPath }) => {
  console.log(image)
  return (
    <img
      className={classes.cardMedia}
      width="100%"
      height="100%"
      onError={handleError}
      src={!image || image === '' ? PlaceholderImage : imgUrl + image }
    />
  )
}

const MyCard = (props) => {
  const imgUrl = apiUrl
  const classes = useStyles();
  const [image, setImage] = useState(null)
  
  const [open, setOpen] = useState({ isFlipped: false });
  const [openModal, setOpenModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedClass, setSelectedClass] = useState(props.className ? props.className : classes.root);

  const [videoError, setVideoError] = useState(false);
  
  useEffect(() => {
    if(props.image) {
      const url = `${imgUrl}${props.image}`;
      
      const img = new Image();
      img.src = url;
      
      img.onload = () => {
        setImage(url);
      };
      
      img.onerror = () => {
        setImage(PlaceholderImage)
      };
    } else {
      setImage(PlaceholderImage);
    }
  }, [props.image, imgUrl]);

  const handleClick = (e) => {
    setOpen(!open)
  }

  const handleSelected = (id) => {
    if (!isSelected) {
      if (!props.alreadySelected) {
        setIsSelected(true);  
        setSelectedClass(props.selectedClassName);

        props.onSelect(props.id)
      }
    } else {
      setIsSelected(false);
      setSelectedClass(props.className);
      props.onSelect(props.id)
    }    
  }

  const handleError = () => {
    setVideoError(true)
  }
  return (
    <ReactCardFlip
      flipSpeedBackToFront={0.5}
      flipSpeedFrontToBack={0.5}
      isFlipped={!open}
      className={selectedClass}
    >
      <Card
        className={selectedClass}
        width={props.width}
        height={props.height}
        onClick={(e) => {
          if (props.flippable && props.flippable === true) {
            handleClick();
          }

          if (props.selectable && props.selectable === true) {
            handleSelected(props.id)
          }
        }}
      >
        {props.image && props.image.slice(props.image.length - 3) === 'mp4' ? (
          <>
            {
              videoError ? (
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  src={PlaceholderImage}
                />
              ) : (
                <VideoFrame 
                  classes={classes}
                  handleError={handleError}
                  image={props.image}
                  mulPath={props.mulPath}
                  imgUrl={imgUrl || PlaceholderImage}
                />
                // <iframe
                //   className={classes.cardMedia}
                //   width="100%"
                //   height="100%"
                //   onError={handleError}
                //   controls
                //   src={!props.image || props.image === '' ? PlaceholderImage : imgUrl + props.image }
                //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                //   allowFullScreen
                // >
                //   {/* <source src={!props.image || props.image === '' ? PlaceholderImage : imgUrl + props.image } type='video/mp4'/> */}
                // </iframe>
              )
            }
          </>
        ) : (
            <CardMedia
              className={classes.cardMedia}
              component="img"
              src={image}
            />
          )
        }
        {isSelected ? <div style={{position: 'absolute', backgroundColor: 'rgba(240, 124, 0, 0.45)', height: '98%', width: '98%', top: 1 }} /> : null}
      </Card>

      <div onClick={(e) => handleClick()}>
        { props.type === "video" ?
          <>
            <Card
              className={selectedClass}
            >
              <CardActions className={classes.cardAction}>
                { props.dragAndDrop ?
                  <>
                    <button>
                      <OpenWithIcon />
                    </button>
                    <button onClick={() => console.log(props.id)} >
                      <VisibilityIcon />
                    </button>
                    <button onClick={() => console.log(props.id)}>
                      <CloseIcon />
                    </button>
                  </>
                  :
                  <>
                    <button onClick={() => console.log(props.id)}>
                      <VisibilityIcon />
                    </button>
                    <button onClick={() => console.log(props.id)}>
                      <CloseIcon />
                    </button>
                  </>
                }
              </CardActions>

              <CardActionArea>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    NOME
                    <p>{props.name}</p>
                  </Typography>
                  <Typography variant="h5" component="h2">
                    TIPO
                    <p className={classes.type}><PlayArrowIcon />{props.type.toUpperCase()}</p>
                  </Typography>
                  <Typography variant="h5" component="h2">
                    CATEGORIA
                    <p>{props.category}</p>
                  </Typography>
                  <div className={classes.time}>
                    <Typography variant="h5" component="h2">
                      DURAÇÃO
                      <p>{props.duration}</p>
                    </Typography>
                    <Typography variant="h5" component="h2">
                      HORARIO DE APRESENTAÇÃO
                      <p>{props.presentation}</p>
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>

            <Modal
              type={props.type}
              open={openModal}
              close={() => setOpenModal(false)}
            />
          </>
          :
          <>
            <Card className={selectedClass}>
              {
                props.displayButtons === true && (
                  <CardActions className={classes.cardAction}>
                    {
                      props.dragAndDrop ? (
                        <>
                          <button>
                            <OpenWithIcon />
                          </button>
                          <button>
                            <CloseIcon />
                          </button>
                          <button onClick={() => setOpenModal(true)} >
                            <VisibilityIcon />
                          </button>
                        </>
                      ) : (
                        <>
                          <button>
                            <CloseIcon />
                          </button>
                          <button onClick={() => setOpenModal(true)} >
                            <VisibilityIcon />
                          </button>
                        </>
                      )
                    }
                  </CardActions>
                )
              }

              <CardActionArea>
                <CardContent>
                  { props.name && (
                    <Typography variant="h5" component="h2">
                      NOME
                      <p>{props.name}</p>
                    </Typography>
                  )}
                  
                  { props.type && (
                    <Typography variant="h5" component="h2">
                      TIPO
                      <p className={classes.type}><ImageIcon />{props.type.toUpperCase()}</p>
                    </Typography>
                  )}
                  
                  { props.category && (
                    <Typography variant="h5" component="h2">
                      CATEGORIA
                      <p>{props.category}</p>
                    </Typography>
                    )
                  }

                  { props.presentation && (
                    <div className={classes.time}>
                      <Typography variant="h5" component="h2">
                        HORARIO DE APRESENTAÇÃO
                        <p>{props.presentation}</p>
                      </Typography>
                    </div>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </>
        }
      </div>
    </ReactCardFlip>
  );
}

export default MyCard;