import React, { useEffect, useRef, useState } from "react";
import { Fab, Box, Button, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    margin: "15px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "23rem",
    height: "10rem",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  inputUpload: {
    display: "none",
  },
  uploadButton: {
    width: "56px",
    height: "56px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: "#FFF",
    backgroundColor: "#009C6D",
    transform: "translatey(-10px)",
  },
  uploadSvg: {
    fontSize: 35,
  },
  defaultSvg: {
    fontSize: 55,
  },
  uploadImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(3),
    marginTop: 10,
  },
}));

const Watermark = (props) => {
  const classes = useStyles();
  const fileInputRef = useRef();

  const [loaded, setLoaded] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();

    fileInputRef.current.click();
  };

  useEffect(() => {
    loadImage();
  }, [props.preview]);

  function loadImage() {
    const url = props.preview;

    const img = new Image();
    img.src = url;

    img.onload = () => {
      setLoaded(true);
    };

    img.onerror = () => {
      setLoaded(false);
    };
  }

  return (
    <>
      {props.preview && loaded ? (
        <>
          <Box style={{ height: 182, width: 392 }}>
            <img
              src={props.preview}
              alt="marca d'agua"
              className={classes.uploadImage}
            />
          </Box>

          <Box className={classes.buttons}>
            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={props.removeWatermark}
            >
              Remover
            </Button>
          </Box>
        </>
      ) : (
        <Box className={classes.dropzone}>
          <input
            onChange={(e) => props.setWatermark(e)}
            accept={`${props.value}/*`}
            ref={fileInputRef}
            className={classes.inputUpload}
            id="icon-button-file"
            type="file"
          />

          <label htmlFor="icon-button-file">
            <Fab
              elevation="disable"
              onClick={handleClick}
              className={classes.uploadButton}
              color="secondary"
              aria-label="upload picture"
              component="span"
            >
              <AddIcon className={classes.defaultSvg} />
            </Fab>
          </label>

          <Typography>Adicionar nova mídia</Typography>
        </Box>
      )}
    </>
  );
};

export default Watermark;
