import React, { createRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  IconButton,
  Fade,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  TextField,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import ImageEditor from "@toast-ui/react-image-editor";
import dataURLtoFile from "src/services/b64-to-file";
import MultimediaDropzone from "./MultimediaDropzone";
import api from "src/services/api";
import AlertDialog from "./AlertDialog";
import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    width: "80vw",
    height: "85vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    "& img": {
      maxWidth: "75vw",
    },
    "& .tui-image-editor-header-logo": {
      display: "none",
    },
    "& .tui-image-editor-header-buttons": {
      display: "none",
    },
  },
  bigPaper: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    "& img": {
      maxWidth: "75vw",
    },
    "& .tui-image-editor-header-logo": {
      display: "none",
    },
    "& .tui-image-editor-header-buttons": {
      display: "none",
    },
  },
  button: {
    marginTop: 5,
    display: "flex",
    justifyContent: "center",
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
    margin: 0,
    padding: 0,
    border: "2px solid #000",
    borderRadius: "50%",
  },
  editTemplateClose: {
    position: "absolute",
    top: 20,
    right: 20,
    margin: 0,
    padding: 0,
    border: "2px solid #fff",
    borderRadius: "50%",
  },
  divRadios: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  radioGroup: {
    gap: theme.spacing(2),
  },
  divName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameBox: {
    width: "340px",
  },
  buttonSave: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const editorRef = createRef();

  const [startLoader, setStartLoader] = useState(false);

  const [radioValue, setRadioValue] = useState("");
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [toSave, setToSave] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [name, setName] = useState("");

  const saveImage = () => {
    if (editorRef.current) {
      const editorInstance = editorRef.current.getInstance();

      let b64 = editorInstance.toDataURL();
      let file = dataURLtoFile(b64, "Template");
      props.saveTemplate(file, b64);
    }
  };

  const setMedia = (e, toSave) => {
    const selectedFile = e.target.files[0];
    setName(selectedFile.name.split(".")[0]);

    if (selectedFile.type.includes("image")) {
      setRadioValue("image")
    } else {
      setRadioValue("video")
    }

    if (toSave) setToSave(true);

    setSelectedMedia(selectedFile);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewMedia(reader.result);
    };

    reader.readAsDataURL(selectedFile);
  };

  const removeMedia = () => {
    setSelectedMedia(null);
    setPreviewMedia(null);
    setName('');
  };

  const updateMedia = () => {
    if (!name || name === '') return openErrorAlert("Insira o nome da mídia.");

    setStartLoader(true);
    const mediaType = radioValue;
    const media = selectedMedia;
    const mediaId = props.multimediaIdToEdit;

    const formData = new FormData();
    formData.append("mul_id", mediaId);
    formData.append("mul_nome", name);
    formData.append("mul_tipo", mediaType === "image" ? "imagem" : mediaType);
    formData.append("file", media, media.name);

    //REQ =
    api
      .patch("/multimidia", formData)
      .then((resp) => {
        setStartLoader(false);
        openSuccessAlert();
        props.isLoaded(false);
        setToSave(false);
        props.close();
      })
      .catch((err) => {
        setStartLoader(false);
        openErrorAlert(err.response.data.message);
      });
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
    // navigate('/app/biblioteca');
  };

  const openSuccessAlert = () => {
    if (props.isEdit) {
      setAlertText("Edição realizada com sucesso.");
      setAlertType("success");
      setAlertButtonText("Fechar");

      setOpenAlert(true);
    } else {
      setAlertText("Cadastro realizado com sucesso.");
      setAlertType("success");
      setAlertButtonText("Fechar");

      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (props.type === "edit-template" && editorRef.current) {
      const editorInstance = editorRef.current.getInstance();
      editorInstance
        .loadImageFromURL(props.content, "Template")
        .then((result) => {
          editorInstance.ui.activeMenuEvent();
        });
    }

    if (props.isEdit) {
      if (props.content) setPreviewMedia(props.content);
      setRadioValue(props.typeEdit);
    }
  }, [props.content, props.typeEdit]);

  return (
    <div>
      <Modal
        disableEnforceFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => {
          setToSave(false);
          setPreviewMedia(null);
          props.close();
        }}
      >
        <Fade
          in={props.open}
          style={{
            paddingTop: props.type === "edit-template" ? "0" : "3%",
          }}
        >
          <div
            className={
              props.type === "edit-template" ? classes.bigPaper : classes.paper
            }
          >
            {props.type === "video" && (
              <iframe
                width="100%"
                height="100%"
                src={props.content}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            )}

            {(props.type === "imagem" || props.type === "template") &&
              !props.isEdit && <img src={props.content} alt="exibicão de imagem dentro do modal" />}

            {props.type === "edit-template" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                }}
              >
                <ImageEditor
                  ref={editorRef}
                  usageStatistics={false}
                  includeUI={{
                    uiSize: {
                      width: "100%",
                    },
                    menuBarPosition: "bottom",
                  }}
                  selectionStyle={{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                  }}
                />

                <Box className={classes.button}>
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={saveImage}
                  >
                    Salvar
                  </Button>
                </Box>
              </div>
            )}

            <IconButton
              className={
                props.type === "edit-template"
                  ? classes.editTemplateClose
                  : classes.close
              }
              aria-label="close"
              onClick={() => {
                setToSave(false);
                setPreviewMedia(null);
                props.close();
              }}
            >
              <CloseIcon
                style={{ color: props.type === "edit-template" && "#fff" }}
              />
            </IconButton>
            {props.isEdit ? (
              <div>
                <FormControl className={classes.divRadios} component="fieldset">
                  <FormLabel component="span">
                    Selecione o tipo de mídia
                  </FormLabel>
                  <RadioGroup
                    className={classes.radioGroup}
                    row
                    aria-label="midia"
                    name="midia"
                    value={radioValue}
                    onChange={(event) => {
                      setPreviewMedia(null);
                      setRadioValue(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="image"
                      control={<Radio color="primary" />}
                      label="Imagem"
                      disabled={radioValue !== "template"}
                    />
                    <FormControlLabel
                      value="video"
                      control={<Radio color="primary" />}
                      label="Vídeo"
                      disabled={radioValue !== "template"}
                    />
                  </RadioGroup>
                </FormControl>
                <MultimediaDropzone
                  value={radioValue}
                  setMedia={setMedia}
                  acceptField="image/*, video/mp4"
                  removeMedia={removeMedia}
                  updateMedia={updateMedia}
                  preview={previewMedia}
                  isEdit={true}
                  toSave={toSave}
                  buttons
                  size="large"
                />
                {toSave && selectedMedia ? (
                  <div className={classes.divName}>
                    <TextField
                      className={classes.nameBox}
                      label="Nome"
                      margin="normal"
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      value={name}
                      variant="outlined"
                      required
                    />
                  </div>
                ) : null}
                {toSave && selectedMedia ? (
                  <Box className={classes.buttonSave}>
                    <Button
                      disableElevation
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        updateMedia();
                      }}
                    >
                      Salvar
                    </Button>
                  </Box>
                ) : null}
              </div>
            ) : null}
          </div>
        </Fade>
      </Modal>

      <Loader display={startLoader} />
      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </div>
  );
}
