import React, { useState } from 'react';
import HeaderBar from 'src/components/Header/HeaderBar';
import DrawerMenu from 'src/components/DrawerMenu';


const Header = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open, event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };
  
  return (
    <div>
      <HeaderBar toggle={toggleDrawer}/>
      <DrawerMenu open={open} toggle={toggleDrawer} />
    </div>
  );
}

export default Header;