import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  makeStyles,
  Select,
  TextField,
} from "@material-ui/core";
import MultimediaDropzone from "src/components/MultimediaDropzone";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import AlertDialog from "src/components/AlertDialog";
import api from "src/services/api";
import Loader from "src/components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  green: {
    color: "#009C6D",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
  },
  nameBox: {
    width: "340px",
  },

  uploadBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "340px",
    height: "170px",

    border: "1px dashed #ccc",
  },

  select: {
    margin: theme.spacing(2),
    width: "340px",
  },

  button: {
    marginTop: theme.spacing(2),
    "& span": {
      fontSize: "12px",
      color: "#9E9E9E",
    },
  },

  buttonSave: {
    marginTop: theme.spacing(5),
    marginBottom: "40px",
  },

  divRadios: {
    display: "flex",
    justifyContent: "center",
  },

  radioGroup: {
    gap: theme.spacing(4),
  },

  template: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& input": {
      width: 294,
    },
  },

  templateInput: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },

  templateMedia: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(8),
    alignItems: "center",
    flexDirection: "row",
  },

  card: {
    position: "relative",
  },

  cardMedia: {
    position: "relative",
    width: 200,
    height: 200,
  },

  templateCard: {
    display: "flex",
    flexDirection: "column",
  },

  buttonBox: {
    display: "flex",
    flexDirection: "row",
  },

  buttonMedia: {
    position: "absolute",
    boxShadow: " 0 3px 4px -2px rgba(0,0,0,0.25)",
    margin: 0,
    bottom: 10,
    left: 2,

    "& button": {
      border: "1px solid #F07C00",
      fontSize: 12,
      fontWeigth: 500,
      borderRadius: 4,
      marginLeft: 8,
      width: 75,
      height: 36,
      backgroundColor: "#fff",
    },
  },

  inputUpload: {
    display: "none",
  },
}));

const NewTemplate = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [startLoader, setStartLoader] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [name, setName] = useState("");
  const [loaded, setIsLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [previewMedia, setPreviewMedia] = useState(null);

  const fetchCategories = () => {
    api
      .get(`/categoria/all/ativos`)
      .then((resp) => {
        const { result } = resp.data;

        setCategories(result);
        setSelectValue(result[0].cat_id);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
        openFetchErrorAlert(err.response.data.message);
      });
  };

  const setMedia = (e) => {
    const selectedFile = e.target.files[0];
    setName(selectedFile.name.split(".")[0]);
    setSelectedMedia(selectedFile);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewMedia(reader.result);
    };

    reader.readAsDataURL(selectedFile);
  };

  const removeMedia = () => {
    setSelectedMedia(null);
    setPreviewMedia(null);
    setName("");
    setSelectValue("");
  };

  const postTemplate = () => {
    if (!name) return openErrorAlert("Insira o nome do template.");
    if (!selectedMedia) return openErrorAlert("Insira uma mídia.");
    if (!selectValue) return openErrorAlert("Selecione a categoria.");

    setStartLoader(true);
    let data = new FormData();

    data.append("tem_nome", name);
    data.append("tem_tipo", "template");
    data.append("tem_cat_id", selectValue);
    data.append("file", selectedMedia, selectedMedia.name);

    api
      .post("/template", data)
      .then((resp) => {
        setStartLoader(false);
        openSuccessAlert();
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Cadastro realizado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    if (type !== "error") navigate("/app/templates");
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split("/")[1]}/templates`;

    navigate(url);
  };

  useEffect(() => {
    if (!loaded) {
      fetchCategories();
    }
  }, [loaded]);

  return (
    <Page className={classes.root} title="Novo Template">
      <PageTitle title="NOVO TEMPLATE" />
      <Container className={classes.container}>
        <TextField
          className={classes.nameBox}
          label="Nome"
          margin="normal"
          onChange={(e) => setName(e.target.value)}
          type="text"
          value={name}
          variant="outlined"
        />

        <MultimediaDropzone
          value={"image"}
          acceptField="image/*"
          setMedia={setMedia}
          removeMedia={removeMedia}
          preview={previewMedia}
          buttons
          size="normal"
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            className={classes.select}
            native
            value={selectValue}
            onChange={(event) => setSelectValue(event.target.value)}
          >
            <option value="">Selecione a categoria</option>
            {categories.map((cat) => (
              <option value={cat.cat_id}>{cat.cat_nome}</option>
            ))}
          </Select>
        </FormControl>

        <Box className={classes.buttonSave}>
          <Button
            disableElevation
            size="large"
            variant="outlined"
            onClick={postTemplate}
          >
            SALVAR
          </Button>
          <Button
            disableElevation
            size="large"
            variant="outlined"
            onClick={backPage}
            style={{
              marginLeft: 5,
              backgroundColor: "#F07C00",
              color: "white",
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Container>

      <Loader display={startLoader} />
      <AlertDialog
        open={openAlert}
        handleClose={() => handleAlertClose(alertType)}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

export default NewTemplate;
