import { combineReducers } from 'redux';
import auth from './auth';
import report from './report';

const Reducers = combineReducers({
  auth,
  report
});

export default Reducers;
