import React, { useRef } from "react";
import { Fab, Box, Button, makeStyles, Typography, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "23rem",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  inputUpload: {
    display: "none",
  },
  uploadButton: {
    width: "56px",
    color: "#FFF",
    backgroundColor: "#009C6D",
  },
  uploadSvg: {
    fontSize: 35,
  },
  defaultSvg: {
    fontSize: 55,
  },
  uploadImage: {
    objectFit: "cover",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(3),
    marginTop: 10
  },
  trashBtnContainer: {
    borderRadius: "50%",
    width: 50,
    position: "absolute",
    top: -30,
    right: -32,
    background: "#FFF",
    // border: "none",
    boxShadow: "0px 1px 29px -2px rgba(133, 128, 128, .5)",
    "-webkit-box-shadow": "0px 1px 29px -2px rgba(133, 128, 128, .5)",
    "-moz-box-shadow": "0px 1px 29px -2px rgba(133, 128, 128, .5)",
    "&:hover": {
      backgroundColor: "#FFF"
    }
  },
  editTemplateClose: {
    position: "absolute",
    top: 20,
    right: 20,
    margin: 0,
    padding: 0,
    border: "2px solid #fff",
    borderRadius: "50%",
  },
}));

const MultimediaDropzone = (props) => {
  const classes = useStyles();
  const fileInputRef = useRef();
  const videoRef = useRef(null);


  const sizeStyle = {
    small: {
      container: {
        height: "12rem"
      },
      box: {
        width: "23rem",
        height: "12rem"
      }
    },
    normal: {
      container: {
        height: "12rem"
      },
      box: {
        width: "23rem",
        height: "12rem"
      }
    },
    large: {
      container: {
        height: "25rem"
      },
      box: {
        width: "46rem",
        height: "25rem"
      }
    }
  }
  const handleClick = (event) => {
    event.preventDefault();

    fileInputRef.current.click();
  };

  const handleVideoInfo = (e) => {
    if (props.setVideoDuration) {
      let time = e.target.duration;

      let hour = Math.floor(time / 3600);
      let minutes = Math.floor((time - hour * 3600) / 60);
      let seconds = Math.floor(time % 60);

      if (hour < 10) hour = "0" + hour;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      const durationFormated = hour + ":" + minutes + ":" + seconds;
      props.setVideoDuration(durationFormated);
    }
  };

  return (
    <div style={{ height: sizeStyle[props.size].container.height }}>
      {props.preview ? (
        <>
          <Box
            style={{
              width: `${sizeStyle[props.size].box.width}`,
              height: `${sizeStyle[props.size].box.height}`,
              position: "relative",
            }}
          >
            {props.value === "image" ? (
              <img
                src={props.preview}
                alt="pré visualização da mídia"
                width="100%"
                height="100%"
                className={classes.uploadImage}
              />
            ) : (
              <video
                width="100%"
                height="100%"
                controls
                onLoadedData={handleVideoInfo}
              >
                <source
                  ref={videoRef}
                  src={props.preview}
                  id="video_here"
                  style={{ width: "100%", height: "100%" }}
                />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Box className={classes.buttons}>
              {!props.isEdit ? (
                <Button
                  disableElevation
                  size="large"
                  onClick={props.removeMedia}
                  className={classes.trashBtnContainer}
                >
                  <DeleteIcon style={{ color: "#5E5E5E", fontSize: 40 }} />
                </Button>
              ) : (
                <Button
                  disableElevation
                  size="large"
                  variant={"raised"}
                  className={classes.trashBtnContainer}
                  onClick={() => {
                    props.removeMedia();
                  }}
                >
                  <DeleteIcon style={{ color: "#5E5E5E", fontSize: 48 }} />
                </Button>
              )}
            </Box>
          </Box>

        </>
      ) : (
        <>
          <Box
            className={classes.dropzone}
            style={{
              width: `${sizeStyle[props.size].box.width}`,
              height: `${sizeStyle[props.size].box.height}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <input
              onChange={(e) => props.setMedia(e, true)}
              accept={props.acceptField}
              ref={fileInputRef}
              className={classes.inputUpload}
              id="icon-button-file"
              type="file"
            />
            <label htmlFor="icon-button-file">
              <Fab
                elevation="disable"
                onClick={handleClick}
                className={classes.uploadButton}
                color="secondary"
                aria-label="upload multimedia"
                component="span"
              >
                <AddIcon className={classes.defaultSvg} />
              </Fab>
            </label>

            {props.size !== "small" && <Typography style={{ marginTop: 16 }}>Adicionar nova mídia</Typography>}
          </Box>
        </>
      )}
    </div>
  );
};

export default MultimediaDropzone;
