
const deafaultDaysToDisplay = [
    {
        exc_dia_semana: "segunda",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    },
    {
        exc_dia_semana: "terca",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    },
    {
        exc_dia_semana: "quarta",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    },
    {
        exc_dia_semana: "quinta",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    },
    {
        exc_dia_semana: "sexta",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    },
    {
        exc_dia_semana: "sabado",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    },
    {
        exc_dia_semana: "domingo",
        exc_horaInicio: null,
        exc_horaFim: null,
        exc_duracao: '00:00:15'
    }
];

export default deafaultDaysToDisplay;