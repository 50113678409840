import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTitle from 'src/components/PageTitle';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';
import Loader from 'src/components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '760px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '60px',
    marginBottom: '60px'
  }
}));

const NewCategoryView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [startLoader, setStartLoader] = useState(false); 

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');

  const formik = useFormik({
    initialValues: {
      nome: ''
    }
  });

  const handleSave = () => {
    let values = formik.values;

    if(!values.nome) return openErrorAlert('Insira o nome da categoria.')
    setStartLoader(true);

    let obj = {
      "cat_nome": values.nome
    }

    api.post('/categoria', obj)
      .then((resp) => {
        setStartLoader(false);
        openSuccessAlert();
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Categoria criada com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    if(type !== 'error') navigate('/app/categorias');
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split('/')[1]}/categorias`;
    
    navigate(url);
  }

  return (
    <Page className={classes.root} title="Nova Categoria">
      <PageTitle title="NOVA CATEGORIA" />
      <Container className={classes.container}>
        <Box className={classes.fullBox}>
          <TextField
            className={classes.field}
            fullWidth
            label="Nome"
            margin="normal"
            name="nome"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.nome}
            variant="outlined"
            required
          />
        </Box>
      
        <Box className={classes.buttonBox}>
          <Button
            disableElevation
            size="large"
            variant="outlined"
            onClick={handleSave}
          >
            Salvar
          </Button>
          <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{marginLeft: 5, backgroundColor: '#F07C00', color: 'white'}}
              >
                Cancelar
              </Button>
        </Box>
      </Container>

      <Loader display={startLoader} />
      <AlertDialog open={openAlert} handleClose={() => handleAlertClose(alertType)} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default NewCategoryView;
