import Cloudly2 from "../assets/weather-cloud-2.webp";
import Sun from "../assets/weather-sun.webp";
import Rain from "../assets/weather-rain.webp";
import Storm from "../assets/weather-storm.webp";
import SunCloud from "../assets/weather-sun-cloud.webp";

const weatherImages = {
    cloudly_day: SunCloud,
    clear_day: Sun,
    clear_night: Sun,
    none_day: Sun,
    none_night: Sun,
    cloud: SunCloud,
    cloudly_night: SunCloud,
    fog: Cloudly2,
    rain: Storm,
    storm: Storm,
    snow: Rain,
    hail: Rain
}

export default weatherImages;