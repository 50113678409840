import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { apiUrl } from "src/services/api";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "20%",
    borderRadius: "0px 30px 0px 0px",
    height: "125px",
    // backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0",
    left: "0",
  },
}));
export default function PlaylistViewWattermark({ data }) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState("");

  const { lot_marcaDaguaPath, cli_marcaDaguaPath } = data;

  const handleImage = async () => {
    const url = lot_marcaDaguaPath
      ? `${apiUrl}/public${lot_marcaDaguaPath}`
      : cli_marcaDaguaPath
        ? `${apiUrl}/public${cli_marcaDaguaPath}`
        : "/static/images/logo_TVM.png";

    setImageUrl(url);
  };

  useEffect(() => {
    handleImage();
  });
  return (
    <Container className={classes.container}>
      <img
        alt="marca d'agua"
        src={imageUrl}
        width="100%"
        height="100px"
        style={{ borderRadius: 4, objectFit: "contain" }}
      />
    </Container>
  );
}
