
import React from "react";
import { ThemeProvider } from "@material-ui/core";
import theme from "src/theme";
import GlobalStyles from "src/components/GlobalStyles";
import { useRoutes } from "react-router-dom";
import routes from "src/routes";
import { Provider } from "react-redux";
import store from "src/store";

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </Provider>
  );
};

export default App;
