import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardActions,
  Container,
  FormControl,
  FormLabel,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Select,
  TextField,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MultimediaDropzone from "src/components/MultimediaDropzone";
import Page from "src/components/Page";
import Modal from "src/components/Modal";
import LotoCanva from "src/components/LotoCanva";
import AlertDialog from "src/components/AlertDialog";
import api, { apiUrl } from "src/services/api";
import PageTabTitle from "src/components/PageTabTitle";
import { Typography } from "@mui/material";
import Loader from "src/components/Loader";
import MidiaDaysModal from "src/components/MidiaDaysModal";
import deafaultDaysToDisplay from "src/utils/defaultDaysToDisplay";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSearchParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  hiddenImage: {
    display: 'none',
  },  
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  green: {
    color: "#009C6D",
  },
  container: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
    paddingBottom: 24,
    overflow: "auto",
  },
  nameBox: {
    width: "340px",
  },
  uploadBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "340px",
    height: "170px",
    border: "1px dashed #ccc",
  },
  select: {
    margin: theme.spacing(2),
    width: "340px",
  },
  button: {
    marginTop: theme.spacing(2),
    "& span": {
      fontSize: "12px",
      color: "#9E9E9E",
    },
  },
  buttonSave: {
    marginTop: theme.spacing(3),
  },
  divRadios: {
    display: "flex",
    justifyContent: "center",
  },
  radioGroup: {
    gap: theme.spacing(2),
  },
  template: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
    "& input": {
      width: 294,
    },
  },
  templateInput: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  templateMedia: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    alignItems: "center",
    flexDirection: "row",
  },
  card: {
    position: "relative",
  },
  cardMedia: {
    position: "relative",
    width: 287,
    height: 161,
  },
  templateCard: {
    display: "flex",
    flexDirection: "column",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  buttonMedia: {
    position: "absolute",
    width: "100%",
    margin: 0,
    bottom: 10,
    left: 2,
    "& button": {
      border: "1px solid #F07C00",
      fontSize: 12,
      fontWeigth: 500,
      borderRadius: 4,
      marginLeft: 8,
      width: "100%",
      height: 36,
      backgroundColor: "#fff",
    },
  },
  inputUpload: {
    display: "none",
  },
  playersToLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
    borderBottom: "1px rgba(119,136,153, 0.1) solid",
    lineHeight: "2rem",
    padding: "5px 20px",
  },
  timeBox: {
    width: "7vw",
    margin: theme.spacing(1, 1, 1, 0),
  },
  label: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: 8,
  },
  trashBtnContainer: {
    borderRadius: "50%",
    width: 50,
    position: "absolute",
    top: -30,
    right: -32,
    background: "#FFF",
    // border: "none",
    boxShadow: "0px 1px 29px -2px rgba(133, 128, 128, .5)",
    "-webkit-box-shadow": "0px 1px 29px -2px rgba(133, 128, 128, .5)",
    "-moz-box-shadow": "0px 1px 29px -2px rgba(133, 128, 128, .5)",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
}));

const NewMediaLibraryView = () => {
  const imgUrl = apiUrl;
  const classes = useStyles();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const me = useSelector((state) => state.auth.user);

  const [startLoader, setStartLoader] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [radioValue, setRadioValue] = useState(queryParameters.get("type"));
  const [categories, setCategories] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [name, setName] = useState("");
  const [loaded, setIsLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [videoDuration, setVideoDuration] = useState(null);
  const [templatePreview, setTemplatePreview] = useState(null);
  const [templateFile, setTemplateFile] = useState(null);
  const [contentSrc, setContentSrc] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [midiaOrder, setMidiaOrder] = useState("");
  const [lotocanvaId, setLotocanvaId] = useState(null);

  //[RND]
  const [midiaOrientacao, setMidiaOrientacao] = useState("paisagem");  

  const [activeTab, setactiveTab] = useState("primary");

  const [players, setPlayers] = useState([]);
  const [grids, setGrids] = useState([]);

  const [playersToLink, setPlayersToLink] = useState([]);
  const [gridsToLink, setGridsToLink] = useState([]);

  const [daysToDisplay, setDaysToDisplay] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "0",
  ]);
  const [midiaDaysToSave, setMidiaDaysToSave] = useState([]);
  const [openMidiaDaysModal, setOpenMidiaDaysModal] = useState(false);

  const fetchCategories = () => {
    api
      .get(`/categoria/all/ativos`)
      .then((resp) => {
        const { result } = resp.data;

        setCategories(result);
        // setSelectValue(result[0].cat_id)
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
        openFetchErrorAlert(err.response.data.message);
      });
  };

  const fetchTemplates = () => {
    api
      .get(`/template/all/ativos`)
      .then((resp) => {
        const { result } = resp.data;

        setTemplates(result);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
        openFetchErrorAlert(err.response.data.message);
      });
  };

  const fetchPlayers = async () => {
    await api
      .get("/player")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setPlayers(resp.data.result);
          //setRows(resp.data.result);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert();
      });
  };

  const fetchGrids = async () => {
    await api
      .get("/gradeprogramacao")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setGrids(resp.data.result);
          // setRows(resp.data.result);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDaysToDisplay = (e) => {
    const dayToAdd = e.target.value;

    const dayAlreadyAdd = daysToDisplay.find((day) => day === dayToAdd);
    if (dayAlreadyAdd) {
      setDaysToDisplay(daysToDisplay.filter((day) => day !== dayToAdd));
    } else {
      setDaysToDisplay([...daysToDisplay, dayToAdd]);
    }
  };

  const handleOpenMidiaDaysModal = () => {
    setOpenMidiaDaysModal(true);
  };

  const handleCloseMidiaDaysModal = () => {
    setOpenMidiaDaysModal(false);
  };

  const handleRemovePlayerToLink = (id) => {
    const players = playersToLink.filter((player) => player != id);

    setPlayersToLink(players);
  };

  const handleRemoveGridsToLink = (id) => {
    const grids = gridsToLink.filter((grid) => grid != id);

    setGridsToLink(grids);
  };

  const handleAddAllGrids = () => {
    const toLink = grids.map((grid) => grid.grp_id);
    setGridsToLink(toLink);
  };

  const handleAddAllPlayers = () => {    
    const toLink = players
    .filter(player => player.pla_posicao === midiaOrientacao) // Filtra apenas os players com a orientação desejada
    .map(player => {
      console.log(`Player: ${player.pla_nome} : Orientação: ${midiaOrientacao}`);
      return player.pla_lir_id; // Transforma o player no ID
    });
  
  setPlayersToLink(toLink);
  };

  const changeActiveTab = (tab) => {
    if (!name) return openErrorAlert("Insira o nome da mídia.");
    if (!selectedMedia) return openErrorAlert("Insira uma mídia.");

    setactiveTab(tab);
  };

  const handleRemoveTemplate = () => {
    setTemplateFile(null);
    setTemplatePreview(null);
  };

  const setMedia = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type.includes("image")) {
      setRadioValue("image");
    } else {
      setRadioValue("video");
    }
    setName(selectedFile.name.split(".")[0]);
    setSelectedMedia(selectedFile);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewMedia(reader.result);
    };

    reader.readAsDataURL(selectedFile);
  };

  const urlToFile = async (url, filename, mimeType) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };  
  // const setMedia2 = async (url, index, tmb) => {
  //   const file = await urlToFile(url, `image_${index}.jpg`, 'image/jpeg');
  //   console.log(file);    
  //   setRadioValue("image");  
  //   setSelectedMedia(file);
  //   setPreviewMedia(url);
  // };

  const setMedia2 = async (item, index, tmb) => {
    const file = await urlToFile(tmb, `image_${index}.jpg`, 'image/jpeg');
    console.log(file);    
    setRadioValue("image");  
    setSelectedMedia(file);
    setPreviewMedia(tmb);
    setLotocanvaId(item.id)
  };

  const removeMedia = () => {
    setVideoDuration(null);
    setSelectedMedia(null);
    setSelectValue("");
    setPreviewMedia(null);
    setRadioValue(null);
    setName("");
  };

  const postMedia = () => {
    if (!name) return openErrorAlert("Insira o nome da mídia.");
    if (!selectedMedia) return openErrorAlert("Insira uma mídia.");

    setStartLoader(true);

    let url = "/multimidia";
    let data = new FormData();
    let defaultDays = [];
    if (videoDuration) {
      deafaultDaysToDisplay.forEach((day) => {
        const dayToEdit = { ...day };

        dayToEdit.exc_duracao = videoDuration;

        defaultDays.push(dayToEdit);
      });
    }

    data.append("mul_nome", name);
    data.append("mul_tipo", radioValue === "image" ? "imagem" : radioValue);
    if (selectValue) data.append("mul_cat_id", selectValue);
    data.append("file", selectedMedia, selectedMedia.name);
    data.append("posicao_lista", Number(midiaOrder));
    data.append("grade_programacao", JSON.stringify(gridsToLink));
    data.append("lista_player", JSON.stringify(playersToLink));
    data.append("inicio", startTime ? startTime : null);
    data.append("fim", endTime ? endTime : null);
    data.append(
      "exc_exibicaoConteudo",
      JSON.stringify(
        midiaDaysToSave.length > 0
          ? midiaDaysToSave
          : midiaDaysToSave.length === 0 && videoDuration
          ? defaultDays
          : deafaultDaysToDisplay
      )
    );
    data.append("mul_video_duration", videoDuration);
    //[RND]
    if(lotocanvaId != null){data.append("lotocanva_id", lotocanvaId);}
    
console.log(`[RND] - Dados do Formulário: ${data}`);
for (let [key, value] of data.entries()) {
  console.log(`${key}: ${value}`);
}
console.log(`[RND] - Dados do Formulário: ${data}`);
    api
      .post(url, data)
      .then((resp) => {
        openSuccessAlert();
        setStartLoader(false);
        //navigate('/app/biblioteca')
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Cadastro realizado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    if (type !== "error") navigate("/app/biblioteca");
  };

  const editTemplate = async (path, obj) => {
    if (name !== "") {
      setStartLoader(true);
      await api
        .get(`/multimidia/file/${path}`, { responseType: "blob" })
        .then((resp) => {
          const objectUrl = URL.createObjectURL(resp.data);

          setStartLoader(false);
          setOpenModal(true);
          setContentSrc(objectUrl);
        })
        .catch((err) => {
          setStartLoader(false);
          console.log(err);
        });
    } else {
      openErrorAlert("Preencha os campos antes de editar.");
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    URL.revokeObjectURL(contentSrc);
    setContentSrc("");
  };

  const handleTemplate = (file, base64) => {
    console.log(file);
    setTemplatePreview(base64);
    setTemplateFile(file);
    onCloseModal();
  };

  const saveTemplate = (file) => {
    if (!file) return openErrorAlert("Adicione um template");
    if (!name) return openErrorAlert("Insira o nome da mídia");

    setStartLoader(true);
    let data = new FormData();

    data.append("mul_nome", name);
    data.append("mul_tipo", "imagem");
    console.log("selectValue", selectValue);
    if (selectValue !== "") data.append("mul_cat_id", selectValue);
    data.append("file", file, file.name);

    api
      .post("/multimidia", data)
      .then((resp) => {
        setStartLoader(false);
        onCloseModal();
        openSuccessAlert();
      })
      .catch((err) => {
        console.log(err);
        setStartLoader(false);
        openErrorAlert(err.response.data.message);
      });
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split("/")[1]}/biblioteca`;

    navigate(url);
  };

  const handleActiveTab = () => {
    if (!name) return openErrorAlert("Insira o nome da mídia.");
    if (!selectedMedia) return openErrorAlert("Insira uma mídia.");

    changeActiveTab("secondary");
  };

  useEffect(() => {
    if (!loaded) {
      fetchCategories();
      fetchTemplates();
      fetchPlayers();
      fetchGrids();
    }
  }, [loaded]);
  console.log("rad", radioValue);

  //RND
  // Filtra os players com base na orientação
  const filteredPlayers = filterPlayersByOrientation(players, midiaOrientacao);

  return (
    <Page className={classes.root} title="Nova Biblioteca">
      <PageTabTitle
        className={classes.title}
        primary="NOVA MÍDIA"
        secondary="REPRODUÇÃO"
        active={activeTab}
        changeTab={changeActiveTab}
      />
      <Container className={classes.container}>
        {activeTab === "primary" ? (
          <>
            {radioValue !== "template" ? (
              <>
                <LotoCanva 
                style={{ display: "none" }}
                className={classes.hiddenImage}
                setMedia2={setMedia2}
                setStartLoader={setStartLoader}
                />
                <div style={{ marginTop: 44 }}>
                  <MultimediaDropzone
                    value={radioValue}
                    setMedia={setMedia}
                    removeMedia={removeMedia}
                    acceptField="image/*, video/*"
                    preview={previewMedia}
                    buttons
                    setName={setName}
                    videoDuration={videoDuration}
                    setVideoDuration={setVideoDuration}
                    size="normal"
                  />
                </div>
                <FormControl
                  className={classes.divRadios}
                  component="fieldset"
                  style={{
                    margin: "12px 0",
                    display:
                      radioValue === "image" || radioValue === "video"
                        ? "block"
                        : "none",
                  }}
                >
                  <RadioGroup
                    className={classes.radioGroup}
                    row
                    aria-label="midia"
                    name="midia"
                    value={radioValue}
                    onChange={(event) => setRadioValue(event.target.value)}
                  >
                    <FormControlLabel
                      value="image"
                      control={<Radio color="primary" />}
                      label="Imagem"
                      disabled
                    />
                    <FormControlLabel
                      value="video"
                      control={<Radio color="primary" />}
                      label="Vídeo"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  className={classes.nameBox}
                  label="Nome"
                  margin="normal"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  value={name}
                  variant="outlined"
                  required
                />

                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    className={classes.select}
                    native
                    value={selectValue}
                    onChange={(event) => setSelectValue(event.target.value)}
                  >
                    <option value="" disabled>
                      Categorias
                    </option>
                    {categories.map((cat) => (
                      <option key={cat.cat_id} value={cat.cat_id}>
                        {cat.cat_nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <div
                  style={{
                    position: "relative",
                    marginTop: 44,
                    display: templatePreview ? "block" : "none",
                  }}
                >
                  <img
                    style={{
                      width: "23rem",
                      height: "12rem",
                    }}
                    src={templatePreview}
                  />
                  <Button
                    disableElevation
                    size="large"
                    className={classes.trashBtnContainer}
                    onClick={handleRemoveTemplate}
                  >
                    <DeleteIcon style={{ color: "#5E5E5E", fontSize: 40 }} />
                  </Button>
                </div>
                <FormControl
                  className={classes.divRadios}
                  component="fieldset"
                  style={{ margin: "12px 0" }}
                >
                  <RadioGroup
                    className={classes.radioGroup}
                    row
                    aria-label="midia"
                    name="midia"
                    value={radioValue}
                    onChange={(event) => setRadioValue(event.target.value)}
                  >
                    <FormControlLabel
                      value="template"
                      control={<Radio color="primary" />}
                      label="Template"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  className={classes.nameBox}
                  label="Nome"
                  margin="normal"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  value={name}
                  variant="outlined"
                  required
                />
                <div className={classes.template}>
                  <div className={classes.templateInput}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        className={classes.select}
                        native
                        value={selectValue}
                        onChange={(event) => setSelectValue(event.target.value)}
                      >
                        <option value="">Categoria</option>
                        {categories.map((cat) => (
                          <option key={cat.cat_id} value={cat.cat_id}>
                            {cat.cat_nome}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes.templateMedia}>
                    {templates.map((el) => (
                      <Card className={classes.card} key={el.tem_id}>
                        <CardMedia
                          className={classes.cardMedia}
                          image={imgUrl + el.tem_thumb}
                        />
                        <CardActions className={classes.buttonMedia}>
                          <Box className={classes.buttonBox}>
                            <Button
                              size="large"
                              variant="outlined"
                              onClick={() =>
                                editTemplate(el.tem_path, el.tem_thumb)
                              }
                            >
                              EDITAR
                            </Button>
                          </Box>
                        </CardActions>
                      </Card>
                    ))}
                  </div>
                  <Box className={classes.buttonSave}>
                    <Button
                      disableElevation
                      size="large"
                      variant="outlined"
                      onClick={() => saveTemplate(templateFile)}
                      style={{ marginRight: 5 }}
                    >
                      SALVAR
                    </Button>
                  </Box>
                </div>
              </>
            )}

            {radioValue !== "template" && (
              <Box className={classes.buttonSave}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{ marginRight: 5 }}
                >
                  VOLTAR
                </Button>
                {me.usr_tipoUsuario !== "caixa" ? (
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={handleActiveTab}
                  >
                    CONTINUAR
                  </Button>
                ) : null}
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={postMedia}
                  style={{
                    marginLeft: 5,
                    backgroundColor: "#F07C00",
                    color: "white",
                  }}
                >
                  Salvar
                </Button>
              </Box>
            )}
          </>
        ) : (
          <>
            <Container>
              <div style={{ marginLeft: 40 }}>
                <Box>
                  <FormControl style={{ width: 490 }}>
                    <Typography variant="h4">Período :</Typography>
                    <div style={{ display: "flex", marginBottom: 15 }}>
                      <TextField
                        label="Inicio"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        type="date"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setStartTime(e.target.value)}
                        style={{ width: 150, marginRight: 15 }}
                      />
                      <TextField
                        label="Término"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        type="date"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setEndTime(e.target.value)}
                        style={{ width: 150 }}
                      />
                    </div>
                  </FormControl>
                  <div>
                    <Button
                      variant="contained"
                      size="large"
                      style={{ backgroundColor: "#F07C00", color: "white" }}
                      onClick={handleOpenMidiaDaysModal}
                    >
                      Exibição Semanal
                    </Button>
                  </div>
                </Box>
                {/*[RND]*/}
                <FormControl className={classes.divRadios} style={{ display: "inline-flex", padding: "10px" }}>
                  <Typography variant="h6" style={{ margin: "10px 0 10px" }}>
                    Ordem de exibição na lista de reprodução
                  </Typography>
                  <RadioGroup
                    value={midiaOrder}
                    row
                    name="posicao lista de reprodução"
                    onChange={(e) => setMidiaOrder(e.target.value)}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Adicionar no inicio"
                      value={"0"}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Adicionar no fim"
                      value={"1"}
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl className={classes.divRadios} style={{ display: "inline-flex", padding: "10px" }}>
                  <Typography variant="h6" style={{ margin: "10px 0 10px" }}>
                    Orientação
                  </Typography>
                  <RadioGroup
                    value={midiaOrientacao}
                    row
                    name="orientacao"
                    onChange={(e) => {setMidiaOrientacao(e.target.value);}}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Telas Verticais"
                      value={"retrato"}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Telas Horizontais"
                      value={"paisagem"}
                    />
                  </RadioGroup>
                </FormControl>   
                {/*[RND]*/}   
              </div>
              <div
                style={{
                  padding: 15,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Container>
                  <Box style={{ display: "flex" }}>
                    <FormControl
                      fullWidth
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Select
                        value={0}
                        onChange={(e) => {
                          const alreadyAdd = playersToLink.find(
                            (el) => el === e.target.value
                          );
                          if (alreadyAdd) return null;

                          setPlayersToLink([...playersToLink, e.target.value]);
                        }}
                        variant="outlined"
                      >
                        <MenuItem value={0}>Selecione o player*</MenuItem>
                        {filteredPlayers.map((player) => (
                          <MenuItem
                            key={player.pla_lir_id}
                            value={player.pla_lir_id}
                          >
                            {player.pla_nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      style={{
                        width: 150,
                        marginLeft: 10,
                        height: 55,
                        marginRight: 0,
                      }}
                      variant="outlined"
                      onClick={handleAddAllPlayers}
                    >
                      Add Todas
                    </Button>
                  </Box>
                  {playersToLink.map((player) => {
                    const playerSelected = players.filter(
                      (playerToCompare) =>
                        playerToCompare.pla_lir_id == player &&
                        playerToCompare.pla_nome
                    );
                    return (
                      <Typography
                        key={player}
                        className={classes.playersToLink}
                      >
                        {playerSelected[0]?.pla_nome}
                        <IconButton
                          onClick={() =>
                            handleRemovePlayerToLink(
                              playerSelected[0].pla_lir_id
                            )
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Typography>
                    );
                  })}
                </Container>

                <Container>
                  <Box style={{ display: "flex" }}>
                    <FormControl
                      style={{
                        marginBottom: 20,
                      }}
                      fullWidth
                    >
                      <Select
                        value={0}
                        onChange={(e) => {
                          const alreadyAdd = gridsToLink.find(
                            (el) => el === e.target.value
                          );
                          if (alreadyAdd) return null;

                          setGridsToLink([...gridsToLink, e.target.value]);
                        }}
                        variant="outlined"
                      >
                        <MenuItem value={0}>Selecione a grade*</MenuItem>
                        {grids.map((grid) => (
                          <MenuItem key={grid.grp_id} value={grid.grp_id}>
                            {grid.grp_nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      style={{
                        width: 150,
                        marginLeft: 10,
                        height: 55,
                        marginRight: 0,
                      }}
                      variant="outlined"
                      onClick={handleAddAllGrids}
                    >
                      Add Todas
                    </Button>
                  </Box>

                  {gridsToLink.map((grid) => {
                    const gridSelected = grids.filter((gridToCompare) =>
                      gridToCompare.grp_id == grid
                        ? gridToCompare.grp_nome
                        : null
                    );
                    return (
                      <Typography key={grid} className={classes.playersToLink}>
                        {gridSelected[0].grp_nome}
                        <IconButton
                          onClick={() =>
                            handleRemoveGridsToLink(gridSelected[0].grp_id)
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Typography>
                    );
                  })}
                </Container>
              </div>
            </Container>
            <Box className={classes.buttonSave}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={postMedia}
              >
                SALVAR
              </Button>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{
                  marginLeft: 5,
                  backgroundColor: "#F07C00",
                  color: "white",
                }}
              >
                VOLTAR
              </Button>
            </Box>
          </>
        )}
      </Container>

      <MidiaDaysModal
        open={openMidiaDaysModal}
        close={handleCloseMidiaDaysModal}
        handleDaysToDisplay={handleDaysToDisplay}
        daysToDisplay={daysToDisplay}
        save={setMidiaDaysToSave}
        videoDuration={videoDuration}
      />

      <Loader display={startLoader} />
      <Modal
        type="edit-template"
        open={openModal}
        content={contentSrc}
        close={onCloseModal}
        saveTemplate={handleTemplate}
      />
      <AlertDialog
        open={openAlert}
        handleClose={() => handleAlertClose(alertType)}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

//RND
const filterPlayersByOrientation = (players, midiaOrientacao) => {
  return players.filter((player) => player.pla_posicao === midiaOrientacao);
};

export default NewMediaLibraryView;
