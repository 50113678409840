import React from 'react';
import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: 'flex',
  },
  primaryBox: {
    width: '20vw',
    height: '48px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  secondaryBox: {
    minWidth: '28vw',
    height: '48px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  tertiaryBox: {
    minWidth: '14vw',
    height: '48px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  activeTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: '#FFFFFF',
    paddingRight: '16px'
  },
  inactiveTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: '#333333',
    paddingRight: '16px'
  },
  active: {
    backgroundColor: '#F07C00'
  },
  inactive: {
    backgroundColor: '#C4C4C4'
  }
}));

const PageTabTripleTitle = ({ primary, secondary, tertiary, active, changeTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleBox}>
      <Box className={`${classes.primaryBox} ${active === "primary" ? classes.active : classes.inactive}`} onClick={() => changeTab('primary')}>
        <Typography className={active === "primary" ? classes.activeTitle : classes.inactiveTitle}>{primary}</Typography>
      </Box>
      
      <Box className={`${classes.secondaryBox} ${active === "secondary" ? classes.active : classes.inactive}`} onClick={() => changeTab('secondary')}>
        <Typography className={active === "secondary" ? classes.activeTitle : classes.inactiveTitle}>{secondary}</Typography>
      </Box>

      <Box className={`${classes.tertiaryBox} ${active === "tertiary" ? classes.active : classes.inactive}`} onClick={() => changeTab('tertiary')}>
        <Typography className={active === "tertiary" ? classes.activeTitle : classes.inactiveTitle}>{tertiary}</Typography>
      </Box>
    </div>
  );
};

export default PageTabTripleTitle;