import { defineState } from 'redux-localstore';

const defaultState = {
  reportType: '',
  reportData: {}
};

const initialState = defineState(defaultState)('report');

const report = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        reportType: action.payload.type,
        reportData: action.payload.data
      };
    case 'RESET':
      return {
        isAuthenticated: '',
        user: {}
      };
    default:
      return state;
  }
};

export default report;
