import axios from "axios";

const apiUrls = {
  local: "http://localhost:3000",
  develop: "https://teste-api.tvmx.com.br",
  stage: "https://api-tvm-hom.techlise.com.br",
  prod: "https://api.tvmx.com.br",
};

export const apiUrl = apiUrls["prod"];

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem("@storage_Key");
  if (token) {
    config.headers.common["x-access-token"] = token;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const oneSecond = 1000;
    if (error?.response?.data?.error == 401) {
      localStorage.clear();
      setTimeout(() => (window.location.pathname = "/login"), oneSecond);
    }
    if (error?.response?.status == 406) {
      localStorage.clear();
      setTimeout(() => (window.location.pathname = "/login"), oneSecond);
    }
    return Promise.reject(error);
  }
);
export default api;
