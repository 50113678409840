import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  credits: {
    color: "#FFFFFF",
    position: "absolute",
    right: "45px",
    top: "15px",
  },
  baseBox: {
    backgroundImage: `url('/static/images/weather-bg.png')`,
    width: "100%",
    height: "64vh",
    display: "flex",
  },
  cityBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "100px",
    color: "#FFFFFF",
  },
  forecastBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "32px",
    marginRight: 32
  },
  dataForecastBox: {
    display: "flex",
    alignItems: "center",
  },
  lastDataForecastBox: {
    display: "flex",
    alignItems: "center",
  },
  forecastImage: {
    width: "25px",
    margin: "10px 10px 10px 0",
  },
  weatherImage: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  cityData: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: "#FFFFFF",
  },
  cityTemperature: {
    fontSize: "60px",
  },
  cityTime: {
    fontSize: "50px",
  },
}));

const WeatherCard = (props) => {
  const classes = useStyles();

  const WeatherImage = (props) => {
    const { condition } = props;
    let component;

    switch (condition) {
      case "clear_day":
        component = (
          <img
            alt="imagem representando um dia limpo"
            src="/static/images/weather-sun.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "clear_night":
        component = (
          <img
            alt="imagem representando um dia de sol"
            src="/static/images/weather-sun.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "none_day":
        component = (
          <img
            alt="imagem representando o clima do dia"
            src="/static/images/weather-sun.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "none_night":
        component = (
          <img
            alt="imagem representando o clima da noite"
            src="/static/images/weather-sun.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "cloud":
        component = (
          <img
            alt="imagem representando um dia com muitas nuvens no céu"
            src="/static/images/weather-sun-cloud.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "cloudly_day":
        component = (
          <img
            alt="imagem representando um dia com muitas nuvens no céu"
            src="/static/images/weather-sun-cloud.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "cloudly_night":
        component = (
          <img
            alt="imagem representando uma noite com muitas nuvens no céu"
            src="/static/images/weather-sun-cloud.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "fog":
        component = (
          <img
            alt="imagem representando um dia nublado"
            src="/static/images/weather-cloud-2.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "rain":
        component = (
          <img
            alt="imagem representando o clima em um dia de chuva"
            src="/static/images/weather-storm.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "storm":
        component = (
          <img
            alt="imagem representando o clima em um dia de tempestade"
            src="/static/images/weather-storm.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "snow":
        component = (
          <img
            
            alt="imagem representando o clima em um dia que esta nevando"
            src="/static/images/weather-rain.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      case "hail":
        component = (
          <img
            alt="imagem representando o clima em um dia de chuva"
            src="/static/images/weather-rain.png"
            width="100%"
            style={{ marginLeft: "-35px" }}
          />
        );
        break;
      default:
        component = null;
        break;
    }

    return component;
  };

  return (
    <Box className={classes.baseBox}>
      <Typography variant="h5" className={classes.credits}>
        Fonte: Clima tempo
      </Typography>

      <Box className={classes.cityBox}>
        <Box>
          <Typography variant="h1">{props.weather.cli_cidade}</Typography>
          <Typography variant="h5">
            {props.weather.cli_weekday} | {props.weather.cli_date}
          </Typography>
        </Box>

        <div style={{display: "flex"}}>
          {props.weather?.cli_forecast?.map((el, index) => (
            <Box className={classes.forecastBox} key={index}>
              <Typography variant="h5">
                {el.date} | {el.weekday}
              </Typography>

              <Box className={classes.dataForecastBox}>
                <img
                  alt="imagem com um icone de temperatura máxima do dia"
                  className={classes.forecastImage}
                  src="/static/images/weather-max.png"
                />
                <Typography variant="h5">{el.max}ºC</Typography>
              </Box>

              <Box className={classes.lastDataForecastBox}>
                <img
                  alt="imagem com um icone de temperatura mínima do dia"
                  className={classes.forecastImage}
                  src="/static/images/weather-min.png"
                />
                <Typography variant="h5">{el.min}ºC</Typography>
              </Box>
            </Box>
          ))}
        </div>
      </Box>

      <Box className={classes.weatherImage}>
        <WeatherImage condition={props.weather.cli_condition} />
      </Box>

      <Box className={classes.cityData}>
        <Typography variant="h1" className={classes.cityTemperature}>
          {props.weather.cli_max}ºC
        </Typography>
        <Typography variant="h1" className={classes.cityTime}>
          {props.weather.cli_time}
        </Typography>
      </Box>
    </Box>
  );
};

export default WeatherCard;
