import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import plugIconRed from 'src/assets/plugIconRed.svg';
import plugIconGreen from 'src/assets/plugIconGreen.svg';
import moment from 'moment';
import Page from 'src/components/Page';
import PageTitle from 'src/components/PageTitle';
import CardList from 'src/components/CardList';
import Skeleton from '@mui/material/Skeleton';
import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import api, { apiUrl } from 'src/services/api';
import { getToken } from "src/services/auth";
import { io } from "socket.io-client";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgfloorColor: '#F5F5F5',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    borderBottom: 'none'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: "75vh",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },

  },
  rightSide: {
    width: '50%',
    height: "75vh",
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    '& 	.MuiSkeleton-root': {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflow: "normal",
    }
  },
  disconected: {
    height: "70vh",
    width: "100%",
    paddingTop: '15px',
    paddingRight: '1px'
  },
  disconnectedFull: {
    maxHeight: "80vh",
    width: "100%",
    paddingTop: '15px',
    paddingRight: '1px'
  },
  labelChart: {
    fontSize: "2rem",
  },
  totalStorage: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    
  },
  lastStorage: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  },
  leftSide: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  icon: {
    width: '15px',
    color: '#E6202A'

  },
  chart: {
    position: 'relative',
    marginTop: theme.spacing(3),
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  styledTitle:{
    display: 'flex',
    fontSize: 28,
    fontWeight: 600,
    color: '#FFFFFF',
    paddingLeft: 20
  },
  styledTitleBox: {
    minWidth: '336px',
    height: '48px',
    whiteSpace: "nowrap",
    paddingLeft: 20,
    backgroundColor: '#F07C00',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
    height: "100%",
  }
}));

const DashboardView = () => {
  const classes = useStyles();
  const me = useSelector((state) => state.auth.user);
  const windowHight = window.innerHeight;

  let limit = Math.floor(((64.4 / 100) * windowHight) / 100);

  useEffect(() => {
    console.log(windowHight)
  }, [windowHight]);

  const [players, setPlayers] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [media, setMedia] = useState([]);
  const [player, setPlayer] = useState({playerId: null, status: null});

  const orderedPlayers = (resp) => {
    return resp.sort((a, b) => {
      const dateA = new Date(a.pla_ultimaSincronizacao);
      const dateB = new Date(b.pla_ultimaSincronizacao);
      
      return dateB - dateA;
    });
  }

  const getDisconnected = () => {
    api.get('/dashboard/all/conexao')
    .then((resp) => {
      setIsLoaded(true);
      if (resp.data.status && resp.data.status === 200) {
        setPlayers(resp.data.result.players ? orderedPlayers(resp.data.result.players) : []);
        getMedia();
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getPlayerId = (player) => {
    players.map((element) => {
      return element.pla_id === player.playerId ? element.conexao = (player.status === "playerDisconnected" ? "Desativado" : "Ativo") : null
    })
  }

  const AtualizarUltimaVisualizacao = () => {
    var pl = []
    players.forEach((element) => {
      var data = moment()
      if (element.conexao === 'Ativo' && Math.floor(((data.diff(element?.pla_ultimaSincronizacao)/1000))/60) > 0) {
        element.pla_ultimaSincronizacao = moment(element?.pla_ultimaSincronizacao).add(Math.floor(((data.diff(element?.pla_ultimaSincronizacao)/1000))), 'seconds')
      }
      pl.push(element)
    });
    setPlayers(orderedPlayers(pl));
  }
  
  const StartSocket = async () => {
    const token = await getToken();
    if (token) {
      const socket = io(apiUrl, {
        transports: ["websocket"],
        extraHeaders: {
          "x-access-token": token,
        },
        auth: {
          token,
        },
      });

      socket.on("playerConnected", (player) => {
        setPlayer({playerId: player, status: "playerConnected"})
      });

      socket.on("playerDisconnected", (player) =>{
        setPlayer({playerId: player, status: "playerDisconnected"})
        }
      );

      socket.on("playerFoiBloqueado", (player) => {
      });

      socket.on("connect", () => {
      });

      socket.on("disconnect", (reason) => {
      });
    }
  };

  const getMedia = () => {
    api.get('/dashboard/multimidia/tvm')
    .then((resp) => {
      setIsLoaded(true);
      if (resp.data.status && resp.data.status === 200) {
        setMedia(resp.data.result);

        // if (me.usr_tipoUsuario === 'admin') {
        //   getStorage();
        // }
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  // const getStorage = () => {
  //   api.get('/dashboard/storage')
  //   .then((resp) => {
  //     if (resp.data.status && resp.data.status === 200) {
  //       let usedPercentage = (resp.data.result.used * 100) / resp.data.result.total;
  //       let freePercentage = (resp.data.result.free * 100) / resp.data.result.total;

  //       let usage = [
  //         { name: "Espaço Livre", value: parseInt(freePercentage.toFixed(0)) },
  //         { name: "Armazenamento Usado", value: parseInt(usedPercentage.toFixed(0)) }
  //       ]
  //       console.log(usage, resp.data)
  //       setUsageData(usage);
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   })
  // }

  useEffect(() => {
    if (!isLoaded) {
      getDisconnected();
      StartSocket();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (player.playerId && player.status) {
      getPlayerId(player);
      setPlayer({player: null, status: "null"})
    }
  }, [player]);

  useEffect(() => {
    if (players.length) {
      const timerId = setInterval(AtualizarUltimaVisualizacao, 1000); 
      return () => { 
        clearInterval(timerId); 
      };
    }
  }, [players.length]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container className={classes.container}>
        <Box className={classes.leftSide}>
          <PageTitle title="PLAYERS" />
          <TableContainer className={me.usr_tipoUsuario === "admin" ? classes.disconected : classes.disconnectedFull}>
            {isLoaded ? <Table className={classes.table} size="small" aria-label="Tabela de clientes">
              <TableHead>
                <TableRow>
                  <StyledTableCell>LOTÉRICA</StyledTableCell>
                  <StyledTableCell>NOME</StyledTableCell>
                  <StyledTableCell>ULTIMA SINC.</StyledTableCell>
                  <StyledTableCell>CONEXÃO</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.map((player) => (
                  <TableRow key={player.pla_id}>
                    <StyledTableCell>{player.loterica.lot_razaoSocial}</StyledTableCell>
                    <StyledTableCell>{player.pla_nome}</StyledTableCell>
                    <StyledTableCell>{player.pla_ultimaSincronizacao ? moment(player.pla_ultimaSincronizacao).format('DD/MM/YY HH:mm') : '-'}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={() => { }}>
                        {
                          player.conexao === 'Desativado' ? 
                            <img src={plugIconRed} alt="logo desconected" className={classes.icon} />  
                            :
                            <img src={plugIconGreen} alt="logo connected" className={classes.icon} />  

                        }
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table> : 
              <>
                <Skeleton height={50}/>
                <Skeleton variant="rectangular" height={"calc(100% - 150px)"}/>
              </>
            }
          </TableContainer>
        </Box>

        <Box className={classes.rightSide}>
          <PageTitle title="ÚLTIMAS MIDIAS" className={classes}/>
          {
            isLoaded ? (
              <Box className={classes.lastStorage}>
                <CardList obj={media} flippable={false} limit={limit} />
                {
                  media?.length === 0 ? (
                    <Typography variant="h3">Nenhuma mídia encontrada.</Typography>
                  ) : null
                }
              </Box>
            ) : (
              <div className={classes.skeletonContainer}>
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
                <Skeleton width={287} variant="rectangular" height={161} />
              </div>
            )
          }
        </Box>
      </Container>
    </Page>
  );
};

export default DashboardView;
