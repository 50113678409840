import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import StorefrontIcon from '@material-ui/icons/Storefront';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MoreIcon from '@material-ui/icons/More';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useNavigate } from 'react-router-dom';
import UserCard from 'src/components/UserCard';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: 328,
    padding: '16px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  expandList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
}));

const DrawerMenu = ({ open, toggle }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const me = useSelector((state) => state.auth.user);
  const [menuList, setMenuList] = useState([]);

  const handleClick = (route) => {
    navigate('/app/' + route);
  }

  const signOut = () => {
    navigate('/login', { replace: true });
  }

  const returnIcon = (icon) => {
    let returnedIcon;

    switch (icon) {
      case 'DashboardIcon':
        returnedIcon = <DashboardIcon />;
        break;
      case 'GroupIcon':
        returnedIcon = <GroupIcon />;
        break;
      case 'StorefrontIcon':
        returnedIcon = <StorefrontIcon />;
        break;
      case 'OndemandVideoIcon':
        returnedIcon = <OndemandVideoIcon />;
        break;
      case 'MoreIcon':
        returnedIcon = <MoreIcon />;
        break;
      case 'VideoLibraryIcon':
        returnedIcon = <VideoLibraryIcon />;
        break;
      case 'QueuePlayNextIcon':
        returnedIcon = <QueuePlayNextIcon />;
        break;
      case 'ViewCompactIcon':
        returnedIcon = <ViewCompactIcon />;
        break;
      case 'PersonIcon':
        returnedIcon = <PersonIcon />;
        break;
      case 'DescriptionIcon':
        returnedIcon = <DescriptionIcon />;
        break;
      default:
        returnedIcon = <MailIcon />;
        break;
    }

    return returnedIcon;
  }

  useEffect(() => {
    let menu = [];

    console.log("ME: ", me);

    if (me) {
      if (me.usr_tipoUsuario === 'admin') {
        menu = [
          { icon: 'DashboardIcon', name: "Dashboard", path: 'dashboard' },
          { icon: 'PersonIcon', name: "Usuários", path: 'usuarios' },
          { icon: 'GroupIcon', name: "Clientes", path: 'clientes' },
          { icon: 'StorefrontIcon', name: "Lotéricas", path: 'lotericas' },
          { icon: 'OndemandVideoIcon', name: "Players", path: 'players' },
          { icon: 'MoreIcon', name: "Categorias", path: 'categorias' },
          { icon: 'VideoLibraryIcon', name: "Biblioteca de Mídia", path: 'biblioteca' },
          { icon: 'QueuePlayNextIcon', name: "Grade", path: 'grades' },
          { icon: 'ViewCompactIcon', name: "Template", path: 'templates' },
          // { icon: '', name: "Marca d'água", path: 'marca-dagua' },
          { icon: 'DescriptionIcon', name: "Relatórios", path: 'relatorios' },
        ];

        setMenuList(menu);
      } else if (me.usr_tipoUsuario === 'cliente') {
        menu = [
          { icon: 'DashboardIcon', name: "Dashboard", path: 'dashboard' },
          { icon: 'PersonIcon', name: "Usuários", path: 'usuarios' },
          { icon: 'GroupIcon', name: "Editar Cliente", path: 'editar-cliente' },
          { icon: 'StorefrontIcon', name: "Lotéricas", path: 'lotericas' },
          { icon: 'OndemandVideoIcon', name: "Players", path: 'players' },
          { icon: 'MoreIcon', name: "Categorias", path: 'categorias' },
          { icon: 'VideoLibraryIcon', name: "Biblioteca de Mídia", path: 'biblioteca' },
          { icon: 'QueuePlayNextIcon', name: "Grade", path: 'grades' },
          { icon: 'DescriptionIcon', name: "Relatórios", path: 'relatorios' },
        ];

        setMenuList(menu);
      } else {
        menu = [
          { icon: 'DashboardIcon', name: "Dashboard", path: 'dashboard' },
          { icon: 'PersonIcon', name: "Usuários", path: 'usuarios' },
          { icon: 'VideoLibraryIcon', name: "Biblioteca de Mídia", path: 'biblioteca' },
          { icon: 'QueuePlayNextIcon', name: "Grade", path: 'grades' },
          { icon: 'DescriptionIcon', name: "Relatórios", path: 'relatorios' },
        ];

        setMenuList(menu);
      }
    }
  }, [me]);

  const MenuList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggle(false)}
      onKeyDown={() => toggle(false)}
    >
      <UserCard />
      <Divider />
      <List style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div className={classes.expandList}>
          {menuList.map((item, index) => (
            <ListItem button key={index} onClick={() => handleClick(item.path)}>
              <ListItemIcon style={{ minWidth: '40px' }}>
                {returnIcon(item.icon)}
              </ListItemIcon>
              <ListItemText primary={item.name.toUpperCase()} />
            </ListItem>
          ))}
        </div>
        <div>
          <ListItem button key="leave" onClick={signOut}>
            <ListItemIcon style={{ minWidth: '40px' }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="SAIR" />
          </ListItem>
        </div>
      </List>
    </div>
  );


  return (
    <React.Fragment key="top">
      <Drawer
        anchor="left"
        onClose={() => toggle(false)}
        open={open}
      >
        <MenuList />
      </Drawer>
    </React.Fragment>
  );
}

export default DrawerMenu;