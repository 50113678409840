import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  makeStyles,
  TextField,
  Select,
} from "@material-ui/core";
import { debounce } from "../../services/debounce";
import Page from "src/components/Page";
import PageTabTitle from "src/components/PageTabTitle";
import AlertDialog from "src/components/AlertDialog";
import MediaCard from "src/components/MediaCard";
import EditModal from "src/components/EditModal";
import api from "src/services/api";
import MidiaDaysModal from "src/components/MidiaDaysModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
    maxWidth: "100vw",
  },
  searchBox: {
    width: "326px",
  },
  input: {
    width: "340px",
  },
  buttonBox: {
    marginTop: "80px",
    marginBottom: "40px",
  },
  headerButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      width: 250,
      marginLeft: theme.spacing(3),
    },
  },
  green: {
    color: "#009C6D",
  },
  mediaCardList: {
    width: "100%",
    maxWidth: "1400px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: theme.spacing(10),
    gap: theme.spacing(2),
    color: "textPrimary",
  },
  select: {
    margin: theme.spacing(2),
    width: "340px",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  fadeIn: {
    animation: "$myEffect 1s ease",
  },
  placeholder: {
    position: "absolute",
    background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
}));

const NewGrid = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const timeoutRef = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [name, setName] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [fadeProps, setFadeProps] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setactiveTab] = useState("primary");
  const [type, setType] = useState("");
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [mediaGrid, setMediaGrid] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");

  const [daysToDisplay, setDaysToDisplay] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "0",
  ]);
  const [midiaDaysToSave, setMidiaDaysToSave] = useState([]);
  const [openMidiaDaysModal, setOpenMidiaDaysModal] = useState(false);
  const [dragNDropStartIndex, setDragNDropStartIndex] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragStart = (event, startIndex) => {
    setDragNDropStartIndex(startIndex);

    event.dataTransfer.setData("text/plain", event.target.id);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    const mediaToRelocateId = event.target.id;

    if (!mediaToRelocateId) return console.log("ID Ñ ENCONTRADO.");

    const mediaToRelocate = selectedMedia.filter((media) => {
      return (
        media.con_id == Number(mediaToRelocateId) ||
        media.mul_id == Number(mediaToRelocateId) ||
        media.not_id == Number(mediaToRelocateId) ||
        media.cli_id == Number(mediaToRelocateId)
      );
    });

    if (mediaToRelocate.length > 1) return console.log("ID DUPLICADO.");
    const mediaToRelocateIndex = selectedMedia.indexOf(mediaToRelocate[0]);

    const reordered = reorder(
      selectedMedia,
      dragNDropStartIndex,
      mediaToRelocateIndex
    );

    setDragNDropStartIndex(null);
    setSelectedMedia(reordered);

    event.dataTransfer.clearData();
  };

  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      selectedMedia,
      result.source.index,
      result.destination.index
    );

    setSelectedMedia(items);
    setFadeProps(classes.fadeIn);
  };

  const changeActiveTab = (tab) => {
    if (!name) return openErrorAlert("Insira o nome da grade.");

    setactiveTab(tab);
  };

  const handleMediaGrid = () => {
    api
      .get("/multimidia/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setMediaGrid(resp.data.result.items);
          setType("media-grid");
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDaysToDisplay = (e) => {
    const dayToAdd = e.target.value;

    const dayAlreadyAdd = daysToDisplay.find((day) => day === dayToAdd);
    if (dayAlreadyAdd) {
      setDaysToDisplay(daysToDisplay.filter((day) => day !== dayToAdd));
    } else {
      setDaysToDisplay([...daysToDisplay, dayToAdd]);
    }
  };

  const handleOpenMidiaDaysModal = () => {
    setOpenMidiaDaysModal(true);
  };
  const handleUpload = () => {
    setType("upload");
    setOpenModal(true);
  };

  const handleUploadNews = () => {
    api
      .get("/noticia/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setNewsList(resp.data.result);
          setType("news");
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWeather = () => {
    setType("weather");
    setOpenModal(true);
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Cadastro realizado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    if (type !== "error") navigate("/app/grades");
  };

  const removeMedia = (id) => {
    let mediaGrid = selectedMedia.filter((el) => {
      if (el.id) {
        return el.id != id;
      } else {
        return el.mul_id != id;
      }
    });
    setSelectedMedia(mediaGrid);
  };

  const saveGrid = () => {
    let gridMedia = [];

    selectedMedia.forEach((el, ind) => {
      let time = el.presentation.split(" - ");
      let itemId = "";

      if (el.type === "imagem" || el.type === "video") {
        itemId = el.mul_id;
      } else if (el.type === "clima") {
        itemId = el.cli_id;
      } else if (el.type === "noticia") {
        itemId = el.not_id;
      }

      let media = {
        id: itemId,
        con_tipo: el.type === "imagem" || el.type === "video" ? "midia" : el.type,
        con_duracao: el.duration, //+ ':00',
        con_inicio: time[0] === "null" ? null : time[0],
        con_fim: time[1] === "null" ? null : time[0],
        con_ordem: ind + 1,
        exc_exibicaoConteudo: el.midiaDaysToSave,
      };

      gridMedia.push(media);
    });

    if (gridMedia.length === 0)
      return openErrorAlert("Insira uma mídia para grade.");

    let gridContent = {
      grp_nome: name,
      grp_cat_id: selectValue ? selectValue : null,
      conteudos: gridMedia,
    };

    api
      .post("/gradeprogramacao", gridContent)
      .then((resp) => {
        if (resp.data.status && resp.data.status === 201) {
          openSuccessAlert();
        }
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };
  const handleCloseMidiaDaysModal = () => {
    setOpenMidiaDaysModal(false);
    setDaysToDisplay(["1", "2", "3", "4", "5", "6", "0"]);
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split("/")[1]}/grades`;

    navigate(url);
  };

  useEffect(() => {
    if (fadeProps === classes.fadeIn) {
      debounce(() => setFadeProps(""), 500, timeoutRef)();
    }
  }, [onDragEnd]);

  useEffect(() => {
    if (!isLoaded) {
      api
        .get("/categoria/all/ativos")
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setCategories(resp.data.result);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLoaded]);

  return (
    <Page className={classes.root} title="Nova Grade">
      <PageTabTitle
        className={classes.title}
        primary="GRADE DE PROGRAMAÇÃO"
        secondary="LISTA DE REPRODUÇÃO"
        active={activeTab}
        changeTab={changeActiveTab}
      />
      <Container className={classes.container}>
        {activeTab === "primary" ? (
          <>
            <FormControl variant="outlined">
              <TextField
                className={classes.input}
                label="Nome"
                margin="normal"
                name="busca"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                value={name}
                variant="outlined"
                required
              />
            </FormControl>

            <FormControl variant="outlined">
              <Select
                className={classes.select}
                native
                value={selectValue}
                onChange={(event) => setSelectValue(event.target.value)}
                required
              >
                <option value="">Selecione a categoria</option>
                {categories.map((cat) => (
                  <option key={cat.cat_id} value={cat.cat_id}>
                    {cat.cat_nome}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={() => changeActiveTab("secondary")}
              >
                AVANÇAR
              </Button>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{
                  marginLeft: 5,
                  backgroundColor: "#F07C00",
                  color: "white",
                }}
              >
                Voltar
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.headerButtons}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={handleMediaGrid}
              >
                SELECIONAR MULTIMIDIA
              </Button>

              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={handleUpload}
              >
                UPLOAD DE IMAGEM/VÍDEO
              </Button>

              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={handleUploadNews}
              >
                IMPORTAR NOTÍCIAS
              </Button>

              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={handleWeather}
              >
                IMPORTAR CLIMA
              </Button>
            </Box>

            <Container className={classes.mediaCardList}>
              {selectedMedia.map((item, index) => (
                <div
                  key={item.mul_id}
                  onDragStart={(e) => onDragStart(e, index)}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  id={index}
                  draggable={true}
                >
                  <MediaCard
                    id={item.id}
                    dragAndDrop
                    leftLabel
                    fadeProps={fadeProps}
                    labelNumber={index}
                    className={classes.root}
                    name={item.name}
                    image={item.image}
                    type={item.type}
                    category={item.category}
                    duration={item.duration}
                    presentation={item.midiaDaysToSave}
                    onDelete={() =>
                      removeMedia(item.id ? item.id : item.mul_id)
                    }
                    cidade={item.cli_cidade}
                    uf={item.cli_uf}
                    conId={item.mul_id}
                  />
                </div>
              ))}
            </Container>

            <Box className={classes.buttonBox}>
              <Button
                style={{ marginRight: "20px" }}
                disableElevation
                size="large"
                variant="outlined"
                onClick={saveGrid}
              >
                SALVAR
              </Button>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{
                  marginLeft: 5,
                  backgroundColor: "#F07C00",
                  color: "white",
                }}
              >
                Cancelar
              </Button>
            </Box>

            <EditModal
              open={openModal}
              type={type}
              newList={newsList}
              categories={categories}
              selectedMedia={selectedMedia}
              mediaGrid={mediaGrid}
              setSelectedMedia={setSelectedMedia}
              //grid={grid}
              close={() => {
                setMidiaDaysToSave([]);
                setOpenModal(false);
              }}
              openMidiaDays={handleOpenMidiaDaysModal}
              daysToDisplay={midiaDaysToSave}
              setVideoDuration={setVideoDuration}
              videoDuration={videoDuration}
            />
            <MidiaDaysModal
              open={openMidiaDaysModal}
              close={handleCloseMidiaDaysModal}
              handleDaysToDisplay={handleDaysToDisplay}
              daysToDisplay={daysToDisplay}
              save={setMidiaDaysToSave}
              videoDuration={videoDuration}
            />
          </>
        )}
      </Container>

      <AlertDialog
        open={openAlert}
        handleClose={() => handleAlertClose(alertType)}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

export default NewGrid;
