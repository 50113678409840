import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Select,
  TextField
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTabTitle from 'src/components/PageTabTitle';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { validEmail } from 'src/services/validDocument';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '326px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '60px',
    marginBottom: '60px'
  }
}));

const EditUserView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const me = useSelector((state) => state.auth.user);

  const [currentUser, setCurrentUser] = useState({
    usr_id: '',
    usr_nome: '',
    usr_email: '',
    usr_tipoUsuario: ''
  })

  const [startLoader, setStartLoader] = useState(false);

  const [activeTab, setactiveTab] = useState('primary');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: currentUser.usr_id,
      nome: currentUser.usr_nome,
      email: currentUser.usr_email,
      cliente: null,
      tipo: currentUser.usr_tipoUsuario,
      senhaAtual: '',
      senha: '',
      confirmaSenha: ''
    }
  });

  const handleSave = () => {
    let values = formik.values;

    if (!validEmail(values.email)) return openErrorAlert('Insira um login válido.');
    if (!values.nome) {
      return openErrorAlert('Nome deve estar preenchido.')
    } else if (!values.email) {
      return openErrorAlert('Email deve estar preenchido.')
    }
    setStartLoader(true);

    let obj = {
      "usr_id": values.id,
      "usr_nome": values.nome,
      "usr_email": values.email,
      "usr_cli_id": values.tipo === "cliente" ? client.cli_id : null,
      "usr_tipoUsuario": values.tipo
    }

    if (values.tipo !== "cliente") {
      delete obj.usr_cli_id;
    }

    api.put('/user', obj)
      .then((resp) => {
        setStartLoader(false);
        setCurrentUser(resp.data.result);
        openSuccessAlert();
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Usuario atualizado com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const changeActiveTab = (tab) => {
    const values = formik.values;

    if (!validEmail(values.email)) return openErrorAlert('Insira um login válido.');
    if (!values.nome) return openErrorAlert('Insira o nome do usuário.');
    if (!values.email) return openErrorAlert('Insira o e-mail do usuário.');
    setactiveTab(tab);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  }

  const updatePassword = () => {
    let obj = {
      "usr_id": id,
      "usr_senha": formik.values.senha
    }

    if (!formik.values.senha) {
      return openErrorAlert('Insira uma senha.');
    }

    if (formik.values.senha === formik.values.confirmaSenha) {
      api.put('/user/senha', obj)
        .then((resp) => {
          openSuccessAlert();
          formik.setFieldValue('senha', '');
          formik.setFieldValue('confirmaSenha', '');
        })
        .catch((err) => {
          console.log(err);
          openErrorAlert(err.response.data.message);
        })
    } else {
      alert('Senhas diferentes.')
    }
  }

  const fetchUser = () => {
    api.get(`/user/${id}`)
      .then((resp) => {
        const { result } = resp.data;

        fetchClients(result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }

  const fetchClients = (user) => {
    api.get('/cliente')
      .then((resp) => {
        setClients(resp.data.result);

        if (user.usr_tipoUsuario === "cliente") {
          let clientArr = resp.data.result.filter((ele) => ele.cli_id === user.usr_cli_id);
          if (clientArr.length > 0) {
            setClient(clientArr[0]);
          }
        }

        setCurrentUser(user);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }

  const backPage = () => {
    const url = `/${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}`;

    navigate(url);
  }

  useEffect(() => {
    fetchUser();
  }, [id]);

  return (
    <Page className={classes.root} title="Atualizar Usuário">
      <PageTabTitle primary="USUÁRIO" secondary="SENHA" active={activeTab} changeTab={changeActiveTab} />
      <Container className={classes.container}>
        {
          activeTab === "primary" ? (
            <>
              <Box className={classes.fullBox}>
                {me.usr_tipoUsuario !== "caixa" ?
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo de usuário</InputLabel>
                    <Select
                      native
                      value={formik.values.tipo}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="tipo"
                      label="Tipo de usuário"
                    >
                      {
                        me.usr_tipoUsuario === "admin" && (
                          <option value="admin">Admin</option>
                        )
                      }

                      {me.usr_tipoUsuario !== "caixa" && (
                        <option value="cliente">Cliente</option>
                      )
                      }
                      {
                        me.usr_tipoUsuario === "admin" && (
                          <option value="caixa">Supervisor</option>
                        )
                      }
                    </Select>
                  </FormControl> : null
                }

                {
                  formik.values.tipo === 'cliente' ?
                    (
                      <Autocomplete
                        value={client}
                        options={clients}
                        getOptionLabel={(option) => option.cli_razaoSocial || ''}
                        onChange={(event, value) => {
                          setClient(value);
                        }}
                        style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px' }}
                        renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" />}
                      />
                    ) : <></>
                }

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Nome"
                  margin="normal"
                  name="nome"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.nome}
                  variant="outlined"
                  required
                />

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Login"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.email}
                  variant="outlined"
                  required
                />
              </Box>

              <Box className={classes.buttonBox}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleSave}
                >
                  Salvar
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{ marginLeft: 5, backgroundColor: '#F07C00', color: 'white' }}
                >
                  Cancelar
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.fullBox}>
                {/*
                <TextField
                  fullWidth
                  label="Senha Atual"
                  margin="normal"
                  name="senhaAtual"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.senhaAtual}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showCurrentPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownCurrentPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                    }}
                />
              */}

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Nova senha"
                  margin="normal"
                  name="senha"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.senha}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Confirme a nova senha"
                  margin="normal"
                  name="confirmaSenha"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formik.values.confirmaSenha}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showConfirmPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              <Box className={classes.buttonBox}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={updatePassword}
                >
                  SALVAR
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{ marginLeft: 5, backgroundColor: '#F07C00', color: 'white' }}
                >
                  Cancelar
                </Button>
              </Box>
            </>
          )
        }
      </Container>

      <Loader display={startLoader} />
      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default EditUserView;
