import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  imgLogo: {
    width: '350px',
    height: '142.4px'
  },
  simpleTextBox: {
    display: 'flex',
    marginTop: '32px',
    marginBottom: '16px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: 8,
    marginLeft: '-17%'
  }
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const RedefinePasswordView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .max(255)
        .required('Senha é obrigatória'),
      confirmPassword: Yup.string()
        .max(255)
        .required('Senha é obrigatória'),
    })
  });
  
  const handleSubmit = async () => {
    let token = query.get('t');
    let values = formik.values;

    if (token && token !== '') {
      if (values.password === values.confirmPassword) {
        setLoading(true);

        let obj = {
          "usr_senha": values.password,
          "usr_codigoRecuperacaoSenha": token
        }

        api.put('/user/recuperarsenha', obj)
          .then((resp) => {
            setLoading(false);
            openSuccessAlert();
            navigate('/redefined-password-success');
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            openErrorAlert();
          })
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  }

  const openErrorAlert = () => {
    setAlertText('Houve um erro ao redefinir a senha. Tente novamente. Caso persista o erro, entre em contato com o administrador');
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('A senha foi redefinida com sucesso. Clique no botão abaixo e faça login.');
    setAlertType('success');
    setAlertButtonText('Fazer Login');

    setOpenAlert(true);
  }

  const handleAlertCloseLogin = () => {
    setOpenAlert(false);
    // navigate('/login');
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  return (
    <Page className={classes.root} title="Esqueci a senha">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box className={classes.boxTitle} maxWidth="xs">
            <Grow in timeout={600}>
              <img
                alt="Logo"
                src="/static/images/logo_TVM.png"
                className={classes.imgLogo}
              />
            </Grow>
          </Box>

          <Box className={classes.simpleTextBox}>
            <Typography variant="body1">
              Digite sua nova senha e confirme a seguir para redefinir.
            </Typography>
          </Box>

          <Container maxWidth="xs">
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Nova senha"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      name="showPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />


            <TextField
              error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Confirme a nova senha"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showConfirmPassword ? 'text' : 'password'}
              value={formik.values.confirmPassword}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      name="showConfirmPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Box my={2}>
              <Button
                disableElevation
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                variant="outlined"
                onClick={handleSubmit}
              >
                Redefinir a senha
              </Button>

              {
                loading && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )
              }
            </Box>
          </Container>    
        </Container>
      </Box>

      <AlertDialog open={openAlert} handleClose={alertType === 'success' ? handleAlertCloseLogin : handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default RedefinePasswordView;
