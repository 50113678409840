import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import Styled from "styled-components";


const Container = Styled.div`
  progress {
    -webkit-appearance: none;
    appearance: none;
  }

  progress[value]::-webkit-progress-value {
    background-color: #F07C00;
  }
`;

export default function Progressbar({ value, max }) {
  return (
    <Container>
      <progress value={value} max={max} className={css(styles.progressbar)} />
    </Container>
  );
}

Progressbar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
};

Progressbar.defaultProps = {
  max: 100,
};

const styles = StyleSheet.create({
  progressbar: {
    position: "relative",
    bottom: "20px",
    width: "100%",
    height: "5px",
  },
});
