import React from 'react';
import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import noticiasPlaceholder from 'src/utils/noticiasPlaceholder';

const useStyles = makeStyles((theme) => ({
  newsBox: {
    display: 'flex', 
    flexDirection: 'column', 
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 0, 0, .7)',
    width: '30%',
    padding: '20px'
  }
}));

const NewsCard = (props) => {
  const classes = useStyles();

  const backgroundUrl = props.news && props.news.noc_imagem 
    ? props.news.noc_imagem 
    : noticiasPlaceholder[props.category][0];

  return (
    <Box 
      style={
        { 
          width: "100%", 
          height: "64vh", 
          display: "flex", 
          flexDirection: 'row', 
          backgroundImage: `url(${backgroundUrl})`, 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: '50% 62%',
          justifyContent: 'flex-end' 
        }
      }
    >
      
      <Box className={classes.newsBox}>
        <Typography variant="h2">{ props.news.noc_titulo }</Typography>
        <Typography style={{ marginTop: '20px' }} variant="h6">{ props.news.noc_descricao }</Typography>
      </Box>        
    </Box>
  )
}

export default NewsCard;