import React, { useState } from 'react';
import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalsReport from "src/views/reports/modals"
import Page from 'src/components/Page';
import PageTitle from 'src/components/PageTitle';
import api from 'src/services/api';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 16,
    borderBottom: 'none'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  searchBox: {
    marginRight: '210px',
    width: '326px'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 48,
  }
}));

const ReportsView = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([]);
  const [lotteries, setLotteries] = useState([]);
  const [players, setPlayers] = useState([]);
  const [displayViewReport, setDisplayViewReport] = useState(false);

  const generateReport = (report) => {

  }

  const openModal = (report) => {
    if (report === 'reproducao-midia') {
      api.get('/multimidia/all/ativos?quantidade=1000')
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setData(resp.data.result.items); 

          api.get('/cliente')
          .then((resp) => {
            if (resp.data.status && resp.data.status === 200) {
              setClients(resp.data.result);
              setType('reproducao-midia');
              setOpen(true);
            }
          })
          .catch((err) => console.log(err))
        }
      })
      .catch((err) => console.log(err))
    } else if (report === 'player-cliente') {
      api.get('/cliente')
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setClients(resp.data.result);
          setType('player-cliente');
          setOpen(true);
        }
      })
      .catch((err) => console.log(err))
    } else if (report === 'reproducao-cef') {
      api.get('/loterica/')
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setLotteries(resp.data.result);
          setType('reproducao-cef');
          setOpen(true);
        } 
      })
      .catch((err) => console.log(err))
    } else {
      setType(report);
      setOpen(true);
    }
  }

  const fetchLotteries = (clientId) => {
    api.get(`/loterica/cliente/${clientId}`)
    .then((resp) => {
      if (resp.data.status && resp.data.status === 200) {
        setLotteries(resp.data.result);
      }
    })
    .catch((err) => console.log(err))
  }

  const fetchPlayers = (lotteryId) => {
    api.get(`/player/loterica/${lotteryId}`)
    .then((resp) => {
      if (resp.data.status && resp.data.status === 200) {
        setPlayers(resp.data.result);
      }
    })
    .catch(err => console.log(err))
  }

  return (
    <Page className={classes.root} title="Relatórios">
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <PageTitle title="RELATÓRIOS" />
      </Box>

      <Container className={classes.container}>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="Tabela de clientes">
            <TableHead>
              <TableRow>
                <StyledTableCell>RELATÓRIO</StyledTableCell>
                <StyledTableCell>AÇÕES</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow >
                  <StyledTableCell>Reprodução de Mídia</StyledTableCell>
                  <StyledTableCell>
                    {
                      displayViewReport && (
                        <IconButton onClick={() => generateReport('reproducao-midia')}>
                          <GetAppIcon />
                        </IconButton>
                      )
                    }
                    <IconButton onClick={() => openModal('reproducao-midia')}>
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
                <TableRow >
                  <StyledTableCell>Player por cliente</StyledTableCell>
                  <StyledTableCell>
                    {
                      displayViewReport && (
                        <IconButton onClick={() => generateReport('player-cliente')}>
                          <GetAppIcon />
                        </IconButton>
                      )
                    }
                    <IconButton onClick={() => openModal('player-cliente')}>
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
                <TableRow >
                  <StyledTableCell>Importação Supervisor Econômica Federal</StyledTableCell>
                  <StyledTableCell>
                    {
                      displayViewReport && (
                        <IconButton onClick={() => generateReport('importacao-cef')}>
                          <GetAppIcon />
                        </IconButton>
                      )
                    }
                    <IconButton onClick={() => openModal('importacao-cef')}>
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
                <TableRow >
                  <StyledTableCell>Reprodução Supervisor Econômica Federal</StyledTableCell>
                  <StyledTableCell>
                    {
                      displayViewReport && (
                        <IconButton onClick={() => generateReport('reproducao-cef')}>
                          <GetAppIcon />
                        </IconButton>
                      )
                    }
                    <IconButton onClick={() => openModal('reproducao-cef')}>
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

     <ModalsReport 
      open={open} 
      modalType={type} 
      setOpen={setOpen} 
      data={data} 
      clients={clients} 
      lotteries={lotteries} 
      fetchLotteries={fetchLotteries} 
      players={players} 
      fetchPlayers={fetchPlayers} 
    />
    </Page>
  );
};

export default ReportsView;
