import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import AlertDialog from "src/components/AlertDialog";
import api from "src/services/api";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5F5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 16,
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  searchBox: {
    marginRight: "210px",
    width: "326px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: 48,
  },
  table: {
    minWidth: 700,
  },
  green: {
    color: "#009C6D",
  },
  red: {
    color: "#E6202A",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
    width: "100%",
    height: "100%"
  }
}));

const UserListView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const me = useSelector((state) => state.auth.user);

  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  const requestSearch = (e) => {
    let val = e.target.value;
    setSearched(val);

    if (val !== "") {
      const filteredRows = users.filter((row) => {
        return row.usr_nome.toLowerCase().includes(val.toLowerCase());
      });

      setRows(filteredRows);
    } else {
      setRows(users);
    }
  };

  const newUser = () => {
    navigate("/app/novo-usuario");
  };

  const viewUser = (id) => {
    navigate(`/app/usuarios/${id}`);
  };

  const changeStatus = (id) => {
    api
      .delete(`/user/${id}`)
      .then(() => {
        openSuccessAlert();
        setIsLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Status atualizado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (!isLoaded) {
      api
        .get("/user" + `${me.usr_tipoUsuario === "caixa" ? "/caixa/all" : ""}`)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setUsers(resp.data.result);
            setRows(resp.data.result);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
          openFetchErrorAlert(err.response.data.message);
        });
    }
  }, [isLoaded]);

  return (
    <Page className={classes.root} title="Usuários">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle title="USUÁRIOS" />
        <TextField
          className={classes.searchBox}
          label="O que deseja localizar?"
          margin="normal"
          name="busca"
          onChange={(e) => requestSearch(e)}
          type="text"
          value={searched}
          variant="outlined"
        />
      </Box>
      {isLoaded ? (
        <Container className={classes.container}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="Tabela de clientes"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>NOME</StyledTableCell>
                  <StyledTableCell>LOGIN</StyledTableCell>
                  {me.usr_tipoUsuario !== "caixa" ? <StyledTableCell>CLIENTE</StyledTableCell> : null}
                  <StyledTableCell>TIPO</StyledTableCell>
                  <StyledTableCell>STATUS</StyledTableCell>
                  <StyledTableCell>AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((user) => (
                  <TableRow key={user.usr_id}>
                    <StyledTableCell component="th" scope="row">
                      {user.usr_nome}
                    </StyledTableCell>
                    <StyledTableCell>{user.usr_email}</StyledTableCell>
                    {me.usr_tipoUsuario !== "caixa" ? <StyledTableCell>{user.cli_razaoSocial}</StyledTableCell> : null}
                    <StyledTableCell>{user.usr_tipoUsuario}</StyledTableCell>
                    <StyledTableCell
                      className={
                        user.usr_status === 1 ? classes.green : classes.red
                      }
                    >
                      {user.usr_status === 1 ? "Ativo" : "Desativado"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={() => changeStatus(user.usr_id)}>
                        {user.usr_status === 1 ? <CloseIcon /> : <CheckIcon />}
                      </IconButton>
                      <IconButton onClick={() => viewUser(user.usr_id)}>
                        <VisibilityIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {
            me.usr_tipoUsuario !== "caixa" && me.usr_tipoUsuario !== "cliente" ? (
              <Fab color="secondary" className={classes.fab} onClick={newUser}>
                <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
              </Fab>
            ) : null
          }

        </Container>
      ) : (
        <div className={classes.skeletonContainer}>
          <Skeleton width="80%" height={45} />
          <Skeleton width="80%" variant="rectangular" height={"calc(100% - 150px)"} />
        </div>
      )}

      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

export default UserListView;
