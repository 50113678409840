import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Fab, makeStyles, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import MediaCard from "src/components/MediaCard";
import Modal from "src/components/Modal";
import Loader from "src/components/Loader";
import api from "src/services/api";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  searchBox: {
    marginRight: "210px",
    width: "326px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "1440px",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  templateGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    marginTop: theme.spacing(10),
    paddingBottom: "40px",
    gap: theme.spacing(1),
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    padding: "0 70px",
    marginTop: theme.spacing(10),
    gap: theme.spacing(1),
    height: "100%",
  },
}));

const Templates = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [modalType, setModalType] = useState("");
  const [contentSrc, setContentSrc] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  const requestSearch = (e) => {
    let val = e.target.value;
    setSearched(val);

    if (val !== "") {
      const filteredRows = templates.filter((row) => {
        return row.tem_nome.toLowerCase().includes(val.toLowerCase());
      });

      setRows(filteredRows);
    } else {
      setRows(templates);
    }
  };

  const handleClick = () => {
    navigate("/app/novo-template");
  };

  const onDelete = (id) => {
    api
      .delete(`/template/status/${id}`)
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onOpen = (path, tipo) => {
    setStartLoader(true);

    api
      .get(`/multimidia/file/${path}`, { responseType: "blob" })
      .then((resp) => {
        const objectUrl = URL.createObjectURL(resp.data);

        setStartLoader(false);
        setModalType(tipo);
        setOpenModal(true);
        setContentSrc(objectUrl);
      })
      .catch((err) => {
        console.log(err);
        setStartLoader(false);
      });
  };

  const onClose = () => {
    setOpenModal(false);
    URL.revokeObjectURL(contentSrc);
    setContentSrc("");
  };

  useEffect(() => {
    if (!isLoaded) {
      api
        .get("/template/all/ativos")
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setTemplates(resp.data.result);
            setRows(resp.data.result);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLoaded]);

  return (
    <Page className={classes.root} title="Templates">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle title="TEMPLATES" />
        <TextField
          className={classes.searchBox}
          label="O que deseja localizar?"
          margin="normal"
          name="busca"
          onChange={(e) => requestSearch(e)}
          type="text"
          value={searched}
          variant="outlined"
        />
      </Box>

      {isLoaded ? (
        <Container className={classes.container}>
          <Box className={classes.templateGrid}>
            {rows.map((template) => (
              <MediaCard
                key={template.tem_id}
                className={classes.root}
                disableEdit
                name={template.tem_nome}
                image={template.tem_thumb}
                type={template.tem_tipo}
                content={template.tem_path}
                category={template.tem_category}
                onOpen={() => onOpen(template.tem_path, template.tem_tipo)}
                onDelete={() => onDelete(template.tem_id)}
              />
            ))}
          </Box>

          <Fab color="secondary" className={classes.fab} onClick={handleClick}>
            <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
          </Fab>
        </Container>
      ) : (
        <div className={classes.skeletonContainer}>
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
          <Skeleton width={287} variant="rectangular" height={161} />
        </div>
      )}

      <Modal
        type={modalType}
        open={openModal}
        content={contentSrc}
        close={onClose}
      />

      <Loader display={startLoader} />
    </Page>
  );
};

export default Templates;
