import React, { useState, useEffect } from "react";
import MultimediaDropzone from "./MultimediaDropzone.js";
import {
  Box,
  Button,
  Fade,
  FormControl,
  FormLabel,
  FormControlLabel,
  IconButton,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  TableContainer,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Select,
  withStyles,
  Typography,
} from "@material-ui/core";

import { Autocomplete } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import states from "src/services/brazilian-states";
import CardList from "src/components/CardList";
import PageTitle from "src/components/PageTitle";
import api from "src/services/api";
import AlertDialog from "./AlertDialog.js";
import deafaultDaysToDisplay from "src/utils/defaultDaysToDisplay.js";
import Loader from "./Loader.js";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5F5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 16,
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  newsList: {
    overflowY: "scroll",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "90vw",
    // height: "95vh",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 10),
    "& .MuiAutocomplete-root": {
      height: 56,
      "& .MuiAutocomplete-inputRoot": {
        height: 56,
      },
    },
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
    margin: 0,
    padding: 0,
    border: "2px solid #000",
    borderRadius: "50%",
  },
  nameBox: {
    width: "23vw",
  },
  nameInput: {
    width: "33vw",
  },
  timeBox: {
    width: "7vw",
    //margin: theme.spacing(1),
  },
  select: {
    width: "23vw",
    margin: theme.spacing(2),
  },
  uploadSelect: {
    width: "33vw",
    margin: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  weatherImage: {
    width: "25vw",
    height: "25vh",
  },
  table: {
    marginTop: "30px",
    width: "60%",
  },
  tableGrid: {
    marginTop: "30px",
    width: "90%",
  },
  mediaGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "85vh",
    overflowY: "scroll",
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
  },
  imageGrid: {
    display: "flex",
    flexDirection: "row",
    width: 225,
    cursor: "pointer",
    height: 130,
    "& h2": {
      fontSize: "10px",
      color: "#5E5E5E",
      paddingBottom: 2,
      "& p": {
        fontSize: "14px",
        marginTop: 8,
        transform: "translatey(-5px)",
        "& svg": {
          width: 18,
        },
      },
    },
  },
  imageGridSelected: {
    display: "flex",
    flexDirection: "row",
    borderWidth: "2px",
    borderColor: "#F07C00",
    borderStyle: "solid",
    width: 225,
    height: 130,
    cursor: "pointer",
    "& h2": {
      fontSize: "10px",
      color: "#5E5E5E",
      paddingBottom: 2,
      "& p": {
        fontSize: "14px",
        marginTop: 8,
        transform: "translatey(-5px)",
        "& svg": {
          width: 18,
        },
      },
    },
  },
  styledTitleBox: {
    minWidth: "336px",
    maxWidth: "40vw",
    height: "48px",
    whiteSpace: "nowrap",
    paddingLeft: 20,
    backgroundColor: "#F07C00",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: "-80px",
    alignSelf: "flex-start",
  },
  styledTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#FFFFFF",
    paddingRight: "16px",
  },
  imageGridCard: {
    display: "flex",
    flexDirection: "row",
    width: 225,
    height: 130,
    border: "1px solid rgba(0,0,0,0.1)",
    "& h2": {
      fontSize: "10px",
      color: "#5E5E5E",
      paddingBottom: 2,
      "& p": {
        fontSize: "10px",
        marginTop: 5,
        transform: "translatey(-5px)",
        "& svg": {
          width: 15,
        },
      },
    },
    "& .MuiCardContent-root": {
      padding: "10px",
    },
  },
  radioGroup: {
    display: "flex",
    justifyContent: "center",
  },
  searchBox: {
    marginLeft: "10px",
    width: "326px",
  },

  formControl: {},
  label: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  divRadios: {
    marginTop: 12,
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [mediaType, setMediaType] = useState("");
  const [cityStates, setCityStates] = useState(states);
  const [cityState, setCityState] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState("00:00:15");
  const [mediaCategory, setMediaCategory] = useState("");
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [selectedNews, setSelectedNews] = useState("");
  const [selectedNewsCategory, setSelectedNewsCategory] = useState(null);
  const [alreadySelected, setAlreadySelected] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [searched, setSearched] = useState("");
  const [categories, setCategories] = useState([]);

  const [mediaGrid, setMediaGrid] = useState([]);

  const [viewGrid, setViewGrid] = useState(false);
  const [selectedGrid, setSelectedGrid] = useState([]);
  const [importGrid, setImportGrid] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");

  const [deselectCard, setDeselectCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCityStates(states);

    api
      .get("/categoria/")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setCategories(resp.data.result);
          // setMediaCategory(resp?.data?.result[0]?.cat_id)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    api
      .get("/multimidia/all/ativos?quantidade=1000")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setMediaGrid(resp.data.result.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedMedia && selectedMedia.mul_video_duration) {
      props.setVideoDuration(selectedMedia.mul_video_duration);
    } else {
      if (!selectedMedia) props.setVideoDuration(null);
    }
  }, [selectedMedia, props]);

  const requestSearch = async (e, type) => {
    setAlreadySelected(false);

    let val = e.target?.value ? e.target.value : "";
    if (type === "text") setSearched(val);

    if (val !== "") {
      let searchStrByCat = `cat_id=${val}`;
      let searchStrByName = `mul_nome=${val}`;

      const filteredRows = await api
        .get(
          `multimidia/all/ativos?quantidade=1000&${type === "text" ? searchStrByName : searchStrByCat
          }`
        )
        .then((res) => res.data.result.items)
        .catch((err) => {
          console.log("Error: ", err);
          return [];
        });
      setMediaGrid(filteredRows);
    } else {
      api
        .get("/multimidia/all/ativos?quantidade=1000")
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setMediaGrid(resp.data.result.items);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    //if(type !== 'error') navigate('/app/');
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const saveMedia = async () => {

    if (
      !name &&
      props.type !== "news" &&
      props.type !== "weather" &&
      props.type !== "media-grid"
    ) {
      return openErrorAlert("Insira o nome da mídia.");
    }

    if (!selectedMedia && props.type !== "weather" && props.type !== "news") {
      return openErrorAlert("Insira uma mídia.");
    }

    setStartLoader(true);

    if (props.type === "upload") {
      if (name !== "" && duration !== "" && selectedMedia) {
        let url = "/multimidia";
        let data = new FormData();

        data.append("mul_nome", name);
        data.append("mul_tipo", mediaType === "image" ? "imagem" : mediaType);
        console.log(mediaCategory);
        if (mediaCategory !== "") data.append("mul_cat_id", mediaCategory);
        data.append("file", selectedMedia, selectedMedia.name);
        data.append("mul_video_duration", props.videoDuration);

        let defaultDays = [];
        if (props.videoDuration) {
          deafaultDaysToDisplay.forEach((day) => {
            const dayToEdit = { ...day };

            dayToEdit.exc_duracao = props.videoDuration;

            defaultDays.push(dayToEdit);
          });
        }

        await api
          .post(url, data)
          .then((resp) => {
            if (resp.data.status && resp.data.status === 201) {
              let selectedMedia = props.selectedMedia;
              let result = resp.data.result;

              let media = {
                mul_id: result.mul_id,
                name: result.mul_nome,
                image: result.mul_thumb,
                type: result.mul_tipo,
                mul_processamento_concluido: result.mul_processamento_concluido,
                category: result?.categoria?.cat_nome
                  ? result?.categoria?.cat_nome
                  : "",
                duration: duration,
                presentation: `${startTime ? startTime : null} - ${endTime ? endTime : null
                  }`,
                midiaDaysToSave:
                  props.daysToDisplay.length === 0 && !props.videoDuration
                    ? deafaultDaysToDisplay
                    : props.videoDuration
                      ? defaultDays
                      : props.daysToDisplay,
              };

              selectedMedia.push(media);
              setSelectedMedia(selectedMedia);
            }
          })
          .catch((err) => {
            console.log(err.response);
            setStartLoader(false);
          });
      }
    } else if (props.type === "news") {
      if (duration !== "" && selectedNews !== "") {
        let selectedMedia = props.selectedMedia;
        let media = {
          not_id: parseInt(selectedNews),
          name: name,
          image: "",
          type: "noticia",
          duration: duration,
          presentation: `${startTime ? startTime : null} - ${endTime ? endTime : null
            }`,
          midiaDaysToSave:
            props.daysToDisplay.length === 0
              ? deafaultDaysToDisplay
              : props.daysToDisplay,
          category: selectedNewsCategory,
        };

        selectedMedia.push(media);
        setSelectedMedia(props.selectedMedia);
      }
    } else if (props.type === "weather") {
      if (duration !== "" && cityState !== "" && city !== "") {
        await api
          .get(`/clima/tempo?cidade=${city}&uf=${cityState}`)
          .then((res) => {
            if (res.data.status && res.data.status === 200) {
              let selectedMedia = props.selectedMedia;
              const result = res.data.result;

              let media = {
                cli_id: result.cli_id,
                name: result.cli_cidade + ", " + result.cli_uf,
                image: "",
                type: "clima",
                category: "",
                duration: duration,
                presentation: `${startTime ? startTime : null} - ${endTime ? endTime : null
                  }`,
                midiaDaysToSave:
                  props.daysToDisplay.length === 0
                    ? deafaultDaysToDisplay
                    : props.daysToDisplay,
                cli_cidade: result.cli_cidade,
                cli_uf: result.cli_uf,
              };

              if (res.data.result.cli_condition) {
                media.condition = res.data.result.cli_condition;
              }

              selectedMedia.push(media);
              setSelectedMedia(props.selectedMedia);
            }
          })
          .catch((err) => {
            openErrorAlert(err.response.data.message);
          });
      }
    } else if (props.type === "media-grid") {
      if (duration !== "" && selectedMedia) {
        let media = {
          mul_id: selectedMedia.mul_id,
          name: selectedMedia.mul_nome,
          image: selectedMedia.mul_thumb,
          type: selectedMedia.mul_tipo,
          category:
            selectedMedia.categoria && selectedMedia.categoria?.cat_nome
              ? selectedMedia.categoria.cat_nome
              : "",
          mul_processamento_concluido: selectedMedia.mul_processamento_concluido,
          duration:
            selectedMedia.mul_video_duration !== "null"
              ? selectedMedia.mul_video_duration
              : null,
          presentation: `${startTime ? startTime : null} - ${endTime ? endTime : null
            }`,
          midiaDaysToSave:
            props.daysToDisplay.length === 0
              ? deafaultDaysToDisplay
              : props.daysToDisplay,
        };

        props.selectedMedia.push(media);
        setSelectedMedia(props.selectedMedia);
      }
    }
    cleanVariablesAndClose();
  };

  const saveGrid = (content) => {
    setStartLoader(true);

    if (content.length > 0) {
      content.forEach((el) => {
        let media = {};


        if (el.con_tipo === "clima") {
          console.log(el);
          media = {
            ...el.clima,
            cli_id: el.clima.cli_id,
            name: el.clima.cli_nome,
            image: "",
            type: "clima",
            category: "",
            duration: el.con_duracao,
            presentation: `${el.con_inicio} - ${el.con_fim}`,
            midiaDaysToSave: el.exibicao,
          };

          props.selectedMedia.push(media);
        } else if (el.con_tipo === "noticia") {
          media = {
            not_id: el.noticia.not_id,
            name: el.noticia.not_nome,
            image: "",
            type: "noticia",
            duration: el.con_duracao,
            presentation: `${el.con_inicio} - ${el.con_fim}`,
            midiaDaysToSave: el.exibicao,
            category: el.noticia?.not_categoria,
          };

          props.selectedMedia.push(media);
        } else if (el.con_tipo === "midia") {
          media = {
            mul_id: el.midia.mul_id,
            name: el.midia.mul_nome,
            image: el.midia.mul_thumb,
            type: el.midia.mul_tipo,
            category: el.midia.cat_nome ? el.midia.cat_nome : "",
            duration: el.con_duracao,
            presentation: `${el.con_inicio} - ${el.con_fim}`,
            midiaDaysToSave: el.exibicao,
          };

          props.selectedMedia.push(media);
        }
      });

      setSelectedMedia(props.selectedMedia);
    }
    cleanVariablesAndClose();
  };

  const setMedia = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    if (selectedFile.type.includes("image")) {
      changeInputType("image");
    } else {
      changeInputType("video");
    }
    setName(selectedFile.name.split(".")[0]);
    setSelectedMedia(selectedFile);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewMedia(reader.result);
    };

    reader.readAsDataURL(selectedFile);
  };

  const removeMedia = () => {
    props.setVideoDuration(null);
    setSelectedMedia(null);
    setPreviewMedia(null);
    setMediaType("");
    setName("");
    setMediaCategory("");
  };

  const changeInputType = (val) => {
    removeMedia();
    setMediaType(val);
  };

  const cleanVariablesAndClose = () => {
    setMediaType("");
    setCityState("");
    setCities([]);
    setCity("");
    setName("");
    setStartTime("");
    setEndTime("");
    setDuration("00:00:15");
    api
      .get("/multimidia/all/ativos?quantidade=1000")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setMediaGrid(resp.data.result.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // setMediaGrid(props.mediaGrid)
    setSelectedMedia(null);
    setPreviewMedia(null);
    setSelectedNews("");
    setAlreadySelected(false);
    setImportGrid([]);
    setSelectedGrid([]);
    setViewGrid(false);
    setStartLoader(false);
    props.setVideoDuration(null);
    props.close();
  };

  const selectCard = (id) => {
    let selected = mediaGrid.filter((el) => el.mul_id == id)[0];

    if (selectedMedia && selectedMedia.mul_id == id) {
      setDeselectCard(true);
      setSelectedMedia(null);
      setAlreadySelected(false);
    } else {
      setDeselectCard(false);
      props.setVideoDuration(null);
      console.log(selected);
      setSelectedMedia(selected);
      setAlreadySelected(true);
    }
  };

  const fetchCities = (state) => {
    setIsLoading(true);
    setCity("");
    setCities([]);
    setCityState(state);

    api
      .get(`/clima/${state}/cidades`)
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setCities(resp.data.result);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const openGrid = (content) => {
    setImportGrid(content);
    let obj = [];

    content.forEach((el) => {
      let media = {};

      if (el.con_tipo === "clima") {
        media = {
          mul_id: el.clima.cli_id,
          mul_nome: el.clima.cli_nome,
          mul_thumb: "",
          mul_tipo: "clima",
          category: "",
          duration: el.con_duracao,
          presentation: `${el.con_horaInicio} - ${el.con_horaFim}`,
          midiaDaysToSave: props.daysToDisplay,
        };

        obj.push(media);
      } else if (el.con_tipo === "noticia") {
        media = {
          mul_id: el.noticia.not_id,
          mul_nome: el.noticia.not_nome,
          mul_thumb: "",
          mul_tipo: "noticia",
          category: "",
          duration: el.con_duracao,
          presentation: `${el.con_horaInicio} - ${el.con_horaFim}`,
          midiaDaysToSave: props.daysToDisplay,
        };

        obj.push(media);
      } else if (el.con_tipo === "midia") {
        media = {
          mul_id: el.midia.mul_id,
          mul_nome: el.midia.mul_nome,
          mul_thumb: el.midia.mul_thumb,
          mul_tipo: el.midia.mul_tipo,
          category: el.midia.cat_nome ? el.midia.cat_nome : "",
          duration: el.con_duracao,
          presentation: `${el.con_horaInicio} - ${el.con_horaFim}`,
          midiaDaysToSave: props.daysToDisplay,
        };

        obj.push(media);
      }
    });

    setSelectedGrid(obj);
    setViewGrid(true);
  };

  const closeGrid = () => {
    setSelectedGrid([]);
    setViewGrid(false);
  };

  const importSelectedGrid = () => {
    saveGrid(importGrid);
  };

  return (
    <div>
      <AlertDialog
        open={openAlert}
        handleClose={() => handleAlertClose(alertType)}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => cleanVariablesAndClose()}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <>
              <>
                {props.type === "media-grid" && (
                  <Box width={"100%"} style={{ marginBottom: 30 }}>
                    <FormControl style={{ width: 490 }}>
                      <Typography variant="h3">Período :</Typography>
                      <div style={{ display: "flex", marginBottom: 15 }}>
                        <TextField
                          label="Inicio"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{ width: 150, marginRight: 15 }}
                        />
                        <TextField
                          label="Término"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{ width: 150 }}
                        />
                      </div>
                    </FormControl>
                    <div>
                      <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#F07C00", color: "white" }}
                        onClick={props.openMidiaDays}
                      >
                        Exibição Semanal
                      </Button>
                    </div>
                  </Box>
                )}
              </>
              {props.type === "media-grid" && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="autocomplete-filter"
                    options={categories.map((category) => {
                      return {
                        label: category.cat_nome,
                        value: category.cat_id,
                      };
                    })}
                    onChange={(e, val) =>
                      requestSearch({ target: { value: val?.value } })
                    }
                    className={classes.searchByCategoryBox}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categorias"
                        type="text"
                      />
                    )}
                  />
                  <TextField
                    className={classes.searchBox}
                    label="O que deseja localizar?"
                    name="busca"
                    onChange={(e) => requestSearch(e, "text")}
                    type="text"
                    value={searched}
                    variant="outlined"
                  />
                </div>
              )}
              {props.type === "media-grid" && (
                <>
                  <Box className={classes.mediaGrid}>
                    <CardList
                      obj={mediaGrid}
                      className={classes.imageGrid}
                      selectedClassName={classes.imageGridSelected}
                      flippable={false}
                      selectable={true}
                      onSelect={(id) => selectCard(id)}
                      alreadySelected={alreadySelected}
                      deselectCard={deselectCard}
                      setDeselectCard={setDeselectCard}
                    />
                    {mediaGrid.length === 0 ? (
                      <Typography variant="h3" style={{ marginTop: 20 }}>
                        Sem mídia para exibir.
                      </Typography>
                    ) : null}
                  </Box>
                </>
              )}

              {props.type === "upload" && (
                <>
                  <Typography style={{ marginBottom: 8 }}>
                    Adicionar nova mídia
                  </Typography>
                  <MultimediaDropzone
                    value={mediaType || "image"}
                    setMedia={setMedia}
                    removeMedia={removeMedia}
                    preview={previewMedia}
                    acceptField="image/*, video/mp4"
                    buttons
                    setVideoDuration={props.setVideoDuration}
                    videoDuration={props.videoDuration}
                    size="small"
                  />

                  {mediaType && (
                    <FormControl
                      className={classes.divRadios}
                      component="fieldset"
                    >
                      <RadioGroup
                        className={classes.radioGroup}
                        row
                        aria-label="midia"
                        name="midia"
                        value={mediaType}
                        onChange={(event) => {
                          removeMedia();
                          changeInputType(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="image"
                          control={<Radio color="primary" />}
                          label="Imagem"
                          disabled
                        />
                        <FormControlLabel
                          value="video"
                          control={<Radio color="primary" />}
                          label="Vídeo"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  <FormControl>
                    <TextField
                      className={classes.nameInput}
                      label="Nome"
                      margin="normal"
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      value={name}
                      variant="outlined"
                      required
                    />
                  </FormControl>
                  <Box width={"60%"}>
                    <FormControl
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 5,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h3" style={{ marginRight: 15 }}>
                          Período :
                        </Typography>
                        <TextField
                          label="Inicio"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{ width: 150, marginRight: 15 }}
                        />
                        <TextField
                          label="Término"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{ width: 150 }}
                        />
                      </div>
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#F07C00",
                          color: "white",
                          margin: "auto",
                        }}
                        onClick={props.openMidiaDays}
                      >
                        Exibição Semanal
                      </Button>
                    </div>
                  </Box>

                  <FormControl variant="outlined">
                    <Select
                      className={classes.uploadSelect}
                      native
                      value={mediaCategory}
                      onChange={(event) => setMediaCategory(event.target.value)}
                    >
                      <option value="" disabled>
                        Categorias
                      </option>
                      {props.categories.map((cat) => (
                        <option key={cat.cat_id} value={cat.cat_id}>
                          {cat.cat_nome}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {props.type === "weather" && (
                <>
                  <Box width={"32%"} style={{ marginBottom: 30 }}>
                    <FormControl style={{ width: 490 }}>
                      <Typography variant="h3">Período :</Typography>
                      <div style={{ display: "flex", marginBottom: 15 }}>
                        <TextField
                          label="Inicio"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{ width: 150, marginRight: 15 }}
                        />
                        <TextField
                          label="Término"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{ width: 150 }}
                        />
                      </div>
                    </FormControl>
                    <div>
                      <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#F07C00", color: "white" }}
                        onClick={props.openMidiaDays}
                      >
                        Exibição Semanal
                      </Button>
                    </div>
                  </Box>
                  <FormControl variant="outlined">
                    <Select
                      className={classes.nameBox}
                      native
                      value={cityState}
                      onChange={(e) => fetchCities(e.target.value)}
                    >
                      <option value="">Selecione o Estado*</option>
                      {cityStates.map((sta, ind) => (
                        <option key={ind} value={sta.sigla}>
                          {sta.estado}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <Autocomplete
                    value={city}
                    options={cities}
                    getOptionLabel={(option) => option || ""}
                    loading={isLoading}
                    onChange={(event, value) => {
                      setCity(value);
                    }}
                    className={classes.nameBox}
                    style={{
                      display: "flex",
                      flex: 1,
                      marginTop: "16px",
                      marginBottom: "8px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cidade"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </>
              )}

              {props.type === "news" && (
                <>
                  <Box width={"60%"} style={{ marginBottom: 30 }}>
                    <FormControl style={{ width: 490 }}>
                      <Typography variant="h3">Período :</Typography>
                      <div style={{ display: "flex", marginBottom: 15 }}>
                        <TextField
                          label="Inicio"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{ width: 150, marginRight: 15 }}
                        />
                        <TextField
                          label="Término"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          type="date"
                          variant="outlined"
                          size="small"
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{ width: 150 }}
                        />
                      </div>
                    </FormControl>
                    <div>
                      <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#F07C00", color: "white" }}
                        onClick={props.openMidiaDays}
                      >
                        Exibição Semanal
                      </Button>
                    </div>
                  </Box>
                  <TableContainer className={classes.table}>
                    <Table size="small" aria-label="Tabela de noticias">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>NOME</StyledTableCell>
                          <StyledTableCell>CATEGORIA</StyledTableCell>
                          <StyledTableCell>AÇÕES</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.newList.map((item) => (
                          <TableRow key={item.not_id}>
                            <StyledTableCell component="th" scope="row">
                              {item.not_nome}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {item.not_categoria}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Radio
                                checked={selectedNews == item.not_id}
                                onChange={(e) => {
                                  setSelectedNews(e.target.value);
                                  setSelectedNewsCategory(
                                    item.not_categoria.toLowerCase()
                                  );
                                }}
                                value={item.not_id}
                                name="radio-btn"
                                color="primary"
                              />
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {props.type === "grid" &&
                (viewGrid === false ? (
                  <>
                    <PageTitle
                      className={classes}
                      title="GRADE DE PROGRAMAÇÃO"
                    />
                    <TableContainer className={classes.tableGrid}>
                      <Table
                        size="small"
                        aria-label="Tabela de grades de programação"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>NOME</StyledTableCell>
                            <StyledTableCell>CATEGORIA</StyledTableCell>
                            <StyledTableCell>QTD MÍDIAS</StyledTableCell>
                            <StyledTableCell>DURAÇÃO</StyledTableCell>
                            <StyledTableCell>STATUS</StyledTableCell>
                            <StyledTableCell>AÇÕES</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.grid.map((item) => (
                            <TableRow key={item.grp_id}>
                              <StyledTableCell component="th" scope="row">
                                {item.grp_nome}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item.cat_nome}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item.conteudos.length}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item.grp_duracao}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item.grp_status === 1 ? "Ativo" : "Desativado"}
                              </StyledTableCell>
                              <StyledTableCell>
                                <IconButton
                                  onClick={() => saveGrid(item.conteudos)}
                                >
                                  <GetAppIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => openGrid(item.conteudos)}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <>
                    <PageTitle
                      className={classes}
                      title="GRADE DE PROGRAMAÇÃO"
                    />
                    <Box className={classes.mediaGrid}>
                      <CardList
                        obj={selectedGrid}
                        className={classes.imageGridCard}
                        selectedClassName={classes.imageGridSelected}
                        flippable={true}
                        selectable={false}
                        displayButtons={false}
                      />
                    </Box>
                    <Box className={classes.button}>
                      <Button
                        disableElevation
                        size="large"
                        variant="outlined"
                        onClick={closeGrid}
                        style={{ marginRight: "15px" }}
                      >
                        VOLTAR
                      </Button>

                      <Button
                        disableElevation
                        size="large"
                        variant="outlined"
                        onClick={importSelectedGrid}
                      >
                        IMPORTAR
                      </Button>
                    </Box>
                  </>
                ))}

              {props.type !== "grid" && (
                <Box className={classes.button}>
                  <Button
                    disableElevation
                    size="large"
                    variant="outlined"
                    onClick={saveMedia}
                  >
                    {props.type === "upload" && "SALVAR NOVA MÍDIA"}
                    {props.type === "weather" && "SALVAR CLIMA"}
                    {props.type === "news" && "SALVAR NOTICIA"}
                    {props.type === "media-grid" && "IMPORTAR"}
                  </Button>
                </Box>
              )}
            </>

            <IconButton
              className={classes.close}
              aria-label="close"
              onClick={cleanVariablesAndClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Fade>
      </Modal>
      <Loader display={startLoader} />
    </div>
  );
}
