import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTitle from 'src/components/PageTitle';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';
import getCep from 'src/services/cep';
import unmask from 'src/services/unmask-values';
import Loader from 'src/components/Loader';
import { validCNPJ, validEmail } from 'src/services/validDocument';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '760px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '60px',
    marginBottom: '60px'
  }
}));

const NewClientView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [startLoader, setStartLoader] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  const [currentCep, setCurrentCep] = useState('');
  const [responsibles, setResponsibles] = useState([]);
  const [responsible, setResponsible] = useState({});

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      cnpj: '',
      responsavel: null,
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: ''
    }
  });

  const handleSave = () => {
    let values = formik.values;
    const cnpjIsValid = validCNPJ(unmask(values.cnpj));

    if(!validEmail(values.email)) return openErrorAlert('Insira um email válido.');
    if(!values.nome) return openErrorAlert('Insira a Razão Social do cliente.');
    if(!values.email) return openErrorAlert('Insira o e-mail do cliente.');
    if(!values.cnpj) return openErrorAlert('Insira o CNPJ do cliente.');
    if(!cnpjIsValid) return openErrorAlert('Insira um cnpj válido.');
    if(!responsible?.usr_id) return openErrorAlert('Selecione o responsável.');
    if(!values.cep) return openErrorAlert('Insira o CEP.');
    if(!values.endereco) return openErrorAlert('Insira o endereço');
    if(!values.numero) return openErrorAlert('Insira o número do endereço.');
    if(!values.bairro) return openErrorAlert('Insira o bairro.');
    if(!values.cidade) return openErrorAlert('Insira a cidade.');
    if(!values.uf) return openErrorAlert('Insira o estado.');

    setStartLoader(true);

    let obj = {
      "cli_razaoSocial": values.nome,
      "cli_email": values.email,
      "cli_cnpj": unmask(values.cnpj),
      "cli_usr_id_responsavel": responsible.usr_id,
      "end_cep": unmask(values.cep),
      "end_endereco": values.endereco,
      "end_numero": values.numero.toString(),
      "end_complemento": values.complemento,
      "end_bairro": values.bairro,
      "end_cidade": values.cidade,
      "end_estado": values.uf,
      "end_pais": "Brasil"
    }

    api.post('/cliente', obj)
      .then((resp) => {
        setStartLoader(false);
        openSuccessAlert();
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Cliente criado com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    if(type !== 'error') navigate('/app/clientes');
  };

  const checkCep = (event) => {
    setCurrentCep(event.target.value);
    if(event.target.value.replace(/_/g, '').length == 9) {
      getCep(unmask(event.target.value))
        .then((resp) => {
          if (resp !== undefined && !resp.erro) {
            formik.setFieldValue('cep', event.target.value);
            formik.setFieldValue('endereco', resp.logradouro);
            formik.setFieldValue('bairro', resp.bairro);
            formik.setFieldValue('cidade', resp.localidade);
            formik.setFieldValue('uf', resp.uf);
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  const backPage = () => {
    const url = `/app/clientes`;
    
    navigate(url);
  }

  useEffect(() => {
    api.get(`/user/tipo/cliente/all`)
      .then((resp) => {
        setResponsibles(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }, [setResponsibles]);

  return (
    <Page className={classes.root} title="Novo Cliente">
      <PageTitle title="NOVO CLIENTE" />
      <Container className={classes.container}>
        <Box className={classes.fullBox}>
          <TextField
            className={classes.field}
            fullWidth
            label="Razão Social"
            margin="normal"
            name="nome"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.nome}
            variant="outlined"
            required
          />

          <TextField
            className={classes.field}
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.email}
            variant="outlined"
            required
          />

          <Box style={{ display: 'flex' }}>
            <InputMask 
              mask={'99999999/9999-99'}
              value={formik.values.cnpj}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              disabled={false}
            >
              <TextField
                style={{ display: 'flex', flex: 1 }}
                className={classes.field}
                label="CNPJ"
                margin="normal"
                name="cnpj"
                type="text"
                variant="outlined"
                required
              />
            </InputMask>

            <Autocomplete
              value={responsible}
              options={responsibles}
              getOptionLabel={(option) => option.usr_nome /*|| option.cli_razaoSocial*/ || ''}
              onChange={(event, value) => {
                setResponsible(value);
              }}
              style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px' }}
              renderInput={(params) => <TextField {...params} label="Nome do responsável" variant="outlined" />}
            />
          </Box>
          
          <InputMask 
            mask={'99999-999'}
            value={currentCep}
            onChange={(event) => checkCep(event)}
            disabled={false}
          >
            <TextField
              className={classes.field}
              label="CEP"
              margin="normal"
              name="cep"
              type="text"
              variant="outlined"
              
            />
          </InputMask>

          <Box style={{ display: 'flex' }}>
            <TextField
              style={{ display: 'flex', flex: 1 }}
              className={classes.field}
              label="Endereço"
              margin="normal"
              name="endereco"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.endereco}
              variant="outlined"
            />

            <TextField
              label="Número"
              margin="normal"
              name="numero"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="number"
              value={formik.values.numero}
              variant="outlined"
            />
          </Box>

          <Box style={{ display: 'flex' }}>
            <TextField
              className={classes.field}
              label="Complemento"
              margin="normal"
              name="complemento"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.complemento}
              variant="outlined"
            />

            <TextField
              style={{ display: 'flex', flex: 1 }}
              label="Bairro"
              margin="normal"
              name="bairro"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.bairro}
              variant="outlined"
            />
          </Box>

          <Box style={{ display: 'flex' }}>
            <TextField
              style={{ display: 'flex', flex: 1 }}
              className={classes.field}
              label="Cidade"
              margin="normal"
              name="cidade"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.cidade}
              variant="outlined"
            />

            <TextField
              label="UF"
              margin="normal"
              name="uf"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.uf}
              variant="outlined"
            />
          </Box>
        </Box>
      
        <Box className={classes.buttonBox}>
          <Button
            disableElevation
            size="large"
            variant="outlined"
            onClick={handleSave}
          >
            Salvar
          </Button>
          <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{marginLeft: 5, backgroundColor: '#F07C00', color: 'white'}}
              >
                Cancelar
              </Button>
        </Box>
      </Container>

      <Loader display={startLoader} />
      <AlertDialog open={openAlert} handleClose={() => handleAlertClose(alertType)} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default NewClientView;
