import React from 'react';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';

const Loader = (props) => {
  return (
    <>
    {
      props.display && (
        <Box style={{ 
            display: 'flex', 
            flex: 1, 
            justifyContent: 'center', 
            alignItems: 'center', 
            position: 'absolute', 
            top: 0, 
            bottom: 0, 
            right: 0, 
            left: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000000
          }}>
          <CircularProgress color="secondary" style={{ width: '80px', height: '80px' }} />
        </Box>
      )
    }
    </>
  )
}

export default Loader;