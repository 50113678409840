import { Fade, Modal, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    width: "45vw",
    height: "35vh",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(10),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    minWidth: "400px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    minWidth: "310px",
    flexWrap: "wrap",
  },
  confirmButton: {
    backgroundColor: "#F07C00",
    color: "white",
    width: "150px",
    padding: "10px",
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
  declineButton: {
    backgroundColor: "gray",
    color: "white",
    width: "150px",
    padding: "10px",
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
}));
export default function ConfirmActionModal(props) {
  const classes = useStyles();
  return (
    <Modal
      disableEnforceFocus
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      className={classes.modal}
      onClose={() => {
        props.close();
      }}
    >
      <Fade
        in={props.open}
        style={{
          paddingTop: "3%",
        }}
      >
        <div className={classes.paper}>
          <div>
            <Typography variant="h2">{props.message ?? 'Deseja confirmar essa ação ?'}</Typography>
          </div>
          <div className={classes.buttons}>
            <div className={classes.confirmButton} onClick={props.confirm}>
              <Typography variant="body1">SIM</Typography>
            </div>
            <div className={classes.declineButton} onClick={props.close}>
              <Typography variant="body1">NÃO</Typography>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
