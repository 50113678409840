import api from "src/services/api";

export const TOKEN_KEY = "@storage_Key";

export const getToken = async () => {
  try {
    const value = await localStorage.getItem(TOKEN_KEY);
    if (value !== null) {
      return value;
    }
    return null;
  } catch (e) {
    return e;
  }
};

export const isSignedIn = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token !== null;
};

export const setToken = (value) => {
  try {
    localStorage.setItem(TOKEN_KEY, value);
    return true;
  } catch (e) {
    // save error
  }
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const postLogin = async (obj) => {
  return api
    .post("/authenticate", obj)
    .then((response) => {
      const resp = response;

      if (
        resp.status === 200 &&
        !Object.prototype.hasOwnProperty.call(resp.data, "error")
      ) {
        if (resp.data.result.token !== undefined) {
          let loaded = setToken(resp.data.result.token);
          if (loaded) {
            resp.data.result.status = 200;
            return resp.data.result;
          }
        }
        return { status: false };
      }

      if (
        resp.status === 200 &&
        Object.prototype.hasOwnProperty.call(resp.data, "error")
      ) {
        return { status: false };
      }

      return { status: false };
    })
    .catch((error) => {
      return { status: false, message: error.response.data.message };
    });
};
