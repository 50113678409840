import React from 'react';
import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    minWidth: '336px',
    maxWidth: '40vw',
    height: '48px',
    whiteSpace: "nowrap",
    paddingLeft: 20,
    backgroundColor: '#F07C00',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    color: '#FFFFFF',
    paddingRight: '16px'
  },
  count: {
    fontSize: 32,
    fontWeight: 600,
    color: '#FFFFFF',
    paddingRight: '16px'
  },
}));

const PageTitle = ({ title, count, className }) => {
  const classes = useStyles();
  const externalClasses = className;

  return (
    <Box className={externalClasses ? externalClasses.styledTitleBox : classes.box}>
      <Typography className={externalClasses ? externalClasses.styledTitle : classes.title}>{title}</Typography>
      <Typography className={classes.count}>{count}</Typography>
    </Box>
  );
};

export default PageTitle;