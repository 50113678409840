// AUTH
export const login = (value) => ({
  type: 'LOGIN',
  login: value
});

export const logoutState = (value) => ({
  type: 'LOGOUT',
  logout: value
});

export const user = (value) => ({
  type: 'USER',
  payload: value
});

// REPORT
export const reportState = (value) => ({
  type: 'UPDATE',
  payload: value
});

export const resetReport = () => ({
  type: 'RESET'
});
