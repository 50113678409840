import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio, 
  RadioGroup,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import Page from 'src/components/Page';
import PageTitle from 'src/components/PageTitle';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';
import Loader from 'src/components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '760px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '60px',
    marginBottom: '60px'
  }
}));

const NewPlayerView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  const [lotteries, setLotteries] = useState([]);
  const [lottery, setLottery] = useState({});

  const [startLoader, setStartLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      posicao: 'paisagem',
      a1botao: '',
      a1duracao: '00:00:15',
      a2botao: '',
      a2duracao: '00:00:15',
      a3botao: '',
      a3duracao: '00:00:15',
      codigo: ''
    }
  });

  const handleVerifyBtnTime = (btnDurationId) => {
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
    const time = formik.values[btnDurationId];
    const timeDto = { ...formik.values };

    if(
        timeRegex.test(time) && time !== "00:00:00"
      ) {
        const [hour, minutes, seconds] = time.split(":").map(Number);

        if (!(hour >= 0 && hour <= 23 && minutes >= 0 && minutes <= 59 && seconds >= 0 && seconds <= 59)) {
            timeDto[btnDurationId] = '00:00:15';
            
            formik.setValues(timeDto);
        }
    } else {
      timeDto[btnDurationId] = '00:00:15';
            
      formik.setValues(timeDto);
    }
  }

  const handleSave = () => {
    let values = formik.values;

    if(!values.nome) return openErrorAlert('Insira o nome do player.');
    if(!lottery.lot_id) return openErrorAlert('Selecione uma lotérica.');
    if(!values.codigo) return openErrorAlert('Insira o código do player.');

    setStartLoader(true);

    let obj = {
      "pla_nome": values.nome,
      "pla_posicao": values.posicao,
      "pla_a1Botao": values.a1botao === '' ? null : values.a1botao,
      "pla_a1Duracao": values.a1duracao === '' ? null : values.a1duracao,
      "pla_a2Botao": values.a2botao === '' ? null : values.a2botao,
      "pla_a2Duracao": values.a2duracao === '' ? null : values.a2duracao,
      "pla_a3Botao": values.a3botao === '' ? null : values.a3botao,
      "pla_a3Duracao": values.a3duracao === '' ? null : values.a3duracao,
      "pla_codigo": values.codigo,
      "pla_lot_id": lottery.lot_id,
      "pla_status": 1,
      "pla_bloqueado": null
    }

    api.post('/player', obj)
      .then((resp) => {
        openSuccessAlert();
        setStartLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setStartLoader(false)
        openErrorAlert();
      })
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Player criado com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = (type) => {
    setOpenAlert(false);
    if(type !== 'error') navigate('/app/players');
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split('/')[1]}/players`;
    
    navigate(url);
  }

  useEffect(() => {
    api.get(`/loterica`)
      .then((resp) => {
        setLotteries(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }, [setLottery]);

  return (
    <Page className={classes.root} title="Novo Player">
      <PageTitle title="PLAYER" />
      <Container className={classes.container}>
        <Box className={classes.fullBox}>
          <Box style={{ display: 'flex' }}>
            <TextField
              style={{ display: 'flex', flex: 1 }}
              className={classes.field}
              fullWidth
              label="Nome"
              margin="normal"
              name="nome"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.nome}
              variant="outlined"
              required
            />

            <FormControl component="fieldset" style={{marginTop: 20}}>
              <FormLabel component="legend">Selecione o tipo de mídia</FormLabel>
              <RadioGroup row aria-label="posicao" name="posicao" value={formik.values.posicao} onChange={formik.handleChange}>
                <FormControlLabel value="paisagem" control={<Radio />} label="Paisagem" />
                <FormControlLabel value="retrato" control={<Radio />} label="Retrato" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Autocomplete
            value={lottery}
            options={lotteries}
            getOptionLabel={(option) => option.lot_razaoSocial || ''}
            onChange={(event, value) => {
              setLottery(value);
            }}
            style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px' }}
            renderInput={(params) => <TextField {...params} label="Lotérica" variant="outlined" />}
          />

          <TextField
            className={classes.field}
            fullWidth
            label="Código"
            margin="normal"
            name="codigo"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.codigo}
            variant="outlined"
            required
          />

          <Box style={{ display: 'flex' }}>
            <TextField
              style={{ display: 'flex', flex: 1 }}
              className={classes.field}
              label="Botão A/1"
              margin="normal"
              name="a1botao"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.a1botao}
              variant="outlined"
            />

            <InputMask 
              mask={'99:99:99'}
              value={formik.values.a1duracao}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onBlurCapture={() => handleVerifyBtnTime("a1duracao")}
              disabled={false}
            >
              <TextField
                style={{ display: 'flex', flex: 1 }}
                label="Duração"
                margin="normal"
                name="a1duracao"
                type="text"
                variant="outlined"
              />
            </InputMask>
          </Box>
          
          <Box style={{ display: 'flex' }}>
            <TextField
              style={{ display: 'flex', flex: 1 }}
              className={classes.field}
              label="Botão A/2"
              margin="normal"
              name="a2botao"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.a2botao}
              variant="outlined"
            />

            <InputMask 
              mask={'99:99:99'}
              value={formik.values.a2duracao}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              disabled={false}
              onBlurCapture={() => handleVerifyBtnTime("a2duracao")}
            >
              <TextField
                style={{ display: 'flex', flex: 1 }}
                label="Duração"
                margin="normal"
                name="a2duracao"
                type="text"
                variant="outlined"
              />
            </InputMask>
          </Box>

          <Box style={{ display: 'flex' }}>
            <TextField
              style={{ display: 'flex', flex: 1 }}
              className={classes.field}
              label="Botão A/3"
              margin="normal"
              name="a3botao"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.a3botao}
              variant="outlined"
            />

            <InputMask 
              mask={'99:99:99'}
              value={formik.values.a3duracao}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onBlurCapture={() => handleVerifyBtnTime("a3duracao")}
              disabled={false}
            >
              <TextField
                style={{ display: 'flex', flex: 1 }}
                label="Duração"
                margin="normal"
                name="a3duracao"
                type="text"
                variant="outlined"
              />
            </InputMask>
          </Box>
        </Box>
      
        <Box className={classes.buttonBox}>
          <Button
            disableElevation
            size="large"
            variant="outlined"
            onClick={handleSave}
          >
            Salvar
          </Button>
          <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{marginLeft: 5, backgroundColor: '#F07C00', color: 'white'}}
              >
                Cancelar
              </Button>
        </Box>
      </Container>

      <Loader 
        display={startLoader}
      />     
      <AlertDialog open={openAlert} handleClose={() => handleAlertClose(alertType)} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default NewPlayerView;
