import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactCardFlip from "react-card-flip";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ImageIcon from "@material-ui/icons/Image";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";

import PlaceholderImage from "../assets/placeholder-image.jpg";
import daysWeek from "src/utils/daysWeek";
import api, { apiUrl } from "../services/api";
import weatherImages from "src/utils/weatherImages";

import WeatherBg from "../assets/weather-bg.webp"
import noticiasPlaceholder from "src/utils/noticiasPlaceholder";

import HourglassFullIcon from "@material-ui/icons/HourglassFull";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: 287,
    height: 161,
    "& h2": {
      fontSize: "10px",
      color: "#5E5E5E",
      paddingBottom: 2,
      "& p": {
        fontSize: "14px",
        marginTop: 18,
        transform: "translatey(-5px)",
        "& svg": {
          width: 18,
        },
      },
    },
  },
  lignText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& p" : {
      paddingLeft: "10px",
    },
  },
  media: {
    display: "flex",
    flexDirection: "row",
    width: 287,
    height: 161,
    borderRadius: 0,
    cursor: "pointer",
  },
  cardAction: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "14%",
    backgroundColor: "#c4c4c4",
    "& button": {
      cursor: "pointer",
      borderRadius: 0,
      border: "none",
      width: 50,
      height: 50,
      margin: theme.spacing(1),
      backgroundColor: "transparent",
      "& svg": {
        fontSize: "2rem",
      },
    },
  },
  cardMedia: {
    height: "100%",
    width: "100%"
  },
  time: {
    display: "flex",
    flexDirection: "row",
  },
  type: {
    "& svg": {
      transform: "translatey(6px)",
      marginRight: 7,
    },
  },
  label: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 41,
    height: 186,
    backgroundColor: "#F07C00",
    color: "#FFFF",
  },
  presentationBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerLabel: { 
    color: "#5E5E5E", 
    fontSize: ".8rem", 
    marginBottom: 12,
    textAlign: "center",
  },
  row: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 6,
    "p": {
      display: "flex",
      alignItems: "flex-end"
    },
    "& label": {
      marginRight: 8,
      fontSize: 6
    }
  },
  cardContent: {
    paddingTop: 6, 
    height: "100%" 
  }
}));

const MediaCard = (props) => {
  var imgUrl = apiUrl;
  const classes = useStyles();

  const [open, setOpen] = useState({ isFlipped: false });
  const [image, setImage] = useState("");
  const [videoError, setVideoError] = useState(false);

  const today = daysWeek[moment().day()].dia;
  
  const todayPresentation = props?.presentation?.find(day => day.exc_dia_semana === today);

  useEffect(() => {
    if(props.type === "clima") {
      handleWeatherInfo()
    } 
  }, [props.type]);

  useEffect(() => {
    if (props.image || props.type === "noticia" || props.type === "video") {
      //[RND]
      if (props.image.includes('dynamic-image')){imgUrl = "";}      
      const url = `${props.type === "noticia" ? `${image}` : `${imgUrl}${props.image}`}`;
      if(props.type === "video") console.log(url);

      var img = new Image();
      img.src = url;
      img.onload = () => {
        if(props.type === "video") console.log("setImage URL");
        setImage(url);
      };
      img.onerror = (err) => {
        if(props?.type === "noticia" && props?.category) {
          setImage(noticiasPlaceholder[props?.category][0])

          return
        }
        if(props.type === "video") console.log("setImage PLACEHOLDER");
        setImage(PlaceholderImage);
      };
    }

    return () => {
      setImage("");
    };
  }, [props.image]);

  const handleClick = (isOpen) => {
    setOpen(isOpen);
  };

  const returnImage = () => {
    return PlaceholderImage;
  };
  
  const handleVideoError = () => {
    setVideoError(true);
  }

  const handleWeatherInfo = async () => {
    if(props.cidade && props.uf) {
      await api.get(`/clima/tempo?cidade=${props.cidade}&uf=${props.uf}`)
        .then(resp => {
          const weather = resp.data?.result;
  
          if(!weather || !weather.cli_condition) {
            setImage(PlaceholderImage);
            return
          }
          setImage(weatherImages[weather.cli_condition]);
        })
        .catch(err => setImage(PlaceholderImage));
    } else {
      setImage(PlaceholderImage)
    }
  }
  console.log()
  return (
    <div
      onMouseEnter={() => handleClick(false)}
      onMouseLeave={() => handleClick(true)}
    >
      <ReactCardFlip
        flipSpeedBackToFront={0.5}
        flipSpeedFrontToBack={0.5}
        isFlipped={!open}
        className={classes.root}
      >
        <Card 
          className={classes.media} 
        >
          {props.leftLabel && (
            <Box className={classes.label}>
              <Typography variant="h2" component="h2" className={props.fadeProps}>
                <p>{props.labelNumber + 1}</p>
              </Typography>
            </Box>
          )}

          {props.type === 'video' && !props.mul_processamento_concluido ? (
            <>
              <HourglassFullIcon style={{
                position: 'absolute',
                color: '#FFFFFF',
                top: 6,
                left: 6,
              }}></HourglassFullIcon>
            </>
          ) : null}

          <Box style={{ width: "100%"}}>
            {props.image && props.image.includes("mp4") ? (
              <>
                {
                  videoError ? (
                      <CardMedia
                        id={props.id}
                        className={classes.cardMedia}
                        component="img"
                        src={returnImage()}
                      />
                    ) : (
                      <CardMedia
                        id={props.id}
                        component="img"
                        className={classes.cardMedia}
                        src={image}
                      />
                  )
                }
              </>
            ) : (
              <>
                <CardMedia
                  id={props.id}
                  className={classes.cardMedia}
                  component="img"
                
                  src={image}
                  style={{ 
                    backgroundImage: props.type === "clima" ? `url(${WeatherBg})` : "", 
                    objectFit: props.type === "clima" && image != PlaceholderImage ? "contain" : ""
                  }}
                />
              </>
            )}
          </Box>
        </Card>

          <Card className={classes.root}>
              
            <CardActions className={classes.cardAction}>

              {!props.dragAndDrop && !props.withoutButtons && (
                <button onClick={props.onOpen}>
                  <VisibilityIcon style={{ color: "#5E5E5E" }} />
                </button>
              )}

              {!props.onlyDisplay && !props.disableEdit && (
                <button onClick={props.onEdit}>
                  <EditIcon style={{ color: "#5E5E5E" }} />
                </button>
              )}

              {!props.onlyDisplay && (
                <button onClick={props.onDelete}>
                  <DeleteIcon style={{ color: "#5E5E5E" }} />
                </button>
              )}
            </CardActions>

            <CardActionArea >
              <CardContent className={classes.cardContent}>
                {props.disableEdit ? (
                    <Typography 
                      className={classes.headerLabel}
                    >
                      -- Arraste a mídia para ordenar --
                    </Typography>
                  ) : null}

                {props.name && (
                  <div className={classes.row}>
                    <label>
                      <Typography variant="body2">
                        NOME:
                      </Typography>
                    </label>
                    <Typography variant="body2">
                       {props.name}
                    </Typography>
                  </div>
                )}

                {props.type && (
                  <div className={classes.row}>
                    <label>
                      <Typography variant="body2">
                        TIPO:
                      </Typography>
                    </label>
                    <Typography variant="body2">
                        {props.type === "video" ? <PlayArrowIcon  style={{fontSize: 12}}/> : <ImageIcon style={{fontSize: 12}} />}{" "}
                        {props.type.toUpperCase()}
                    </Typography>
                  </div>
                )}

                {props.category && (
                  <div className={classes.row}>
                    <label>
                      <Typography variant="body2">
                        CATEGORIA: 
                      </Typography>
                    </label>
                    <Typography variant="body2">
                      {props.category}
                    </Typography>
                  </div>
                )}

                <div className={classes.presentationBox}>
                  {props.duration && (
                    <div className={classes.row}>
                      <label>
                        <Typography variant="body2">
                          DURAÇÃO:
                        </Typography>
                      </label>
                      <Typography variant="body2">
                        {props.duration}
                      </Typography>
                    </div>
                  )}

                </div>
                {props.presentation && (
                  <div className={classes.time}>
                    <div className={classes.row}>
                      <label> 
                          <Typography variant="body2">
                            HORARIO:
                          </Typography>
                      </label>
                      <Typography variant="body2">
                        {todayPresentation?.exc_horaInicio && todayPresentation?.exc_horaFim ? (
                          <>
                            {
                              todayPresentation.exc_horaInicio
                            }{" - "}
                            {
                              todayPresentation.exc_horaFim
                            }
                          </>
                        ) : todayPresentation?.exc_dia_semana ? (
                          <p>Exibindo o tempo todo.</p>
                        ) : (
                          <p>Sem exibição hoje</p>
                        )}
                      </Typography>
                    </div>

                  </div>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
      </ReactCardFlip>
    </div>
  );
};

export default MediaCard;
