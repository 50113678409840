import { createContext, useContext, useState } from "react";

const SocketContext = createContext({});

export function SocketProvider(props) {
  const [playersConnected, setPlayersConnected] = useState([53]);

  function handlePlayersConnected(player) {
    const checkPlayersList = playersConnected?.filter((p) => p == player);

    if (checkPlayersList?.length === 0) {
      setPlayersConnected([...playersConnected, player]);
    }
  }

  function handlePlayersDisconnected(player) {
    if (playersConnected.length > 0) {
      const arr = playersConnected.filter((p) => p != player);
      
      setPlayersConnected(arr);
    }
  }

  return (
    <SocketContext.Provider
      value={{
        playersConnected,
        handlePlayersConnected,
        handlePlayersDisconnected,
        setPlayersConnected,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
}

export function useSocket() {
  const ctx = useContext(SocketContext);
  return ctx;
}
