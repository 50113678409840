import { defineState } from 'redux-localstore';

const defaultState = {
  isAuthenticated: false,
  user: {}
};

const initialState = defineState(defaultState)('auth');

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: action.login
      };
    case 'LOGOUT':
      return {
        isAuthenticated: action.logout,
        user: {}
      };
    case 'USER':
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

export default auth;
