import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import LockIcon from "@material-ui/icons/Lock";
import plugIconRed from 'src/assets/plugIconRed.svg';
import plugIconGreen from 'src/assets/plugIconGreen.svg';
/*[RND] - Deletar players*/
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import AlertDialog from "src/components/AlertDialog";
import api, { apiUrl } from "src/services/api";
import capitalizeFirstLetter from "src/services/capitalizeFirstLetter";
import { Skeleton } from "@mui/material";

/*[RND] - Deletar players*/
import Modal from "src/components/Modal";
import Loader from "src/components/Loader";
import Popup from 'reactjs-popup';

import ConfirmActionModal from "src/components/ConfirmActionModal";
/*[RND] - Deletar players*/

import { getToken } from "src/services/auth";
import { io } from "socket.io-client";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgfloorColor: "#F5F5F5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 16,
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  searchBox: {
    marginRight: "210px",
    width: "326px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: 48,
    maxWidth: "1980px",
  },
  table: {
    minWidth: 700,
  },
  green: {
    color: "#009C6D",
  },
  red: {
    color: "#E6202A",
  },
  icon: {
    width: '15px',
    color: '#E6202A'

  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 37,
    width: "100%",
    height: "100%",
    "& 	.MuiSkeleton-root": {
      maxWidth: "1480px",
      padding: 0,
    },
  },
}));

const PlayerListView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const me = useSelector((state) => state.auth.user);

  const [isLoaded, setIsLoaded] = useState(false);
  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState({ playerId: null, status: null });

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [playersConnected, setPlayersConnected] = useState([]);
  const [lastPlayerInteraction, setLastPlayerInteraction] = useState({});

  /*[RND]*/
  const [modalType, setModalType] = useState("");
  const [modalEditType, setModalEditType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false); 
  const [clientActive, setClientActive] = useState(null); 
  const [startLoader, setStartLoader] = useState(false);

  const handleOpenDelete = (media) => {
    setClientActive(media);
    setOpenModal(true);
    setConfirmModal(!confirmModal);
  }
  const handleCloseDelete = () => {
    setClientActive(null);
    setConfirmModal(!confirmModal);
  }  
  /*[RND]*/      

  const getListPlayers = (() => {
    api
      .get("/player")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setPlayers(resp.data.result);
          console.log("getListPlayers", resp.data.result)
          setRows(resp.data.result);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert();
      });
  });

  const getPlayerId = (player) => {
    players.map((element) => {
      return element.pla_id === player.playerId ? element.conexao = (player.status === "playerDisconnected" ? "Desativado" : "Ativo") : null
    })
    console.log(moment(players[0].pla_ultimaSincronizacao).format())
    console.log(moment(rows[0].pla_ultimaSincronizacao).format())
    console.log("getPlayerId")
    setRows(players);
    console.log(moment(players[0].pla_ultimaSincronizacao).format())
    console.log(moment(rows[0].pla_ultimaSincronizacao).format())
  }

  useEffect(() => {
    if (player.playerId && player.status) {
      getPlayerId(player);
      setPlayer({ player: null, status: "null" })
    }
  }, [player]);

  useEffect(() => {
    if (lastPlayerInteraction.type === "connect") {
      handlePlayersConnected(lastPlayerInteraction.id);
    } else {
      handlePlayersDisconnected(lastPlayerInteraction.id);
    }
  }, [lastPlayerInteraction]);

  useEffect(() => {
    if (rows.length) {
      const timerId = setInterval(AtualizarUltimaVisualizacao, 1000);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [rows.length])

  const AtualizarUltimaVisualizacao = () => {
    var pl = []
    rows.forEach((element) => {
      var data = moment()
      if (element.conexao === 'Ativo' && Math.floor(((data.diff(element?.pla_ultimaSincronizacao) / 1000)) / 60) > 0) {
        element.pla_ultimaSincronizacao = moment(element?.pla_ultimaSincronizacao).add(Math.floor(((data.diff(element?.pla_ultimaSincronizacao) / 1000))), 'seconds')
      }
      pl.push(element)
    });
    console.log("AtualizarUltimaVisualizacao")
    setRows(pl);
  }

  function handlePlayersConnected(player) {
    const checkPlayersList = playersConnected?.filter((p) => p == player);

    if (checkPlayersList?.length === 0) {
      setPlayersConnected([...playersConnected, player]);
    }
  }

  function handlePlayersDisconnected(player) {
    const arr = playersConnected.filter((p) => p != player);
    setPlayersConnected(arr);
  }

  const handleSession = (socket) => {
    let playerList = socket.players.map(
      (player) => player.connected == true && player.userID
    );
    setPlayersConnected(playerList);
  };

  const requestSearch = (e) => {
    let val = e.target.value;
    setSearched(val);

    if (val !== "") {
      const filteredRows = players.filter((row) => {
        return row.pla_nome.toLowerCase().includes(val.toLowerCase());
      });
      console.log("requestSearch if")
      setRows(filteredRows);
    } else {
      console.log("requestSearch else")
      setRows(players);
    }
  };

  useEffect(() => {
    StartSocket();

    return () => {
      setPlayersConnected([]);
    };
  }, []);

  const StartSocket = async () => {
    const token = await getToken();
    if (token) {
      const socket = io(apiUrl, {
        transports: ["websocket"],
        extraHeaders: {
          "x-access-token": token,
        },
        auth: {
          token,
        },
      });

      socket.on("session", handleSession);

      socket.on("playerConnected", (player) => {
        setLastPlayerInteraction({ id: player, type: "connect" })
        setPlayer({ playerId: player, status: "playerConnected" })
      });

      socket.on("playerDisconnected", (player) => {
        setLastPlayerInteraction({ id: player, type: "disconnect" })
        setPlayer({ playerId: player, status: "playerDisconnected" })
      });

      socket.on("playerFoiBloqueado", () => {
        getListPlayers()
      });

      socket.on("connect", () => {
        getListPlayers()
      });

      socket.on("disconnect", (reason) => {
        getListPlayers()
      });
    }
  };

  const newPlayer = () => {
    navigate("/app/novo-player");
  };

  const viewPlayer = (id) => {
    navigate(`/app/players/${id}`);
  };

  const changeStatus = (id) => {
    api
      .delete(`/player/status/${id}`)
      .then(() => {
        openSuccessAlert();
        setIsLoaded(false);
        window.location.reload();
      })
      .catch((err) => {
        openErrorAlert(err.response.data.message);
      });
  };

  /*[RND]*/
  const permanentDelete = (id) => {
    api
      .delete(`/player/${id}/deletepermanent`)
      .then((response) => {
        openSuccessAlert();
        alert(response);
        setIsLoaded(false);
        setOpenModal(false);
        setConfirmModal(!confirmModal);        
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const changeBloqStatus = (id) => {
    api
      .delete(`/player/bloqueia/${id}`)
      .then(async () => {
        openSuccessAlert();
        setIsLoaded(false);
        await window.location.reload();
      })
      .catch((err) => {
        openErrorAlert(err.response.data.message);
      });
  };

  const restartPlayer = (id) => {
    api
      .delete(`/player/restart/${id}`)
      .then(() => {
        openRestartAlert();
        setIsLoaded(false);
      })
      .catch((err) => {
        openErrorAlert(err.response.data.message);
      });
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Status atualizado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openRestartAlert = () => {
    setAlertText("Player reiniciado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (!isLoaded) {
      getListPlayers()
    }
  }, [isLoaded]);
  return (
    <Page className={classes.root} title="Players">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle title="PLAYERS" />
        <TextField
          className={classes.searchBox}
          label="O que deseja localizar?"
          margin="normal"
          name="busca"
          onChange={(e) => requestSearch(e)}
          type="text"
          value={searched}
          variant="outlined"
        />
      </Box>

      {isLoaded ? (
        <Container className={classes.container}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="Tabela de clientes"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>NOME</StyledTableCell>
                  <StyledTableCell>CLIENTE</StyledTableCell>
                  <StyledTableCell>LOTÉRICA</StyledTableCell>
                  <StyledTableCell>ULTIMA SINC</StyledTableCell>
                  <StyledTableCell>TIPO</StyledTableCell>
                  <StyledTableCell>CONEXÃO</StyledTableCell>
                  <StyledTableCell>STATUS</StyledTableCell>
                  <StyledTableCell>BLOQ.</StyledTableCell>
                  <StyledTableCell>AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((player) => (
                  <TableRow key={player.pla_id}>
                    <StyledTableCell component="th" scope="row">
                      {player.pla_nome}
                    </StyledTableCell>
                    <StyledTableCell>{player.cli_razaoSocial}</StyledTableCell>
                    <StyledTableCell>{player.lot_razaoSocial}</StyledTableCell>
                    {/* <StyledTableCell>
                      {player.pla_ultimaSincronizacao
                        ? moment(player.pla_ultimaSincronizacao).format(
                            "DD/MM/YY HH:mm"
                          )
                        : "-"}
                    </StyledTableCell> */}
                    <StyledTableCell>{player.pla_ultimaSincronizacao ? moment(player.pla_ultimaSincronizacao).format('DD/MM/YY HH:mm') : '-'}</StyledTableCell>
                    <StyledTableCell>
                      {capitalizeFirstLetter(player.pla_posicao)}
                    </StyledTableCell>
                    <StyledTableCell
                    >
                      <IconButton onClick={() => { }}>
                        {
                          player.conexao === 'Desativado' ?
                            <img src={plugIconRed} alt="logo desconected" className={classes.icon} />
                            :
                            <img src={plugIconGreen} alt="logo connected" className={classes.icon} />

                        }
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell
                      className={
                        player.pla_status === 1 ? classes.green : classes.red
                      }
                    >
                      {player.pla_status === 1 ? "Ativo" : "Desativado"}
                    </StyledTableCell>
                    <StyledTableCell
                      className={
                        player.pla_bloqueado === "liberado"
                          ? classes.green
                          : classes.red
                      }
                    >
                      {capitalizeFirstLetter(player.pla_bloqueado)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={() => changeBloqStatus(player.pla_id)}
                      >
                        <LockIcon />
                      </IconButton>

                      <IconButton onClick={() => restartPlayer(player.pla_id)}>
                        <SettingsBackupRestoreIcon />
                      </IconButton>

                      <IconButton onClick={() => changeStatus(player.pla_id)}>
                        {player.pla_status === 1 ? (
                          <CloseIcon />
                        ) : (
                          <CheckIcon />
                        )}
                      </IconButton>

                      <IconButton onClick={() => viewPlayer(player.pla_id)}>
                        <VisibilityIcon />
                      </IconButton>
                      {/*[RND] - Deletar Player*/}
                      {me.usr_tipoUsuario === "admin" && (
                      <IconButton onClick={() => handleOpenDelete(player.pla_id)}>
                      <DeleteIcon style={{ color: "#5E5E5E" }} />
                      </IconButton>      
                      )}                        
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {me.usr_tipoUsuario === "admin" && (
            <Fab color="secondary" className={classes.fab} onClick={newPlayer}>
              <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
            </Fab>
          )}
        </Container>
      ) : (
        <div className={classes.skeletonContainer}>
          <Skeleton height={50} width={"100%"} />
          <Skeleton
            width={"100%"}
            variant="rectangular"
            height={"calc(100% - 150px)"}
          />
        </div>
      )}

      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
      {/*[RND] - Deletar Player*/}
      <Loader display={startLoader} />
      <ConfirmActionModal
        message={'Deseja deletar essa Player? O Procedimento não poderá ser desfeito!'}
        open={confirmModal}
        confirm={() => permanentDelete(clientActive)}
        close={() => handleCloseDelete()}
      />  
      {/*[RND] - Deletar Player*/}        
    </Page>
  );
};

export default PlayerListView;
