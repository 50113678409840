import Card from 'src/components/Card';

const MediaCardList = (props) => {
  const list = props.obj;
  return (
    list.map((item, index) =>
      (
        <Card
          key={item.mul_id}
          id={item.mul_id}
          dragAndDrop={props.dragAndDrop}
          className={props.className}
          selectedClassName={props.selectedClassName}
          name={item.mul_nome}
          image={item.mul_thumb}
          type={item.mul_tipo}
          category={item.category}
          duration={item.duration}
          presentation={item.presentation}
          flippable={props.flippable}
          selectable={props.selectable}
          onSelect={props.onSelect}
          alreadySelected={props.alreadySelected}
          deselectCard={props.deselectCard}
          displayButtons={props.displayButtons}
          setDeselectCard={props.setDeselectCard}
          mulPath={item.mul_path}
        />
      )
    )
  )
}

export default MediaCardList;
