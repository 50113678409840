import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardList from "src/components/CardList";
import PageTitle from "src/components/PageTitle";
import ReportModal from "src/components/reportModal";
import api from "src/services/api";
import isEmpty from "src/services/check-empty-obj";
import { reportState } from "src/store/actions";
import Loader from "src/components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: -80,
    maxWidth: "80vw",
  },
  searchBox: {
    width: "326px",
  },
  inputField: {
    width: "20vw",
  },
  modalMidia: {
    width: "85vw",
    height: "55vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10vh",
  },
  modalClient: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalSize: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mediaCardList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflowY: "scroll",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: 20,
  },
  inputBox: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  buttonBox: {
    marginTop: "40px",
  },
  errorMessage: {
    position: "absolute",
    bottom: "25px",
    color: "red",
  },
  selectCard: {
    display: "flex",
    flexDirection: "row",
    width: 300,
    height: 170,
  },
  selectedCard: {
    display: "flex",
    flexDirection: "row",
    borderWidth: "2px",
    borderColor: "#F07C00",
    borderStyle: "solid",
    width: 300,
    height: 170,
  },
  styledTitleBox: {
    minWidth: "336px",
    maxWidth: "80vw",
    height: "48px",
    whiteSpace: "nowrap",
    paddingLeft: 20,
    backgroundColor: "#F07C00",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  styledTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#FFFFFF",
    paddingRight: "16px",
  },
}));

const ModalsReport = ({
  open,
  modalType,
  setOpen,
  data,
  clients,
  lotteries,
  fetchLotteries,
  players,
  fetchPlayers,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ReportDispatch = (value) => dispatch(reportState(value));

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [client, setClient] = useState({});
  const [lottery, setLottery] = useState({});
  const [player, setPlayer] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [alreadySelected, setAlreadySelected] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startLoader, setStartLoader] = useState(false);

  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [initialData, setInitialData] = useState([]);

  const requestSearch = (e) => {
    let val = e.target.value;
    setSearched(val);

    if (val !== "") {
      const filteredRows = initialData.filter((row) => {
        return row.mul_nome.toLowerCase().includes(val.toLowerCase());
      });

      setRows(filteredRows);
    } else {
      setRows(initialData);
    }
  };

  const closeModal = () => {
    setSelectedMedia(null);
    setClient({});
    setLottery({});
    setPlayer({});
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setMonth("");
    setYear("");
    setDisplayErrorMessage(false);
    setAlreadySelected(false);
    setOpen(false);
  };

  const changeClient = (client) => {
    setClient(client);

    if (client && client.cli_id) {
      fetchLotteries(client.cli_id);
    }
  };

  const changeLottery = (lottery) => {
    setLottery(lottery);

    if (lottery && lottery.lot_id) {
      fetchPlayers(lottery.lot_id);
    }
  };

  const selectMedia = (id) => {
    if (selectedMedia && selectedMedia == id) {
      setSelectedMedia(null);
      setAlreadySelected(false);
    } else {
      setSelectedMedia(id);
      setAlreadySelected(true);
    }
  };

  const checkParameter = (parameter, concat) => {
    switch (parameter) {
      case "data_inicio":
        if (startDate !== "") {
          return `data_inicio=${startDate}${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "hora_inicio":
        if (startTime !== "") {
          return `hora_inicio=${startTime}:00${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "data_fim":
        if (endDate !== "") {
          return `data_fim=${endDate}${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "hora_fim":
        if (endTime !== "") {
          return `hora_fim=${endTime}:00${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "lot_nome":
        if (
          lottery !== undefined &&
          lottery !== null &&
          !isEmpty(lottery) &&
          lottery.lot_razaoSocial
        ) {
          return `lot_nome=${lottery.lot_razaoSocial}${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "cli_razaoSocial":
        if (
          client !== undefined &&
          client !== null &&
          !isEmpty(client) &&
          client.cli_razaoSocial
        ) {
          return `cli_razaoSocial=${client.cli_razaoSocial}${concat ? "&" : ""
            }`;
        } else {
          return "";
        }
      case "mes":
        if (month !== "") {
          return `mes=${month}${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "ano":
        if (year !== "") {
          return `ano=${year}${concat ? "&" : ""}`;
        } else {
          return "";
        }
      case "pla_nome":
        if (
          player !== undefined &&
          player !== null &&
          !isEmpty(player) &&
          player.pla_nome
        ) {
          return `pla_nome=${player.pla_nome}${concat ? "&" : ""}`;
        } else {
          return "";
        }
    }
  };

  const validFilters = (report) => {
    if (report === "player-cliente") {
      if (month || year) {
        if (month && year) {
          return { valid: true };
        } else {
          return {
            valid: false,
            message:
              "Para filtrar por data é obrigatório selecionar o mês e ano do período.",
          };
        }
      } else {
        return { valid: true };
      }
    }
    if (startDate || startTime || endDate || endTime) {
      if (startDate && startTime && endDate && endTime) {
        return { valid: true };
      } else {
        return {
          valid: false,
          message:
            "Para filtrar por data é obrigatório selecionar os dados de inicio e fim do período.",
        };
      }
    } else {
      return { valid: true };
    }
  };

  const generateReport = (report) => {
    setStartLoader(true);
    setDisplayErrorMessage(false);

    let uri = "";

    console.log("SELECTEDMEDIA: ", selectedMedia);

    const filtersValid = validFilters(report);
    if (report === "reproducao-midia") {
      if (!selectedMedia) {
        setErrorMessage("É obrigatório selecionar uma mídia.");
        setDisplayErrorMessage(true);
        setStartLoader(false);
      } else {
        uri = `multimidia?${checkParameter(
          "data_inicio",
          true
        )}${checkParameter("hora_inicio", true)}${checkParameter(
          "data_fim",
          true
        )}${checkParameter("hora_fim", true)}${checkParameter(
          "lot_nome",
          true
        )}${checkParameter("cli_razaoSocial", true)}mul_id=${selectedMedia}`;
      }
    }

    if (report === "player-cliente") {
      uri = `players/cliente?${checkParameter(
        "cli_razaoSocial",
        true
      )}${checkParameter("mes", true)}${checkParameter("ano", false)}`;
    }

    if (report === "importacao-cef") {
      uri = `importacao/multimidia/caixa?${checkParameter(
        "data_inicio",
        true
      )}${checkParameter("hora_inicio", true)}${checkParameter(
        "data_fim",
        true
      )}${checkParameter("hora_fim", false)}`;
    }

    if (report === "reproducao-cef") {
      uri = `reproducao/multimidia/caixa?${checkParameter(
        "data_inicio",
        true
      )}${checkParameter("hora_inicio", true)}${checkParameter(
        "data_fim",
        true
      )}${checkParameter("hora_fim", true)}${checkParameter(
        "lot_nome",
        true
      )}${checkParameter("pla_nome", false)}`;
    }

    if (uri !== "") {
      if (!filtersValid.valid) {
        setErrorMessage(filtersValid.message);
        setDisplayErrorMessage(true);
        setStartLoader(false);
        return;
      }
      api
        .get(`/relatorio/${uri}`)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            let result = [];

            if (report === "reproducao-midia" || report === "reproducao-cef") {
              result = resp.data.result;
            } else if (report === "player-cliente") {
              result = resp.data.result.rows;
            } else if (report === "importacao-cef") {
              result = resp.data.result.rows;
            }

            if (result.length === 0) {
              setErrorMessage("Nenhum resultado para o filtro aplicado");
              setDisplayErrorMessage(true);

              setStartLoader(false);
            } else {
              setStartLoader(false);
              if (report !== "importacao-cef") {
                ReportDispatch({ type: report, data: result });
                navigate(`/app/relatorios/${report}`, result);
              } else {
                ReportDispatch({ type: report, data: resp.data.result.rows });
                navigate(`/app/relatorios/${report}`, resp.data.result);
              }
            }
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
          setDisplayErrorMessage(true);
          setStartLoader(false);
        });
    }
  };

  useEffect(() => {
    // if (data !== []) {
    //   setInitialData(data);
    //   setRows(data);
    // }
    if (data) {
      setInitialData(data);
      setRows(data);
    }
  }, [data]);

  return (
    <>
      {modalType === "reproducao-midia" && (
        <ReportModal open={open} close={closeModal}>
          <Box className={classes.header}>
            <PageTitle
              className={classes}
              title="GERAÇÃO DE RELATÓRIO POR MÍDIA"
            />

            <TextField
              className={classes.searchBox}
              label="O que deseja localizar?"
              margin="normal"
              name="busca"
              onChange={(e) => requestSearch(e)}
              type="text"
              value={searched}
              variant="outlined"
            />
          </Box>

          <Box className={classes.modalMidia}>
            <Box className={classes.mediaCardList}>
              <CardList
                className={classes.selectCard}
                obj={rows}
                flippable={false}
                selectedClassName={classes.selectedCard}
                selectable={true}
                onSelect={selectMedia}
                alreadySelected={alreadySelected}
              />
            </Box>

            <Box className={classes.inputBox}>
              <TextField
                className={classes.timeField}
                label="Data inicial"
                margin="normal"
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                value={startDate}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.timeField}
                label="Horario inicial"
                margin="normal"
                onChange={(e) => setStartTime(e.target.value)}
                type="time"
                value={startTime}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.timeField}
                label="Data final"
                margin="normal"
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                value={endDate}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.timeField}
                label="Horario final"
                margin="normal"
                onChange={(e) => setEndTime(e.target.value)}
                type="time"
                value={endTime}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <Autocomplete
                className={classes.inputField}
                value={client}
                options={clients}
                getOptionLabel={(option) => option.cli_razaoSocial || ""}
                onChange={(event, value) => {
                  changeClient(value);
                }}
                style={{ marginTop: "16px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente (proprietário)"
                    variant="outlined"
                  />
                )}
              />

              <Autocomplete
                className={classes.inputField}
                value={lottery}
                options={lotteries}
                getOptionLabel={(option) => option.lot_razaoSocial || ""}
                onChange={(event, value) => {
                  setLottery(value);
                }}
                style={{ marginTop: "16px" }}
                renderInput={(params) => (
                  <TextField {...params} label="Lotérica" variant="outlined" />
                )}
              />
            </Box>

            <Box>
              <Button
                className={classes.buttonBox}
                disableElevation
                size="large"
                variant="outlined"
                onClick={() => generateReport("reproducao-midia")}
              >
                GERAR RELATÓRIO
              </Button>
            </Box>

            {displayErrorMessage && (
              <>
                <Typography className={classes.errorMessage}>
                  {errorMessage}
                </Typography>
              </>
            )}
          </Box>
        </ReportModal>
      )}

      {modalType === "player-cliente" && (
        <ReportModal
          open={open}
          close={() => closeModal()}
          className={classes.modalSize}
        >
          <Box className={classes.header}>
            <PageTitle
              className={classes}
              title="GERAÇÃO DE RELATÓRIO POR PLAYER POR CLIENTE"
            />
          </Box>

          <Box className={classes.modalClient}>
            <Box>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Mês"
                  style={{ marginRight: "10px" }}
                  margin="normal"
                  onChange={(e) => setMonth(e.target.value)}
                  type="text"
                  value={month}
                  variant="outlined"
                />

                <TextField
                  label="Ano"
                  margin="normal"
                  onChange={(e) => setYear(e.target.value)}
                  type="text"
                  value={year}
                  variant="outlined"
                />
              </Box>

              <Autocomplete
                value={client}
                options={clients}
                getOptionLabel={(option) => option.cli_razaoSocial || ""}
                onChange={(event, value) => {
                  changeClient(value);
                }}
                style={{ marginTop: "16px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente (proprietário)"
                    variant="outlined"
                  />
                )}
              />
            </Box>

            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={() => generateReport("player-cliente")}
              >
                GERAR RELATÓRIO
              </Button>
            </Box>

            {displayErrorMessage && (
              <>
                <Typography className={classes.errorMessage}>
                  {errorMessage}
                </Typography>
              </>
            )}
          </Box>
        </ReportModal>
      )}

      {modalType === "importacao-cef" && (
        <ReportModal
          open={open}
          close={() => closeModal()}
          className={classes.modalSize}
        >
          <Box className={classes.header}>
            <PageTitle
              className={classes}
              title="GERAÇÃO DE RELATÓRIO IMPORTAÇÃO SUPERVISOR"
            />
          </Box>

          <Box className={classes.modalClient}>
            <Box>
              <TextField
                style={{ marginRight: "10px" }}
                className={classes.timeField}
                label="Data inicial"
                margin="normal"
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                value={startDate}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.timeField}
                label="Horário inicial"
                margin="normal"
                onChange={(e) => setStartTime(e.target.value)}
                type="time"
                value={startTime}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box>
              <TextField
                style={{ marginRight: "10px" }}
                className={classes.timeField}
                label="Data final"
                margin="normal"
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                value={endDate}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.timeField}
                label="Horário final"
                margin="normal"
                onChange={(e) => setEndTime(e.target.value)}
                type="time"
                value={endTime}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={() => generateReport("importacao-cef")}
              >
                GERAR RELATÓRIO
              </Button>
            </Box>

            {displayErrorMessage && (
              <>
                <Typography className={classes.errorMessage}>
                  {errorMessage}
                </Typography>
              </>
            )}
          </Box>
        </ReportModal>
      )}

      {modalType === "reproducao-cef" && (
        <ReportModal
          open={open}
          close={() => closeModal()}
          className={classes.modalSize}
        >
          <Box className={classes.header}>
            <PageTitle
              className={classes}
              title="GERAÇÃO DE RELATÓRIO REPRODUÇÃO SUPERVISOR"
            />
          </Box>

          <Box className={classes.modalClient}>
            <Box
              style={{
                width: "340px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                className={classes.timeField}
                label="Data inicial"
                margin="normal"
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                value={startDate}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className={classes.timeField}
                label="Horário inicial"
                margin="normal"
                onChange={(e) => setStartTime(e.target.value)}
                type="time"
                value={startTime}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box
              style={{
                width: "340px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                className={classes.timeField}
                label="Data final"
                margin="normal"
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                value={endDate}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className={classes.timeField}
                label="Horário final"
                margin="normal"
                onChange={(e) => setEndTime(e.target.value)}
                type="time"
                value={endTime}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Autocomplete
              value={lottery}
              options={lotteries}
              getOptionLabel={(option) => option.lot_razaoSocial || ""}
              onChange={(event, value) => {
                changeLottery(value);
              }}
              style={{ marginTop: "16px", width: "340px" }}
              renderInput={(params) => (
                <TextField {...params} label="Lotérica" variant="outlined" />
              )}
            />

            <Autocomplete
              value={player}
              options={players}
              getOptionLabel={(option) => option.pla_nome || ""}
              onChange={(event, value) => {
                setPlayer(value);
              }}
              style={{ marginTop: "16px", width: "340px" }}
              renderInput={(params) => (
                <TextField {...params} label="Player" variant="outlined" />
              )}
            />

            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={() => generateReport("reproducao-cef")}
              >
                GERAR RELATÓRIO
              </Button>
            </Box>

            {displayErrorMessage && (
              <>
                <Typography className={classes.errorMessage}>
                  {errorMessage}
                </Typography>
              </>
            )}
          </Box>
        </ReportModal>
      )}
      <Loader display={startLoader} />
    </>
  );
};

export default ModalsReport;
