import React from "react";
import { Navigate } from "react-router-dom";
import { isSignedIn } from "src/services/auth";

import MainLayout from "src/layouts/MainLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

// Auth
import LoginView from "src/views/auth/LoginView";
import ForgotPasswordView from "src/views/auth/ForgotPasswordView";
import ValidateTokenPasswordView from "src/views/auth/ValidateTokenPasswordView";
import RedefinePasswordView from "src/views/auth/RedefinePasswordView";
import RedefinedPasswordView from "src/views/auth/RedefinedPasswordView";
import NotFoundView from "src/views/errors/NotFoundView";

// Dashboard
import DashboardView from "src/views/dashboard/DashboardView";

// Profile
import EditProfileView from "src/views/profile/EditProfileView";

// Clients
import ClientListView from "src/views/clients/ClientListView";
import NewClientView from "src/views/clients/NewClientView";
import EditClientView from "src/views/clients/EditClientView";

// EditClient
import EditSingleClientView from "src/views/editClient/EditSingleClientView";

// Lotteries
import LotteryListView from "src/views/lotteries/LotteryListView";
import NewLotteryView from "src/views/lotteries/NewLotteryView";
import EditLotteryView from "src/views/lotteries/EditLotteryView";

// Users
import UserListView from "src/views/users/UserListView";
import NewUserView from "src/views/users/NewUserView";
import EditUserView from "src/views/users/EditUserView";

// Players
import PlayerListView from "src/views/players/PlayerListView";
import NewPlayerView from "src/views/players/NewPlayertView";

// Categories
import CategoryListView from "src/views/categories/CategoryListView";
import NewCategoryView from "src/views/categories/NewCategoryView";
import EditCategoryView from "src/views/categories/EditCategoryView";

// Media Library
import MediaLibraryView from "src/views/mediaLibrary/MediaLibraryView";
import NewMediaLibraryView from "src/views/mediaLibrary/NewMediaLibraryView";

// Templates
import Template from "src/views/template/TemplateView";
import NewTemplate from "src/views/template/NewTemplate";

// Watermark
import Watermark from "src/views/watermark/Watermark";

// Grid
import GridView from "src/views/grid/GridView";
import NewGridView from "src/views/grid/NewGridView";
import EditGridView from "src/views/grid/EditGridView";

// Playlist
import Playlist from "src/views/playlist/PlaylistView";
import GridPreviewView from "src/views/grid-preview/GridPreviewView";

//Reports
import ReportsView from "./views/reports/ReportsView";
import PDFReports from "./views/reports/PDFReports";
import EditPlayerView from "./views/players/EditPlayerView";
import { SocketProvider } from "./hooks/useSocket";

const PrivateRoute = ({ component: Component }) =>
  isSignedIn() ? <Component /> : <LoginView />;

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: <PrivateRoute component={() => <DashboardView />} />,
      },
      {
        path: "perfil",
        element: <PrivateRoute component={() => <EditProfileView />} />,
      },
      {
        path: "clientes",
        element: <PrivateRoute component={() => <ClientListView />} />,
      },
      {
        path: "clientes/:id",
        element: <PrivateRoute component={() => <EditClientView />} />,
      },
      {
        path: "editar-cliente",
        element: <PrivateRoute component={() => <EditSingleClientView />} />,
      },
      {
        path: "novo-cliente",
        element: <PrivateRoute component={() => <NewClientView />} />,
      },
      {
        path: "lotericas",
        element: <PrivateRoute component={() => <LotteryListView />} />,
      },
      {
        path: "lotericas/:id",
        element: <PrivateRoute component={() => <EditLotteryView />} />,
      },
      {
        path: "nova-loterica",
        element: <PrivateRoute component={() => <NewLotteryView />} />,
      },
      {
        path: "usuarios",
        element: <PrivateRoute component={() => <UserListView />} />,
      },
      {
        path: "usuarios/:id",
        element: <PrivateRoute component={() => <EditUserView />} />,
      },
      {
        path: "novo-usuario",
        element: <PrivateRoute component={() => <NewUserView />} />,
      },
      {
        path: "categorias",
        element: <PrivateRoute component={() => <CategoryListView />} />,
      },
      {
        path: "categorias/:id",
        element: <PrivateRoute component={() => <EditCategoryView />} />,
      },
      {
        path: "nova-categoria",
        element: <PrivateRoute component={() => <NewCategoryView />} />,
      },
      {
        path: "players",
        element: (
          <PrivateRoute
            component={() => (
              <SocketProvider>
                <PlayerListView />
              </SocketProvider>
            )}
          />
        ),
      },
      {
        path: "players/:id",
        element: <PrivateRoute component={() => <EditPlayerView />} />,
      },
      {
        path: "novo-player",
        element: <PrivateRoute component={() => <NewPlayerView />} />,
      },
      {
        path: "biblioteca",
        element: <PrivateRoute component={() => <MediaLibraryView />} />,
      },
      {
        path: "nova-biblioteca",
        element: <PrivateRoute component={() => <NewMediaLibraryView />} />,
      },
      {
        path: "templates",
        element: <PrivateRoute component={() => <Template />} />,
      },
      {
        path: "novo-template",
        element: <PrivateRoute component={() => <NewTemplate />} />,
      },
      {
        path: "marca-dagua",
        element: <PrivateRoute component={() => <Watermark />} />,
      },
      {
        path: "grades",
        element: <PrivateRoute component={() => <GridView />} />,
      },
      {
        path: "grades/:id",
        element: <PrivateRoute component={() => <EditGridView />} />,
      },
      {
        path: "nova-grade",
        element: <PrivateRoute component={() => <NewGridView />} />,
      },
      {
        path: "playlist/:id",
        element: <PrivateRoute component={() => <Playlist />} />,
      },
      {
        path: "grade-preview/:id",
        element: <PrivateRoute component={() => <GridPreviewView />} />,
      },
      {
        path: "relatorios",
        element: <PrivateRoute component={() => <ReportsView />} />,
      },
      {
        path: "relatorios/:type",
        element: <PrivateRoute component={() => <PDFReports />} />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <LoginView /> },
      { path: "login", element: <LoginView /> },
      { path: "forgot-password", element: <ForgotPasswordView /> },
      {
        path: "validate-password-token",
        element: <ValidateTokenPasswordView />,
      },
      { path: "redefine-password", element: <RedefinePasswordView /> },
      {
        path: "redefined-password-success",
        element: <RedefinedPasswordView />,
      },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
