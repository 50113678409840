import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { apiUrl } from "src/services/api";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5F5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 16,
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    maxWidth: "100vw",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    marginLeft: theme.spacing(1),
    width: "30vw",
    paddingLeft: theme.spacing(5),
  },
  totalPlayers: {
    fontSize: 26,
    marginTop: theme.spacing(1),
    fontWeight: "bold",
  },
  headerClient: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(10),
    paddingLeft: theme.spacing(5),
  },
  headerPlay: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(10),
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(10),
    marginBottom: theme.spacing(17),
    padding: 0,
  },
  headerImport: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(10),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(10),
  },
  subTitle: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  subTitleMedia: {
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(2),
  },
  cefFlexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "0 64px",
    paddingBottom: theme.spacing(2),
  },
  imageBox: {
    width: "25vw",
    maxHeight: "50vh",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  mediaReport: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(3),
    justifyContent: "space-between",
  },
  subTitleMediaBox: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(8),
    justifyContent: "space-between",
  },
  lotteryName: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
  },
  midiaBox: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  tableMidia: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  totalLabel: {
    padding: "15px",
  },
  lotteryTotal: {
    padding: "15px",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
  },
  playerReportHeader: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(8),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "#F5F5F5",
    width: "50%",
  },
  playerReportColumn1: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  playerReportColumn2: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end",
    gap: theme.spacing(1),
  },
  subTitleMediaBox2: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

const PDFReports = () => {
  const imgUrl = apiUrl;
  const classes = useStyles();
  const { type } = useParams();
  const report = useSelector((state) => state.report);
  const [reportData, setReportData] = useState([]);
  const [cefReport, setCefReport] = useState(null);

  const [show, setShow] = useState(false);

  const manageData = () => {
    if (type !== "reproducao-cef") {
      setReportData(report.reportData);
    } else {
      setCefReport(report.reportData);
    }
  };

  const returnTotal = () => {
    let total = 0;

    reportData.forEach((el) => {
      total = total + el.total_rep_lot;
    });

    return total;
  };

  useState(() => {
    if (report && report.reportType && report.reportType === type) {
      manageData();
    }
  });

  return (
    <Page className={classes.root} title="Dashboard">
      <Container className={classes.container}>
        {type === "reproducao-midia" && (
          <>
            <PageTitle title="RELATÓRIO POR MÍDIA" />
            <Box className={classes.flexRow}>
              <Box className={classes.imageBox}>
                {reportData[0]?.mul_tipo === "video" ? (
                  <video
                    src={
                      imgUrl +
                      (reportData.length > 0 ? reportData[0].mul_thumb : "")
                    }
                    alt="Report Video"
                    className={classes.img}
                  />
                ) : (
                  <img
                    src={
                      imgUrl +
                      (reportData.length > 0 ? reportData[0].mul_thumb : "")
                    }
                    alt="Report Media"
                    className={classes.img}
                  />
                )}
              </Box>

              <Box className={classes.mediaReport}>
                <Box className={classes.subTitleMediaBox}>
                  <Box className={classes.subTitleMedia}>
                    <Typography>NOME</Typography>
                    <Typography>
                      {reportData.length > 0 ? reportData[0].mul_nome : ""}
                    </Typography>
                  </Box>
                  {show && (
                    <Box className={classes.header}>
                      <Box className={classes.subTitle}>
                        <Box className={classes.subTitle}>
                          <Box>
                            <Typography>PERÍODO INICIAL</Typography>
                            <Typography>01/01/2021 - 08:00</Typography>
                          </Box>

                          <Box>
                            <Typography>PERÍODO FINAL</Typography>
                            <Typography>01/01/2021 - 20:00</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Typography className={classes.totalPlayers}>
                  TOTAL DE REPRODUÇÕES: {returnTotal()}
                </Typography>
              </Box>
            </Box>

            {reportData.map((data, index) => (
              <Box className={classes.midiaBox} key={index}>
                <Box>
                  <Typography variant="h3" color="secondary">
                    Lotérica
                  </Typography>
                  <Typography className={classes.lotteryName}>
                    {" "}
                    {data.lot_razaoSocial}
                  </Typography>
                </Box>

                {data.player.map((player, index) => (
                  <Box key={index}>
                    <Typography color="secondary" style={{ marginTop: "15px" }}>
                      {player.pla_nome}
                    </Typography>

                    <TableContainer className={classes.tableMidia}>
                      <Table size="small" aria-label="Tabela de clientes">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>DATA</StyledTableCell>
                            <StyledTableCell>
                              HORARIO DE REPRODUÇÃO
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>

                        {player.reproducao.map((item, index) => (
                          <TableBody key={index}>
                            <TableRow>
                              <StyledTableCell>{item.rep_data}</StyledTableCell>
                              <StyledTableCell>{item.rep_hora}</StyledTableCell>
                            </TableRow>
                          </TableBody>
                        ))}
                      </Table>
                      <Typography className={classes.totalLabel}>
                        TOTAL DE REPRODUÇÕES: {player.total_rep_pla}
                      </Typography>
                    </TableContainer>
                  </Box>
                ))}

                <Typography className={classes.lotteryTotal}>
                  TOTAL DE REPRODUÇÕES: {data.total_rep_lot}
                </Typography>

                <Divider />
              </Box>
            ))}
          </>
        )}

        {type === "player-cliente" && (
          <>
            <PageTitle title="RELATÓRIO PLAYER POR CLIENTE" />

            <Box className={classes.playerReportHeader}>
              <Box className={classes.playerReportColumn1}>
                {show && (
                  <Box>
                    <Typography>Clientes</Typography>
                    <Typography>Loteriada Sorte LTDA</Typography>
                  </Box>
                )}

                <Typography className={classes.totalPlayers}>
                  TOTAL DE PLAYERS: {reportData.length}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.midiaBox}>
              <TableContainer className={classes.tableMidia}>
                <Table size="small" aria-label="Tabela de clientes">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>LOTÉRICA</StyledTableCell>
                      <StyledTableCell>NOME</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  {reportData.map((data) => (
                    <TableBody key={data.pla_id}>
                      <TableRow>
                        <StyledTableCell>
                          {data.loterica.lot_razaoSocial}
                        </StyledTableCell>
                        <StyledTableCell>{data.pla_nome}</StyledTableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Box>
          </>
        )}

        {type === "importacao-cef" && (
          <>
            <PageTitle title="RELATÓRIO IMPORTAÇÃO DE MÍDIAS SUPERVISOR" />

            <Box className={classes.playerReportHeader}>
              <Box className={classes.playerReportColumn1}>
                {show && (
                  <>
                    <Box>
                      <Typography>PERÍODO INICIAL</Typography>
                      <Typography>01/01/2021 - 08:00</Typography>
                    </Box>
                    <Box>
                      <Typography>PERÍODO FINAL</Typography>
                      <Typography>01/01/2021 - 20:00</Typography>
                    </Box>
                  </>
                )}

                <Typography className={classes.totalPlayers}>
                  TOTAL DE MÍDIAS IMPORTADAS: {reportData.length}
                </Typography>
              </Box>
            </Box>

            <Box>
              <TableContainer className={classes.midiaBox}>
                <Table size="small" aria-label="Tabela de clientes">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>MÍDIA</StyledTableCell>
                      <StyledTableCell>DATA</StyledTableCell>
                      <StyledTableCell>HORA DA IMPORTAÇÃO</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  {reportData.map((data) => (
                    <TableBody key={data.mul_id}>
                      <TableRow>
                        <StyledTableCell>{data.mul_nome}</StyledTableCell>
                        <StyledTableCell>{data.mul_data}</StyledTableCell>
                        <StyledTableCell>{data.mul_hora}</StyledTableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Box>
          </>
        )}

        {type === "reproducao-cef" && (
          <>
            <PageTitle title="RELATÓRIO REPRODUÇÃO DE MÍDIAS SUPERVISOR" />

            <Box className={classes.playerReportHeader}>
              <Box className={classes.playerReportColumn1}>
                {show && (
                  <>
                    <Box>
                      <Typography>Mês Referência</Typography>
                      <Typography>MARÇO</Typography>
                    </Box>

                    <Box>
                      <Typography>Ano Referência</Typography>
                      <Typography>2021</Typography>
                    </Box>
                  </>
                )}

                <Typography className={classes.totalPlayers}>
                  TOTAL DE EXIBIÇÕES: {cefReport !== null ? cefReport.count : 0}
                </Typography>
              </Box>
            </Box>

            {cefReport &&
              cefReport.lista.map((media, index) => (
                <Fragment key={index}>
                  <Box className={classes.cefFlexRow}>
                    <Box className={classes.imageBox}>
                      <img
                        src={imgUrl + media.mul_thumb}
                        alt="Report Media"
                        className={classes.img}
                      />
                    </Box>

                    <Box className={classes.mediaReport}>
                      <Box className={classes.subTitleMediaBox2}>
                        <Box className={classes.subTitleMedia}>
                          <Typography>NOME</Typography>
                          <Typography>{media.mul_nome}</Typography>
                        </Box>

                        <Box className={classes.header}>
                          <Box className={classes.subTitle}>
                            {show && (
                              <Box className={classes.subTitle}>
                                <Box>
                                  <Typography>PERÍODO INICIAL</Typography>
                                  <Typography>01/01/2021 - 08:00</Typography>
                                </Box>

                                <Box>
                                  <Typography>PERÍODO FINAL</Typography>
                                  <Typography>01/01/2021 - 20:00</Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <Typography className={classes.totalPlayers}>
                        TOTAL DE REPRODUÇÕES: {media.total}{" "}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Box>
                      <TableContainer className={classes.midiaBox}>
                        <Table size="small" aria-label="Tabela de clientes">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>LOTERICA</StyledTableCell>
                              <StyledTableCell>PLAYER</StyledTableCell>
                              <StyledTableCell>DATA</StyledTableCell>
                              <StyledTableCell>
                                HORARIO DE REPRODUÇÃO
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>

                          {media.conteudo.map((item, index) => (
                            <TableBody key={index}>
                              <TableRow>
                                <StyledTableCell>
                                  {item.lot_razaoSocial}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item.pla_nome}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {moment(item.rep_data).format("DD/MM/YYYY")}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item.rep_hora}
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Fragment>
              ))}
          </>
        )}
      </Container>
    </Page>
  );
};

export default PDFReports;
