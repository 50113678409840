import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: colors.common.white
    },
    primary: {
      main: colors.common.black,
    },
    secondary: {
      main: '#F07C00'
    },
    text: {
      primary: colors.common.black,
      secondary: 'rgba(0, 0, 0, 0.80)',
    },
    outline: {
      primary: 'rgba(0, 0, 0, 0.12)'
    },
    success: {
      main: colors.green[400]
    },
    error: {
      main: '#F52C62'
    },
    warning: {
      main: '#ff9D00'
    },
    info: {
      main: '#2196f3'
    }
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#F07C00'
      }
    },
    MuiInputBase: {
      root: {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.12)'
        },
        '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#F07C00',
        },
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#F07C00'
        }
      }
    },
    MuiButton: {
      root: {
        color: '#333333'
      },
      outlinedSizeLarge: {
        backgroundColor: '#F5F5F5',
        color: '#605D5C',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        '&:hover': {
          borderColor: '#F07C00'
        }
      }
    },
    MuiDrawer: {
      paperAnchorLeft: {
        backgroundColor: '#F5F5F5'
      }
    }
  },
  shadows,
  typography
});

export default theme;
