import React, { useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import StopIcon from "@material-ui/icons/Stop";
import Page from "src/components/Page";
import MediaCard from "src/components/MediaCard";
import PageTitle from "src/components/PageTitle";
import WeatherCard from "src/components/WeatherCard";
import NewsCard from "src/components/NewsCard";
import { useState } from "react";
import { Box, Button, Container, makeStyles } from "@material-ui/core";
import AlertDialog from "src/components/AlertDialog";
import api from "src/services/api";
import hourToMiliseconds from "src/services/hoursToMiliseconds";
import PlaceHolderImage from "../../assets/placeholder-image.jpg";
import daysWeek from "src/utils/daysWeek";
import moment from "moment";
import PlaylistViewWattermark from "src/components/PlaylistViewWattermark";
import Progressbar from "src/components/ProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    left: -20,
    top: 25,
    justifyContent: "space-between",
    margin: theme.spacing(0),
    maxWidth: "100vw",
  },
  searchBox: {
    marginRight: "210px",
    width: "326px",
  },
  mediaCardList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "center",
    overflowY: "scroll",
    overflowX: "hidden",
    width: 360,
    height: "65vh",
    marginRight: theme.spacing(1),
    gap: theme.spacing(2),
    color: "textPrimary",
  },
  image: {
    width: "100%",
    height: "64vh",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  active: {
    color: "#F07C00",
  },
  inactive: {
    color: "#605D5C",
  },
  stopActive: {
    background: "#F07C00",
  },
  stopInactive: {
    background: "#605D5C",
  },
  backButton: {
    right: "45px",
    position: "absolute",
  },
  stopButton: {
    color: "#fff",
    width: "1.9rem",
    height: "1.9rem",
    borderRadius: "50%",
  },
  buttonSave: {
    position: "absolute",
    top: 650,
    left: "45%",
  },
  view: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  styledTitleBox: {
    minWidth: "336px",
    maxWidth: "45vw",
    height: "48px",
    whiteSpace: "nowrap",
    paddingLeft: 20,
    backgroundColor: "#F07C00",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  styledTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#FFFFFF",
    paddingRight: "16px",
  },
}));

const Playlist = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [buttonPlayer, setButtonPlayer] = useState("play");
  const [loaded, setLoaded] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [displayedElement, setDisplayedElement] = useState(null);
  const [currentMedia, setCurrentMedia] = useState(0);
  const [value, setValue] = useState(0);
  const [mediaLength, setMediaLength] = useState(0);

  const classes = useStyles();

  const timerGridRef = useRef(null);
  const timerRef = useRef(null);
  const videoRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  const { id } = useParams();

  const today = daysWeek[moment().day()];

  useEffect(() => {
    if (playlist.length > 0 && buttonPlayer === "play") {
      console.log("playlist[currentMedia]", playlist[currentMedia]);
      console.log("playlist", playlist);
      const duracao = playlist[currentMedia]?.exibicao.find(
        (i) => i.exc_dia_semana == today.dia
      )?.exc_duracao;

      if (duracao) {
        const duracaoFormatted = tempoParaMilissegundos(duracao);

        console.log("duracaoFormatted", duracaoFormatted);
        setMediaLength(duracaoFormatted);
      }
    }
  }, [buttonPlayer, currentMedia, playlist, today.dia]);

  const tempoParaMilissegundos = (tempoString) => {
    const [horas, minutos, segundos] = tempoString.split(":").map(Number);

    const totalMilissegundos =
      horas * 3600000 + minutos * 60000 + segundos * 1000;

    return totalMilissegundos;
  };

  const setIntervalWithTimeout = useCallback(
    (interval, timeout) => {
      setValue(0);

      let elapsedTime = 0;

      timerRef.current = setInterval(() => {
        if (buttonPlayer === "stop") {
          clearInterval(timerRef.current);
        }

        if (buttonPlayer === "pause") {
          clearInterval(timerRef.current);
        }

        if (buttonPlayer !== "pause") {
          elapsedTime += interval;

          setValue((oldValue) => {
            const newValue = oldValue + interval;

            if (newValue === mediaLength) {
              clearInterval(timerRef.current);
            }

            return newValue;
          });
        }

        if (elapsedTime >= timeout) {
          clearInterval(timerRef.current);
        }
      }, interval);
    },
    [buttonPlayer, mediaLength]
  );

  const handleBack = () => {
    clearTimeout(timerGridRef.current);

    navigate(state?.from);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleStartProgressBar = useCallback(
    (item) => {
      // const item = playlist[currentMedia];

      const durationMedia = item?.exibicao?.find(
        (i) => i.exc_dia_semana == today.dia
      );
      const intervalDuration = tempoParaMilissegundos(
        durationMedia?.exc_duracao ? durationMedia?.exc_duracao : "00:00:15"
      );
      console.log("handleStartProgressBar durationMedia", intervalDuration);

      setIntervalWithTimeout(100, intervalDuration);
    },
    [setIntervalWithTimeout, today.dia]
  );

  const handleOnLoadVideo = useCallback(
    (videoFrame, item) => {
      const iframeDoc =
        videoFrame.contentDocument || videoFrame.contentWindow.document;
      if (iframeDoc) {
        const videoElement = iframeDoc.querySelector("video");

        videoElement.addEventListener("play", () =>
          handleStartProgressBar(item)
        );

        videoElement.addEventListener("pause", () => {
          if (buttonPlayer !== "pause") handlePauseVideo();
        });
      }
    },
    [buttonPlayer, handleStartProgressBar]
  );

  const fetchList = useCallback((id) => {
    api
      .get(`/listaReproducao/${id}`)
      .then((resp) => {
        const { result } = resp.data;
        setupPlaylist(result.conteudos);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err?.response?.data?.message);
      });
  }, []);

  const setupPlaylist = (list) => {
    list.forEach((el) => {
      if (el.con_tipo === "midia") {
        el.id = el.con_mul_id;
        el.name = el.midia.mul_nome;
        el.type = el.midia.mul_tipo;
        el.image = el.midia.mul_thumb;
      } else if (el.con_tipo === "clima") {
        el.id = el.con_cli_id;
        el.name = el.clima.cli_nome;
        el.type = el.con_tipo;
        el.cli_cidade = el.clima.cli_cidade;
        el.cli_uf = el.clima.cli_uf;
      } else if (el.con_tipo === "noticia") {
        el.id = el.con_not_id;
        el.type = el.con_tipo;
        el.category = el.noticia.not_categoria;
      }

      el.duration = el.con_duracao;
      el.presentation = `${el.con_horaInicio} - ${el.con_horaFim}`;
    });
    setPlaylist(list);
  };

  const openItem = useCallback(
    (item) => {
      if (item.type === "imagem") {
        api
          .get(`/multimidia/file/${item.midia.mul_path}`, {
            responseType: "blob",
          })
          .then((resp) => {
            const objectUrl = URL.createObjectURL(resp.data);
            const url = `${objectUrl}`;

            const img = new Image();
            img.src = url;

            img.onload = () => {
              setDisplayedElement(
                <img
                  src={objectUrl}
                  className={classes.image}
                  alt="mídia imagem"
                  style={{ width: "100%", height: "64vh" }}
                />
              );
              handleStartProgressBar(item);
            };

            img.onerror = () => {
              handleStartProgressBar(item);
              setDisplayedElement(
                <img
                  src={PlaceHolderImage}
                  className={classes.image}
                  alt="mídia imagem"
                  style={{ width: "100%", height: "64vh" }}
                />
              );
            };
          })
          .catch((err) => {
            setDisplayedElement(
              <img
                src={PlaceHolderImage}
                className={classes.image}
                alt="mídia imagem"
                style={{ width: "100%", height: "64vh" }}
              />
            );
            handleStartProgressBar(item);
          });
      } else if (item.type === "video") {
        if (item.midia.mul_processamento_concluido) {
          api
            .get(`/multimidia/file/${item.midia.mul_path}`, {
              responseType: "blob",
            })
            .then((resp) => {
              const objectUrl = URL.createObjectURL(resp.data);

              setDisplayedElement(
                <iframe
                  title="video player"
                  id="videoPlayer"
                  ref={videoRef}
                  style={{ width: "100%", height: "64vh" }}
                  width="100%"
                  height="100%"
                  src={objectUrl}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onLoad={(e) => handleOnLoadVideo(e.target, item)}
                ></iframe>
              );
            })
            .catch((err) => {
              setDisplayedElement(
                <img
                  alt="imagem de fundo padrão"
                  src={PlaceHolderImage}
                  className={classes.image}
                  style={{ width: "100%", height: "64vh" }}
                />
              );
              handleStartProgressBar(item);
            });
        }
        else {
          setDisplayedElement(
            <div style={{ width: "100%", height: "64vh", display: "flex", justifyContent: "center" }}>
              <p style={{ display: "flex", alignItems: "center" }}>O vídeo está sendo processado. Tente novamente em instantes.</p>
            </div>
          );
          handleStartProgressBar(item);
        }
      } else if (item.type === "clima") {
        api
          .get(
            `/clima/tempo?cidade=${item.clima.cli_cidade}&uf=${item.clima.cli_uf}`
          )
          .then((resp) => {
            const result = resp.data.result;

            setDisplayedElement(<WeatherCard weather={result} />);
            handleStartProgressBar(item);
          })
          .catch((err) => {
            setDisplayedElement(
              <img
                src={PlaceHolderImage}
                className={classes.image}
                alt="imagem de fundo padrão"
                style={{ width: "100%", height: "64vh" }}
              />
            );
            handleStartProgressBar(item);
          });
      } else if (item.type === "noticia") {
        api
          .get(`/noticia/${item.noticia.not_categoria}`)
          .then((resp) => {
            const result = resp.data.result;
            let random_num = Math.floor(Math.random() * result.length);
            setDisplayedElement(
              <NewsCard
                category={item.noticia.not_categoria}
                news={result[random_num]}
              />
            );
            handleStartProgressBar(item);
          })
          .catch((err) => {
            setDisplayedElement(
              <img
                alt="imagem de fundo padrão"
                src={PlaceHolderImage}
                className={classes.image}
                style={{ width: "100%", height: "64vh" }}
              />
            );
            handleStartProgressBar(item);
          });
      }
    },
    [classes.image, handleOnLoadVideo, handleStartProgressBar]
  );

  const startPlayer = () => {
    let arrLength = playlist.length;

    if (currentMedia === arrLength) {
      setCurrentMedia(0);
    } else {
      let item = playlist[currentMedia];
      const durationMedia = item.exibicao?.find(
        (i) => i.exc_dia_semana == today.dia
      );

      if (durationMedia?.exc_duracao) {
        openItem(item);
        console.log(durationMedia?.exc_duracao);

        const durationMiliseconds = hourToMiliseconds(
          durationMedia?.exc_duracao ? durationMedia?.exc_duracao : "00:00:15"
        );

        timerGridRef.current = setTimeout(() => {
          setCurrentMedia(currentMedia + 1);
        }, durationMiliseconds);
      } else {
        setCurrentMedia(
          currentMedia + 1 >= playlist.length ? 0 : currentMedia + 1
        );
      }
    }
  };

  const playListExhibition = () => {
    setButtonPlayer("play");

    clearTimeout(timerGridRef.current);
    clearInterval(timerRef.current);

    // setIntervalWithTimeout(50, mediaLength);
  };

  const pauseList = () => {
    clearTimeout(timerGridRef.current);
    console.log("clear timerGridRef");
    clearInterval(timerRef.current);
    console.log("clear timerRef");

    setButtonPlayer("pause");
  };

  const stopList = () => {
    clearTimeout(timerGridRef.current);
    clearInterval(timerRef.current);

    setButtonPlayer("stop");
    setCurrentMedia(0);
  };

  useEffect(() => {
    if (!loaded) {
      fetchList(id);
    }
  }, [fetchList, id, loaded]);

  const handlePauseVideo = () => {
    const iframe = videoRef.current;

    if (iframe) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      const videoElement = iframeDocument.querySelector("video");

      if (videoElement) videoElement.pause();
    }
  };

  useEffect(() => {
    if (playlist.length !== 0 && buttonPlayer === "play") {
      clearInterval(timerRef.current);

      startPlayer();
    }

    if (buttonPlayer === "pause" || buttonPlayer === "stop") {
      handlePauseVideo();
    }
  }, [playlist, currentMedia, buttonPlayer]);

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    const videoFrame = videoRef.current;

    if (videoFrame) {
      console.log("setting event load");
      // videoFrame.addEventListener("load", () => handleOnLoadVideo(videoFrame));
    }

    return () => {
      window.removeEventListener("play", handleStartProgressBar);
      window.removeEventListener("load", handleOnLoadVideo);
    };
  }, [handleOnLoadVideo, handleStartProgressBar]);

  return (
    <Page className={classes.root} title="Lista de reprodução">
      <Box style={{ display: "flex", alignItems: "center" }}>
        <PageTitle
          title="LISTA DE REPRODUÇÃO - PRÉ-VISUALIZAÇÃO"
          className={classes}
        />

        <Box>
          <Button>
            <PlayCircleFilledWhiteIcon
              fontSize="large"
              className={`${buttonPlayer === "play" ? classes.active : classes.inactive
                }`}
              onClick={playListExhibition}
            />
          </Button>

          <Button>
            <PauseCircleFilledIcon
              fontSize="large"
              className={`${buttonPlayer === "pause" ? classes.active : classes.inactive
                }`}
              onClick={pauseList}
            />
          </Button>

          <Button>
            <StopIcon
              fontSize="large"
              className={`${classes.stopButton} 
              ${buttonPlayer === "stop"
                  ? classes.stopActive
                  : classes.stopInactive
                }`}
              onClick={stopList}
            />
          </Button>
        </Box>

        <Button
          disableElevation
          className={classes.backButton}
          size="large"
          variant="outlined"
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Box>

      <Box className={classes.view}>
        <Container className={classes.container}>
          <div className={classes.mediaCardList}>
            {playlist.map((item, index) => (
              <MediaCard
                key={index}
                withoutButtons
                className={classes.card}
                name={item.name}
                image={item.image}
                type={item.type}
                category={item.category}
                duration={item.duration}
                mul_processamento_concluido={item?.midia?.mul_processamento_concluido}
                presentation={item.midiaDaysToSave}
                onlyDisplay={true}
                onOpen={() => openItem(item)}
                cidade={item.cli_cidade}
                uf={item.cli_uf}
              />
            ))}
          </div>
          <div
            style={{
              width: "70%",
              height: "100%",
              position: "relative",
            }}
          >
            {displayedElement}
            {displayedElement && (
              <>
                <PlaylistViewWattermark data={location.state?.data} />
                <Progressbar value={value} max={mediaLength} />
              </>
            )}
          </div>
        </Container>
      </Box>

      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

export default Playlist;
