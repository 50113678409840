import React from 'react';
import {
    Container,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';

import Dropzone from 'src/components/Dropzone';
import Page from 'src/components/Page';
import PageTitle from 'src/components/PageTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
   
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 48,
    },

    nameBox: {
        width: '300px',
    },

}));

const Watermark = () => {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="Marca d'agua">
            <PageTitle title="MARCA D'AGUA" />
            <Container className={classes.container}>
                <TextField
                    className={classes.nameBox}
                    margin="normal"
                    placeholder="Texto livre (opcional)"
                    onChange={() => { }}
                    type="text"
                    value=""
                    variant="outlined"
                />

                <Dropzone value={'image'} buttons />

                <Typography variant="h5" component="h2">
                    Recomendados que a imagem seja no formato PNG, com transparência.
                </Typography>

            </Container>

        </Page>
    );
};

export default Watermark;
