import Ciencia from "../assets/noticiasPlaceholder/ciencia_um.jpg";
import Entretenimento from "../assets/noticiasPlaceholder/entretenimento_um.jpg";
import Saude from "../assets/noticiasPlaceholder/saude_um.jpg";
import Negocio from "../assets/noticiasPlaceholder/negocio_um.jpg";
import Esportes from "../assets/noticiasPlaceholder/esportes_um.jpg";
import Tecnologia from "../assets/noticiasPlaceholder/tecnologia_um.jpg";
import Geral from "../assets/noticiasPlaceholder/geral_um.jpg";

const imagesPlaceholder = {
    saude: [Saude],
    negocio: [Negocio],
    entretenimento: [Entretenimento],
    geral: [Geral],
    ciencia: [Ciencia],
    esporte: [Esportes],
    tecnologia: [Tecnologia]
}

export default imagesPlaceholder