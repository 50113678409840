const daysWeek = [
    {
        dia: 'domingo'
    },
    {
        dia: 'segunda'
    },
    {
        dia: 'terca'
    },
    {
        dia: 'quarta'
    },
    {
        dia: 'quinta'
    },
    {
        dia: 'sexta'
    },
    {
        dia: 'sabado'
    }
]

export default daysWeek;