import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import AlertDialog from 'src/components/AlertDialog';
import { login, user } from 'src/store/actions';
import { postLogin, logout } from 'src/services/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  imgLogo: {
    width: '350px',
    height: '142.4px'
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: 8,
    marginLeft: '-17%'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loadedView, setLoadedView] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  
  const dispatch = useDispatch();
  const LoginDispatch = (value) => dispatch(login(value));
  const UserDispatch = (value) => dispatch(user(value));

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .max(255)
        .required('E-mail é obrigatório'),
      password: Yup.string()
        .max(255)
        .required('Senha é obrigatória')
    })
  });
  
  useEffect(() => {
    if (!loadedView) {
      LoginDispatch(false);
      UserDispatch({});
      logout();
      setLoadedView(true);
    }
  }, [setLoadedView]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    setLoading(true);
    
    try {
      const response = await postLogin({
        usr_email: formik.values.email,
        usr_senha: formik.values.password
      });
      
      if (response.status === 200) {
        if(response.usr_tipoUsuario !== 'player') {
          delete response.token;
        
          LoginDispatch(true);
          UserDispatch(response);
  
          setLoading(false);
  
          navigate('/app/dashboard', { replace: true });
        }
        else {
          setLoading(false);
          handleAlertOpen("Tipo de usuário não autorizado.");
        }
      } else {
        setLoading(false);
        handleAlertOpen(response.message);
      }
    } catch (error) {
      setLoading(false);
      handleAlertOpen();
    }

  };

  const handleAlertOpen = (message) => {
    if(message){
      setAlertText(message)
    }else{
      setAlertText('Email ou senha incorretos.');
    }
    setAlertType('error');
    setAlertButtonText('Fechar');
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const forgotPassword = () => {
    navigate('/forgot-password');
  }

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <Box className={classes.boxTitle}>
            <Grow in timeout={600}>
              <img
                alt="Logo"
                src="/static/images/logo_TVM.png"
                className={classes.imgLogo}
              />
            </Grow>
          </Box>

          <TextField
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label="Usuário"
            margin="normal"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.email}
            variant="outlined"
          />

          <TextField
            error={Boolean(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            label="Senha"
            margin="normal"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    name="showPassword"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Box my={2}>
            <Button
              disableElevation
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              onClick={handleSubmit}
              variant="outlined"
            >
              Fazer Login
            </Button>

            {
              loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )
            }
          </Box>

          <Box my={2}>
            <Button
              fullWidth
              size="large"
              onClick={forgotPassword}
            >
              Esqueci a senha
            </Button>
          </Box>
        </Container>
      </Box>

      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default LoginView;
