import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: {
    width: "350px",
  },
  imgLogo: {
    width: "69px",
    height: "70px",
  },
  alertIcon: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
  },
  alertContent: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
  },
  errorButton: {
    backgroundColor: "#C4C4C4",
    color: "#FFFFFF",
    borderColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "#C4C4C4",
      borderColor: "#F07C00",
    },
  },
  successButton: {
    backgroundColor: "#009C6D",
    color: "#FFFFFF",
    borderColor: "#009C6D",
    "&:hover": {
      backgroundColor: "#009C6D",
      borderColor: "#F07C00",
    },
  },
}));

export default function AlertDialog({
  open,
  handleClose,
  type,
  text,
  buttonText,
}) {
  const classes = useStyles();
  let imageSrc =
    type === "error"
      ? "/static/images/alert-error.png"
      : "/static/images/alert-success.png";
  let btnClass = type === "error" ? classes.errorButton : classes.successButton;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className={classes.dialog}>
        <Box className={classes.alertIcon}>
          <img alt={type} src={imageSrc} className={classes.imgLogo} />
        </Box>

        <DialogContentText
          id="alert-dialog-text"
          className={classes.alertContent}
        >
          {text}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          disableElevation
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleClose}
          className={btnClass}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
