import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Select,
  TextField
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import PageTabTitle from 'src/components/PageTabTitle';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';
import Loader from 'src/components/Loader';
import { validEmail } from 'src/services/validDocument';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
  fullBox: {
    width: '326px',
  },
  field: {
    marginRight: '25px'
  },
  buttonBox: {
    marginTop: '60px',
    marginBottom: '60px'
  }
}));

const NewUserView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const me = useSelector((state) => state.auth.user);

  const [startLoader, setStartLoader] = useState(false);

  const [activeTab, setactiveTab] = useState('primary');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      cliente: null,
      tipo: 'cliente',
      senha: '',
      confirmaSenha: ''
    }
  });

  const handleSave = () => {
    let values = formik.values;

    if (!validEmail(values.email)) return openErrorAlert('Insira um login válido.');
    if (!values.senha) return openErrorAlert('Insira uma senha.');
    if (!values.confirmaSenha) return openErrorAlert('Confirme sua senha.');
    // if(!client?.cli_id) return openErrorAlert("Selecione o cliente.");

    setStartLoader(true);

    let obj = {
      "usr_nome": values.nome,
      "usr_email": values.email,
      "usr_senha": values.senha,
      "usr_cli_id": values.tipo === "cliente" ? client.cli_id : null,
      "usr_tipoUsuario": values.tipo
    }

    if (values.tipo !== "cliente") {
      delete obj.usr_cli_id;
    }

    if (values.senha === values.confirmaSenha) {
      api.post('/user', obj)
        .then((resp) => {
          setStartLoader(false);
          openSuccessAlert();
        })
        .catch((err) => {
          setStartLoader(false);
          console.log(err);
          openErrorAlert(err.response.data.message);
        })
    } else {
      openErrorAlert('Senhas diferentes.');
      setStartLoader(false);
    }
  }

  const changeActiveTab = (tab) => {
    let values = formik.values;

    if (!validEmail(values.email)) return openErrorAlert('Insira um login válido.');
    if (!values.nome) return openErrorAlert('Insira o nome do usuário.');
    if (!values.email) return openErrorAlert('Insira o e-mail do usuário.');
    // if(!client?.cli_id) return openErrorAlert("Selecione o cliente.");

    setactiveTab(tab);
  }

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Usuario criado com sucesso.');
    setAlertType('success');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
    if (alertType !== 'error') navigate('/app/usuarios');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  }

  const nextPage = () => {
    const values = formik.values;

    if (!validEmail(values.email)) return openErrorAlert('Insira um login válido.');
    if (!values.nome) return openErrorAlert('Insira o nome do usuário.');
    if (!values.email) return openErrorAlert('Insira o e-mail do usuário.');
    // if(!client?.cli_id) return openErrorAlert("Selecione o cliente.");
    setactiveTab('secondary');
  }

  const backPage = () => {
    const url = `/app/usuarios`;
    navigate(url);
  }

  useEffect(() => {
    api.get(`/cliente`)
      .then((resp) => {
        if (me.usr_tipoUsuario === "cliente") setClient(resp.data.result[0])
        setClients(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }, [setClients]);

  return (
    <Page className={classes.root} title="Novo Usuário">
      <PageTabTitle primary="USUÁRIO" secondary="SENHA" active={activeTab} changeTab={changeActiveTab} />
      <Container className={classes.container}>
        {
          activeTab === "primary" ? (
            <>
              <Box className={classes.fullBox}>
                {me.usr_tipoUsuario === "admin" ?
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo de usuário</InputLabel>
                    <Select
                      native
                      value={formik.values.tipo}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="tipo"
                      label="Tipo de usuário"
                    >
                      <option value="admin">Admin</option>
                      <option value="cliente">Cliente</option>
                      <option value="caixa">Supervisor</option>
                    </Select>
                  </FormControl> : null
                }

                {
                  formik.values.tipo === 'cliente' && me.usr_tipoUsuario === 'admin' ?
                    (
                      <Autocomplete
                        value={client}
                        options={clients}
                        getOptionLabel={(option) => option.cli_razaoSocial || ''}
                        onChange={(event, value) => {
                          setClient(value);
                        }}
                        style={{ display: 'flex', flex: 1, marginTop: '16px', marginBottom: '8px' }}
                        renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" />}
                      />
                    ) : <></>
                }

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Nome"
                  margin="normal"
                  name="nome"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.nome}
                  variant="outlined"
                  required
                />

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Login"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.email}
                  variant="outlined"
                  required
                />
              </Box>

              <Box className={classes.buttonBox}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={nextPage}
                >
                  AVANÇAR
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{ marginLeft: 5, backgroundColor: '#F07C00', color: 'white' }}
                >
                  Voltar
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.fullBox}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Nova senha"
                  margin="normal"
                  name="senha"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.senha}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  className={classes.field}
                  fullWidth
                  label="Confirme a nova senha"
                  margin="normal"
                  name="confirmaSenha"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formik.values.confirmaSenha}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="showConfirmPassword"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              <Box className={classes.buttonBox}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleSave}
                >
                  SALVAR
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{ marginLeft: 5, backgroundColor: '#F07C00', color: 'white' }}
                >
                  Cancelar
                </Button>
              </Box>
            </>
          )
        }
      </Container>

      <Loader display={startLoader} />
      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />

    </Page>
  );
};

export default NewUserView;
