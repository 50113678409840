import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Divider,
  Grow,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%'
  },
  containerShield: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#F5F5F5'
  },
  containerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '80px'
  },
  imgLogo: {
    width: '250px',
    height: '102.4px'
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  simpleTextBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '64px',
    justifyContent: 'center'
  },
  simpleText: {
    marginBottom: '32px'
  },
  successButton: {
    backgroundColor: '#009C6D',
    color: '#FFFFFF',
    borderColor: '#009C6D',
    '&:hover': {
      backgroundColor: '#009C6D',
      borderColor: '#F07C00'
    }
  },
  divider: {
    backgroundColor: '#C4C4C4',
    marginBottom: '32px'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '64px'
  }
}));

const RedefinedPasswordView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');

  const handleClick = async () => {
    navigate('/login')
  };

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  return (
    <Page className={classes.root} title="Solicitar redefinição de senha">
      <Container className={classes.containerShield} maxWidth="sm">
        <Box className={classes.containerBox}>
          <Container maxWidth="sm" style={{paddingLeft: '26px', paddingRight: '26px'}}>
            <Box className={classes.boxTitle} maxWidth="xs">
              <Grow in timeout={600}>
                <img
                  alt="Logo"
                  src="/static/images/logo_TVM.png"
                  className={classes.imgLogo}
                />
              </Grow>
            </Box>

            <Box className={classes.simpleTextBox}>
              <Typography className={classes.simpleText} variant="h1">Senha redefinida com sucesso!</Typography>
              <Typography className={classes.simpleText} variant="body1">
                Sua senha já foi redefinida e você já pode acessar o sistema.
              </Typography>
            </Box>

            <Container maxWidth="xs" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '16px' }}>
              <Box my={2}>
                <Button
                  className={classes.successButton}
                  disableElevation
                  onClick={handleClick}
                  size="large"
                  type="submit"
                  variant="outlined"
                >
                  Fazer login
                </Button>
              </Box>
            </Container>

            <Divider className={classes.divider} />

            <Typography className={classes.simpleText} variant="body1">
              Não pediu para receber este e-mail? Entre em contato com o suporte.
            </Typography>

            <Box className={classes.footer}>
              <Typography className={classes.simpleText} variant="body2">
                Desenvolvido por Techlise © 2021
              </Typography>
            </Box>
          </Container>
        </Box>

        <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
      </Container>
    </Page>
  );
};

export default RedefinedPasswordView;
