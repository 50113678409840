import React from 'react';
import {
  Avatar,
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import returnInitials from 'src/services/returnInitials';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '16px',
    alignItems: 'center'
  },
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#605D5C',
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  userData: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingLeft: '8px'
  },
  editarPerfilText: {
    paddingTop: '8px',
    paddingBottom: '16px',
    cursor: 'pointer'
  }
}));

const UserCard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const handleClick = () => {
    navigate('/app/perfil');
  }

  return (
    <>
      <Box className={classes.card}>
        <Avatar className={classes.avatar} alt="User Card Avatar">{returnInitials(user.usr_nome)}</Avatar>
        <Box className={classes.userData}>
          <Typography variant="body1">{user.usr_nome}</Typography>
          <Typography variant="body2">{user.usr_email}</Typography>
        </Box>
      </Box>
      <Typography variant="body2" className={classes.editarPerfilText} onClick={handleClick}>EDITAR PERFIL</Typography>
    </>
  )
}

export default UserCard;