import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  makeStyles,
  TextField,
  Select,
} from "@material-ui/core";
import { debounce } from "../../services/debounce";
import Page from "src/components/Page";
import PageTabTitle from "src/components/PageTabTitle";
import AlertDialog from "src/components/AlertDialog";
import MediaCard from "src/components/MediaCard";
import EditModal from "src/components/EditModal";
import api from "src/services/api";
import capitalizeFirstLetter from "src/services/capitalizeFirstLetter";
import Loader from "src/components/Loader";
import MidiaDaysModal from "src/components/MidiaDaysModal";
import deafaultDaysToDisplay from "src/utils/defaultDaysToDisplay";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
    maxWidth: "100vw",
  },
  searchBox: {
    width: "326px",
  },
  input: {
    width: "340px",
  },
  buttonBox: {
    marginTop: "80px",
    marginBottom: "40px",
  },
  headerButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      width: 250,
      marginLeft: theme.spacing(3),
    },
  },
  green: {
    color: "#009C6D",
  },
  mediaCardList: {
    width: "100%",
    maxWidth: "1400px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: theme.spacing(10),
    gap: theme.spacing(2),
    color: "textPrimary",
  },
  select: {
    margin: theme.spacing(2),
    width: "340px",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  fadeIn: {
    animation: "$myEffect 1s ease",
  },
  placeholder: {
    position: "absolute",
    background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
}));

const EditGridView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const me = useSelector((state) => state.auth.user);

  const timeoutRef = useRef(null);

  const [startLoader, setStartLoader] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [name, setName] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [fadeProps, setFadeProps] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setactiveTab] = useState("primary");
  const [type, setType] = useState("");
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [mediaGrid, setMediaGrid] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");

  const [daysToDisplay, setDaysToDisplay] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "0",
  ]);
  const [midiaDaysToSave, setMidiaDaysToSave] = useState([]);
  const [openMidiaDaysModal, setOpenMidiaDaysModal] = useState(false);
  const [dragNDropStartIndex, setDragNDropStartIndex] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragStart = (event, startIndex) => {
    setDragNDropStartIndex(startIndex);

    event.dataTransfer.setData("text/plain", event.target.id);
  };

  const handleCleanMedia = () => {
    setSelectedMedia([]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      selectedMedia,
      result.source.index,
      result.destination.index
    );

    setSelectedMedia(items);
    setFadeProps(classes.fadeIn);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    const mediaToRelocateId = event.target.id;

    if (!mediaToRelocateId) return console.log("ID Ñ ENCONTRADO.");

    const mediaToRelocate = selectedMedia.filter((media) => {
      return (
        media.con_id == Number(mediaToRelocateId) ||
        media.mul_id == Number(mediaToRelocateId) ||
        media.not_id == Number(mediaToRelocateId) ||
        media.cli_id == Number(mediaToRelocateId)
      );
    });

    if (mediaToRelocate.length > 1) return console.log("ID DUPLICADO.");
    const mediaToRelocateIndex = selectedMedia.indexOf(mediaToRelocate[0]);

    const reordered = reorder(
      selectedMedia,
      dragNDropStartIndex,
      mediaToRelocateIndex
    );

    setDragNDropStartIndex(null);
    setSelectedMedia(reordered);

    event.dataTransfer.clearData();
  };

  const changeActiveTab = (tab) => {
    if (name !== "" && selectValue !== "") {
      setactiveTab(tab);
    }
  };

  const handleMediaGrid = () => {
    api
      .get("/multimidia/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setMediaGrid(resp.data.result.items);
          setType("media-grid");
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpload = () => {
    setType("upload");
    setOpenModal(true);
  };

  const handleOpenMidiaDaysModal = () => {
    setOpenMidiaDaysModal(true);
  };

  const handleCloseMidiaDaysModal = () => {
    setOpenMidiaDaysModal(false);
    setDaysToDisplay(["1", "2", "3", "4", "5", "6", "0"]);
  };

  const handleDaysToDisplay = (e) => {
    const dayToAdd = e.target.value;

    const dayAlreadyAdd = daysToDisplay.find((day) => day === dayToAdd);
    if (dayAlreadyAdd) {
      setDaysToDisplay(daysToDisplay.filter((day) => day !== dayToAdd));
    } else {
      setDaysToDisplay([...daysToDisplay, dayToAdd]);
    }
  };

  const handleUploadNews = () => {
    api
      .get("/noticia/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          resp.data.result.forEach((el) => {
            if (el.not_nome) {
              el.not_nome = capitalizeFirstLetter(el.not_nome);
            }

            if (el.not_categoria) {
              el.not_categoria = capitalizeFirstLetter(el.not_categoria);
            }
          });

          setNewsList(resp.data.result);
          setType("news");
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWeather = () => {
    setType("weather");
    setOpenModal(true);
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Cadastro realizado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
    // navigate('/app/grades');
  };

  const removeMedia = (id) => {
    let mediaGrid = selectedMedia.filter((el) => {
      if (el.id) {
        return el.id !== id;
      } else {
        return el.mul_id != id;
      }
    });
    setSelectedMedia(mediaGrid);
  };

  const saveGrid = (play) => {
    setStartLoader(true);
    let gridMedia = [];

    selectedMedia.forEach((el, ind) => {
      let time = el.presentation.split(" - ");
      let itemId = "";

      if (el.type === "imagem" || el.type === "video") {
        if (el.con_tipo) {
          itemId = el.midia.mul_id;
        } else {
          itemId = el.mul_id;
        }
      } else if (el.type === "clima") {
        if (el.con_tipo) {
          itemId = el.clima.cli_id;
        } else {
          itemId = el.cli_id;
        }
      } else if (el.type === "noticia") {
        if (el.con_tipo) {
          itemId = el.noticia.not_id;
        } else {
          itemId = el.not_id;
        }
      }
      if (
        el.duration !== "00:00:15" &&
        (el.midiaDaysToSave[0]?.exc_duracao === "00:00:15" ||
          el.midiaDaysToSave[0]?.exc_duracao === null) &&
        el.type == "video"
      ) {
        el.midiaDaysToSave = el.midiaDaysToSave.map((item) => {
          const newItem = { ...item };
          newItem.exc_duracao = el.duration;

          return newItem;
        });
      }

      let media = {
        id: itemId,
        con_tipo: el.type === "imagem" || el.type === "video" ? "midia" : el.type,
        // "con_duracao": el.duration,
        con_inicio: time[0] === "null" ? null : time[0],
        con_fim: time[1] === "null" ? null : time[1],
        con_ordem: ind + 1,
        exc_exibicaoConteudo:
          el.midiaDaysToSave.length === 0
            ? deafaultDaysToDisplay
            : el.midiaDaysToSave,
      };

      gridMedia.push(media);
    });

    let gridContent = {
      grp_id: id,
      grp_nome: name,
      grp_cat_id: selectValue,
      grp_status: 1,
      conteudos: gridMedia,
    };

    api
      .put("/gradeprogramacao", gridContent)
      .then((resp) => {
        setStartLoader(false);
        if (resp.data.status && resp.data.status === 200) {
          if (play) {
            navigate(`/app/grade-preview/${id}`, {
              state: { from: currentPath }
            });
          } else {
            openSuccessAlert();
          }
        }
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const fetchGrid = () => {
    api
      .get(`gradeprogramacao/${id}`)
      .then((resp) => {
        const { result } = resp.data;
        let grid = result;

        setName(grid.grp_nome);
        setSelectValue(grid.grp_cat_id);

        grid.conteudos.forEach((el, index) => {
          if (el.con_tipo === "midia") {
            el.id = el.con_id;
            el.name = el.midia.mul_nome;
            el.type = el.midia.mul_tipo;
            el.image = el.midia.mul_thumb;
          } else if (el.con_tipo === "clima") {
            el.id = el.con_cli_id;
            el.name = el.clima.cli_nome;
            el.type = el.con_tipo;
            el.cli_cidade = el.clima.cli_cidade;
            el.cli_uf = el.clima.cli_uf;
          } else if (el.con_tipo === "noticia") {
            el.id = el.con_not_id;
            el.type = el.con_tipo;
            el.category = el.noticia.not_categoria;
          }

          el.duration = el.con_duracao;
          el.midiaDaysToSave = el.exibicao;
          el.presentation = `${el.con_inicio} - ${el.con_fim}`;
        });

        setSelectedMedia(
          grid.conteudos.sort((y, x) => y.con_ordem - x.con_ordem)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
      }`;

    navigate(url);
  };

  useEffect(() => {
    if (fadeProps === classes.fadeIn) {
      debounce(() => setFadeProps(""), 500, timeoutRef)();
    }
  }, [onDragEnd]);

  useEffect(() => {
    if (!isLoaded) {
      api
        .get("/categoria/all/ativos")
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setCategories(resp.data.result);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLoaded]);

  useEffect(() => {
    fetchGrid();
  }, [id]);

  console.log("SELECTEDMEDAA", selectedMedia);

  return (
    <Page className={classes.root} title="Editar Grade">
      <PageTabTitle
        className={classes.title}
        primary="GRADE DE PROGRAMAÇÃO"
        secondary="LISTA DE REPRODUÇÃO"
        active={activeTab}
        changeTab={changeActiveTab}
      />
      <Container className={classes.container}>
        {activeTab === "primary" ? (
          <>
            <FormControl variant="outlined">
              <TextField
                className={classes.input}
                label="Nome"
                margin="normal"
                name="busca"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                value={name}
                variant="outlined"
                required
              />
            </FormControl>

            <FormControl variant="outlined">
              <Select
                className={classes.select}
                native
                value={selectValue}
                onChange={(event) => setSelectValue(event.target.value)}
              >
                <option value="">Selecione a categoria*</option>
                {categories.map((cat) => (
                  <option key={cat.cat_id} value={cat.cat_id}>
                    {cat.cat_nome}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Box className={classes.buttonBox}>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={() => changeActiveTab("secondary")}
              >
                AVANÇAR
              </Button>
              <Button
                disableElevation
                size="large"
                variant="outlined"
                onClick={backPage}
                style={{
                  marginLeft: 5,
                  backgroundColor: "#F07C00",
                  color: "white",
                }}
              >
                Voltar
              </Button>
            </Box>
          </>
        ) : (
          <>
            {me.usr_tipoUsuario !== "caixa" ? (
              <Box className={classes.headerButtons}>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleMediaGrid}
                >
                  SELECIONAR MULTIMIDIA
                </Button>

                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleUpload}
                >
                  UPLOAD DE IMAGEM/VÍDEO
                </Button>

                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleUploadNews}
                >
                  IMPORTAR NOTÍCIAS
                </Button>

                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={handleWeather}
                >
                  IMPORTAR CLIMA
                </Button>
              </Box>
            ) : null}

            <Container className={classes.mediaCardList}>
              {selectedMedia.map((item, index) => (
                <div
                  key={item.id || index}
                  onDragStart={(e) => onDragStart(e, index)}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  id={index}
                  draggable={true}
                >
                  <MediaCard
                    id={item.id}
                    dragAndDrop
                    leftLabel
                    fadeProps={fadeProps}
                    labelNumber={index}
                    className={classes.root}
                    name={item.name}
                    image={item.image}
                    type={item.type}
                    mul_processamento_concluido={item.mul_processamento_concluido ?? item.midia?.mul_processamento_concluido}
                    category={item.category}
                    duration={item.duration}
                    presentation={item.midiaDaysToSave}
                    onDelete={() =>
                      removeMedia(item.id ? item.id : item.mul_id)
                    }
                    cidade={item.cli_cidade}
                    uf={item.cli_uf}
                    conId={
                      item.con_id || item.mul_id || item.cli_id || item.not_id
                    }
                  />
                </div>
              ))}
            </Container>

            {me.usr_tipoUsuario !== "caixa" ? (
              <Box className={classes.buttonBox}>
                <Button
                  style={{ marginRight: "20px" }}
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={() => saveGrid(false)}
                >
                  SALVAR
                </Button>

                <Button
                  style={{ marginRight: "20px" }}
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={() => saveGrid(true)}
                >
                  REPRODUZIR
                </Button>

                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={() => handleCleanMedia()}
                >
                  LIMPAR MIDIAS
                </Button>

                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{
                    marginLeft: 20,
                    backgroundColor: "#F07C00",
                    color: "white",
                  }}
                >
                  Cancelar
                </Button>
              </Box>
            ) : null}

            {me.usr_tipoUsuario === "caixa" ? (
              <Box className={classes.buttonBox}>
                <Button
                  style={{ marginRight: "20px" }}
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={() => saveGrid(true)}
                >
                  REPRODUZIR
                </Button>
                <Button
                  disableElevation
                  size="large"
                  variant="outlined"
                  onClick={backPage}
                  style={{
                    marginLeft: 20,
                    backgroundColor: "#F07C00",
                    color: "white",
                  }}
                >
                  Cancelar
                </Button>
              </Box>
            ) : null}
            <EditModal
              open={openModal}
              type={type}
              newList={newsList}
              categories={categories}
              selectedMedia={selectedMedia}
              mediaGrid={mediaGrid}
              setSelectedMedia={setSelectedMedia}
              close={() => {
                setMidiaDaysToSave([]);
                setOpenModal(false);
              }}
              openMidiaDays={handleOpenMidiaDaysModal}
              daysToDisplay={midiaDaysToSave}
              videoDuration={videoDuration}
              setVideoDuration={setVideoDuration}
            />
            <MidiaDaysModal
              open={openMidiaDaysModal}
              close={handleCloseMidiaDaysModal}
              handleDaysToDisplay={handleDaysToDisplay}
              daysToDisplay={daysToDisplay}
              save={setMidiaDaysToSave}
              videoDuration={videoDuration}
            />
          </>
        )}
      </Container>

      <Loader display={startLoader} />
      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

export default EditGridView;
