import React, { useState, useEffect, useRef } from 'react';
import {
	Fab,
	Box,
	Button,
	makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
	dropzone: {
		margin: '15px 0',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '23rem',
		height: '10rem',
		backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
	},
	inputUpload: {
		display: 'none',
	},
	uploadButton: {
		width: "56px",
		height: "56px",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
		color: '#FFF',
		backgroundColor: '#009C6D',
		transform: 'translatey(-10px)',
	},
	uploadSvg: {
		fontSize: 35,
	},
	defaultSvg: {
		fontSize: 55,
	},
  uploadImage: {
		width: "100%",
		height: "100%",
		objectFit: 'contain'
	},
	buttons: {
		display: 'flex',
		justifyContent: 'center',
		gap: theme.spacing(3),
		marginTop: 10
	}
}));

const Dropzone = (props) => {
	const classes = useStyles();
	const [previewImg, setPreviewImg] = useState(null);
	const [previewVideo, setPreviewVideo] = useState(null);
	const [preview, setPreview] = useState(null);
	const [file, setFile] = useState(null);
	const videoRef = useRef();
	const fileInputRef = useRef();

	useEffect(() => {
		if (file && props.value === 'image') {
			const reader = new FileReader();
			
			reader.onloadend = () => {
				setPreviewImg(reader.result);
				setPreview(true);
			}
			
			reader.readAsDataURL(file);
		} else if (file && props.value === 'video') {
			const reader = new FileReader();
			const urlVideo = URL.createObjectURL(file);
			
			reader.onloadend = () => {
				setPreviewVideo(urlVideo);
				setPreview(true);
			}

			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	}, [file, props.value])

	const handleClick = (event) => {
		event.preventDefault();
		fileInputRef.current.click();
	}

	const handleChange = (event) => {
		const file = event?.target?.files[0]
		
		if (file) {
			setFile(file);
		} else {
			setFile(null);
		}
	}

	return (
		<>
		{ 
			preview === null ? (
				<Box className={classes.dropzone}>
					<input
						onChange={handleChange}
						accept={`${props.value}/*`}
						ref={fileInputRef}
						className={classes.inputUpload}
						id="icon-button-file"
						type="file"
					/>

					<label htmlFor="icon-button-file">
						<label htmlFor="icon-button-file">
							<Fab
								elevation='disable'
								onClick={handleClick}
								className={classes.uploadButton}
								color="secondary"
								aria-label="upload picture"
								component="span"
							>
								{ props.news ? <GetAppIcon className={classes.uploadSvg} /> : <AddIcon className={classes.defaultSvg} /> }
							</Fab>
						</label>
					</label>

					{ props.news ? <span>Selecionar grupo de notícias</span> : <span>Adicionar nova mídia</span> }
				</Box>
			)	: (
				<Box style={{ height: 182, width: 392 }}>
					{ props.value === "video" && (
						<video width="400px" height="200vh" id="video" ref={videoRef} controls>
							<source id="source" src={previewVideo} type={file.type} />
						</video>
					)}

					{ props.value === "image" && (
						<img src={previewImg} alt="Upload imagem" className={classes.uploadImage} />
					)}

					{ props.buttons && (
						<Box className={classes.buttons}>
							<Button
								disableElevation
								size="large"
								variant="outlined"
								onClick={(e) => handleChange(e)}
							>
								Alterar
							</Button>

							<Button
								disableElevation
								size="large"
								variant="outlined"
								onClick={() => setFile("")}
							>
								Remover
							</Button>
						</Box>
					)}
				</Box>
			)
		}
		</>
	)
}

export default Dropzone;