import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grow,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Page from 'src/components/Page';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  imgLogo: {
    width: '350px',
    height: '142.4px'
  },
  simpleTextBox: {
    display: 'flex',
    marginTop: '32px',
    marginBottom: '16px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: 8,
    marginLeft: '-17%'
  }
}));

const ForgotPasswordView = () => {
  const classes = useStyles();
  
  const [loading, setLoading] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');
  
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .max(255)
        .required('E-mail é obrigatório')
    })
  });
  
  const handleSubmit = async () => {
    setLoading(true);
    
    let values = formik.values;

    let obj = {
      "usr_email": values.email
    }

    api.post('/user/recuperarsenha', obj)
      .then((resp) => {
        setLoading(false);
        openSuccessAlert();
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
        openErrorAlert(err?.response);
      })
  };

  const openErrorAlert = (response) => {
    setAlertText(response.data.message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText('Email com o link de redefinição de senha enviado com sucesso. Verifique sua caixa de SPAM, caso não localize na caixa de entrada.');
    setAlertType('success');
    setAlertButtonText('Fazer Login');

    setOpenAlert(true);
  }

  const handleAlertCloseLogin = () => {
    setOpenAlert(false);
    // navigate('/login');
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  return (
    <Page className={classes.root} title="Esqueci a senha">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box className={classes.boxTitle} maxWidth="xs">
            <Grow in timeout={600}>
              <img
                alt="Logo"
                src="/static/images/logo_TVM.png"
                className={classes.imgLogo}
              />
            </Grow>
          </Box>

          <Box className={classes.simpleTextBox}>
            <Typography variant="body1">Insira abaixo o seu usuário ou email cadastrado para recuperar o acesso.</Typography>
          </Box>

          <Container maxWidth="xs">
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Usuário / email"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.email}
              variant="outlined"
            />

            <Box my={2}>
              <Button
                disableElevation
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                variant="outlined"
                onClick={handleSubmit}
              >
                Solicitar redefinição de senha
              </Button>

              {
                loading && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )
              }
            </Box>
          </Container>        
        </Container>
      </Box>

      <AlertDialog open={openAlert} handleClose={alertType === 'success' ? handleAlertCloseLogin : handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default ForgotPasswordView;
