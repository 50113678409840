import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import StopIcon from '@material-ui/icons/Stop';
import Page from 'src/components/Page';
import MediaCard from 'src/components/MediaCard';
import PageTitle from 'src/components/PageTitle';
import WeatherCard from 'src/components/WeatherCard';
import NewsCard from 'src/components/NewsCard';
import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'src/components/AlertDialog';
import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    position: 'absolute',
    left: -20,
    top: 25,
    justifyContent: "space-between",
    margin: theme.spacing(0),
    maxWidth: '100vw'
  },
  searchBox: {
    marginRight: '210px',
    width: '326px'
  },
  mediaCardList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    overflowY: "scroll",
    overflowX: "hidden",
    width: 360,
    height: "65vh",
    marginRight: theme.spacing(1),
    gap: theme.spacing(2),
    color: "textPrimary",
  },
  image: {
    width: "70%",
    height: "64vh"
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  active: {
    color: '#F07C00',
  },
  inactive: {
    color: '#605D5C'
  },
  stopActive: {
    background: '#F07C00',
  },
  stopInactive: {
    background: '#605D5C'
  },
  backButton: {
    right: '45px',
    position: 'absolute'
  },
  stopButton: {
    color: '#fff',
    width: '1.9rem',
    height: '1.9rem',
    borderRadius: '50%',
  },
  buttonSave: {
    position: 'absolute',
    top: 650,
    left: '45%',
  },
  view: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  styledTitleBox: {
    minWidth: '336px',
    maxWidth: '45vw',
    height: '48px',
    whiteSpace: "nowrap",
    paddingLeft: 20,
    backgroundColor: '#F07C00',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  styledTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: '#FFFFFF',
    paddingRight: '16px'
  }
}));

const GridPreviewView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const { id } = useParams();
  let gridTimeout;

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertButtonText, setAlertButtonText] = useState('');

  const [buttonPlayer, setButtonPlayer] = useState("play");

  const [loaded, setLoaded] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [displayedElement, setDisplayedElement] = useState(null);
  const [currentMedia, setCurrentMedia] = useState(0);

  const handleBack = () => {
    clearTimeout(gridTimeout);

    navigate(state?.from);
  }

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType('error');
    setAlertButtonText('Fechar');

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const fetchList = (id) => {
    api.get(`/gradeprogramacao/${id}`)
      .then((resp) => {
        const { result } = resp.data;

        setupPlaylist(result.conteudos);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      })
  }

  const setupPlaylist = (list) => {
    list.forEach((el) => {
      if (el.con_tipo === 'midia') {
        el.id = el.con_mul_id;
        el.name = el.midia.mul_nome;
        el.type = el.midia.mul_tipo;
        el.image = el.midia.mul_thumb;
      } else if (el.con_tipo === 'clima') {
        el.id = el.con_cli_id;
        el.name = el.clima.cli_nome;
        el.type = el.con_tipo;
      } else if (el.con_tipo === 'noticia') {
        el.id = el.con_not_id;
        el.type = el.con_tipo;
        el.category = el.noticia.not_categoria;
      }

      el.duration = el.con_duracao;
      el.presentation = `${el.con_horaInicio} - ${el.con_horaFim}`;
    })

    setPlaylist(list);
  }

  const startPlayer = () => {
    let arrLength = playlist.length;

    if (currentMedia === arrLength) {
      setCurrentMedia(0);
    } else {
      let item = playlist[currentMedia];
      openItem(item);

      gridTimeout = setTimeout(() => {
        setCurrentMedia(currentMedia + 1);
      }, 10000)
    }
  }

  const openItem = (item) => {
    if (item.type === "imagem") {
      api.get(`/multimidia/file/${item.midia.mul_path}`, { responseType: 'blob' })
        .then((resp) => {
          const objectUrl = URL.createObjectURL(resp.data);

          setDisplayedElement(<img src={objectUrl} className={classes.image} alt="midia do tipo imagem da grade" style={{ width: "70%", height: "64vh" }} />)
        })
    } else if (item.type === "video") {
      api.get(`/multimidia/file/${item.midia.mul_path}`, { responseType: 'blob' })
        .then((resp) => {
          const objectUrl = URL.createObjectURL(resp.data);

          setDisplayedElement(<iframe style={{ width: "70%", height: "64vh" }} width="100%" height="100%" src={objectUrl} title="video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>)
        })
    } else if (item.type === "clima") {
      api.get(`/clima/tempo?cidade=${item.clima.cli_cidade}&uf=${item.clima.cli_uf}`)
        .then((resp) => {
          const result = resp.data.result;

          setDisplayedElement(<WeatherCard weather={result} />);
        })
    } else if (item.type === "noticia") {
      api.get(`/noticia/${item.noticia.not_categoria}`)
        .then((resp) => {
          const result = resp.data.result;
          let random_num = Math.floor(Math.random() * result.length);

          setDisplayedElement(<NewsCard news={result[random_num]} />);
        })
    }
  }

  const pauseList = () => {
    setButtonPlayer("pause");
  }

  const stopList = () => {
    setButtonPlayer("stop");
    setCurrentMedia(0);
  }

  useEffect(() => {
    if (!loaded) {
      fetchList(id);
    }
  }, [loaded]);

  useEffect(() => {
    if (playlist.length !== 0 && buttonPlayer === "play") {
      startPlayer();
    }
  }, [playlist, currentMedia, buttonPlayer])


  return (
    <Page
      className={classes.root}
      title="Lista de reprodução"
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <PageTitle title="LISTA DE REPRODUÇÃO - PRÉ-VISUALIZAÇÃO" className={classes} />

        <Box>
          <Button>
            <PlayCircleFilledWhiteIcon
              fontSize="large"
              className={`${buttonPlayer === "play" ? classes.active : classes.inactive}`}
              onClick={() => setButtonPlayer("play")}
            />
          </Button>

          <Button>
            <PauseCircleFilledIcon
              fontSize="large"
              className={`${buttonPlayer === "pause" ? classes.active : classes.inactive}`}
              onClick={pauseList} />
          </Button>

          <Button>
            <StopIcon fontSize="large"
              className={`${classes.stopButton} 
              ${buttonPlayer === "stop" ? classes.stopActive : classes.stopInactive}`}
              onClick={stopList}
            />
          </Button>
        </Box>

        <Button
          disableElevation
          className={classes.backButton}
          size="large"
          variant="outlined"
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Box>

      <Box className={classes.view}>
        <Container className={classes.container}>
          <div className={classes.mediaCardList}>
            {
              playlist.map((item, index) => (
                <MediaCard
                  key={index}
                  className={classes.card}
                  name={item.name}
                  image={item.image}
                  type={item.type}
                  category={item.category}
                  duration={item.duration}
                  presentation={item.midiaDaysToSave}
                  onlyDisplay={true}
                  onOpen={() => openItem(item)}
                />
              ))
            }
          </div>

          {displayedElement}
        </Container>
      </Box>

      <AlertDialog open={openAlert} handleClose={handleAlertClose} type={alertType} text={alertText} buttonText={alertButtonText} />
    </Page>
  );
};

export default GridPreviewView;